import React from 'react';
import {getSmoothStepPath, getMarkerEnd} from 'react-flow-renderer';
import {EventEmitter} from "../events";

export default function CustomEdge({
									   id,
									   sourceX,
									   sourceY,
									   targetX,
									   targetY,
									   sourcePosition,
									   targetPosition,
									   style = {},
									   arrowHeadType,
									   markerEndId,
								   }) {
	const edgePath = getSmoothStepPath({sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition});
	const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

	const deleteNode = (idNode) => {
		EventEmitter.dispatch('deleteElement', idNode);
	}

	return (
		<>
			<path id={id} style={style} className="react-flow__edge-path" d={edgePath} markerEnd={markerEnd}/>

			<defs>
				<filter x="0" y="0" width="1" height="1" id="solid" radius="50">
					<feImage href="/static/removeIcon.svg"/>
				</filter>
			</defs>

			<text className="deleteArea" dy='3' filter="url(#solid)" style={{cursor: 'pointer'}}
				  onClick={() => deleteNode(id)}>
				<textPath href={`#${id}`} style={{fontSize: '10px', marginTop: '10px'}} startOffset="50%"
						  textAnchor="middle">
					X
				</textPath>
			</text>

		</>
	);
}