import Http from "../http";

const prefix = '/funnels';

const funnels = new (function () {

	this.get = function () {
		return Http.authApi().get(`${prefix}`);
	};

	this.getFunnel = function (encryptedId) {
		return Http.authApi().get(`${prefix}/${encryptedId}`);
	};

	this.getWebsite = function (encryptedId) {
		return Http.authApi().get(`${prefix}/website/${encryptedId}`);
	};

	this.post = function (data) {
		return Http.authApi().post(`${prefix}`, data);
	};

	this.delete = function (encryptedId) {
		return Http.authApi().delete(`${prefix}/${encryptedId}`);
	};

	this.duplicate = function (encryptedId) {
		return Http.authApi().post(`${prefix}/duplicate/${encryptedId}`);
	};

	this.share = function (data) {
		return Http.authApi().post(`${prefix}/share`, data);
	};

	this.shareImage = function (data, encryptedId) {
		return Http.authApi().post(`${prefix}/share-img/${encryptedId}`, data);
	};

	this.generateSite = function (data) {
		return Http.authApi().post(`${prefix}/generate`, data);
	};

	this.getLanguageUser = function () {
		return Http.authApi().get(`${prefix}/user/language`);
	}

	this.postLanguageUser = function (data) {
		return Http.authApi().post(`${prefix}/user/language`, data);
	}

	this.getImages = function () {
		return Http.authApi().get(`${prefix}/user/images`);
	}

	this.postImages = function (data) {
		return Http.authApi().post(`${prefix}/user/images`, data);
	}

	this.deleteImage = function (image_id) {
		return Http.authApi().delete(`${prefix}/user/images/${image_id}`);
	}

})();

export default funnels;