import React from "react";

import './edition.css';
import i18n from "../../i18n";
import {EventEmitter} from "../events";
import {toast} from "react-toastify";
import Lists from "./extends/lists";

class CampaignEdition extends Lists {

	constructor(props) {
		super(props);

		this.state = {
			campaign_uid: null,
			campaign: {},
		}

		EventEmitter.subscribe('editionSidebar', (data) => {
			this.showCampaignEdition(data)
		})
	}

	addNodePopup = (type) => {

		let toastId = toast(i18n.t('cfb-adding'), {autoClose: false});
		let rc = this;

		function addOpenNodeIfNeeded() {
			rc.props.addAutomationNode({
				desc: 'cfb-title-open',
				type: 'open',
				campaign_uid: rc.state.campaign_uid,
				idNode: rc.state.campaign.idNode
			});
		}

		if (type === 'click' || type === 'not-click') {
			addOpenNodeIfNeeded.call(this);
		}

		setTimeout(() => {
			rc.props.addAutomationNode({
				desc: 'cfb-title-' + type,
				type: type,
				campaign_uid: rc.state.campaign_uid,
				idNode: this.state.campaign.idNode
			});

			setTimeout(() => {

				toast.update(toastId, {
					render: i18n.t('cfb-added'),
					type: toast.TYPE.SUCCESS,
					autoClose: 5000,
					className: 'rotateY animated'
				});

			}, 1000)
		}, 1000)
	}

	showCampaignEdition = (data) => {

		this.setState({campaign_uid: data.campaign_uid})
		this.setState({campaign: data})

		EventEmitter.dispatch('closeSidebars');
		document.getElementById('sidebar-campaign-element').classList.add('active');
	}

	editEmail = () => {
		if (this.state.campaign_uid) {
			EventEmitter.dispatch('editionEmail', this.state.campaign_uid);
		}
	}

	closePopup = () => {
		EventEmitter.dispatch('closeSidebars');
	}

	render() {
		return (
			<div id={'sidebar-campaign-element'} className='card sidebar-canvas sidebar-right-absolute'>

				<div className="sidebar-brand d-flex alert-primary">
					<button className='btn btn-success w-50 btn-lists btn-create-campaign mb-0 mt-0'
							data-ba-i={'edit-email'}
							onClick={this.editEmail}>{i18n.t('cfb-edit-email')}
					</button>

					<button type="button" className="close close-popup" onClick={this.closePopup}>&times;</button>
				</div>

				{this.titleElem()}
				<div className={'form-group automation-edition-area'}>
					{this.addAutomation('open')}
					{this.addAutomation('not-open')}

					{this.addAutomation('click')}
					{this.addAutomation('not-click')}
				</div>
			</div>
		);
	}

	titleElem() {
		let value = ''
		if (typeof this.state.campaign.label != 'undefined')
			value = this.state.campaign.label;

		return <h3 className='title-page-sidebar line-bottom'>{value}</h3>;
	}

	addAutomation(type) {
		return (
			<div className='popup-element-add'>
				<label className='w-100 text-left mb-3'>
					{i18n.t('cfb-add-automation-label-' + type)}
				</label>
				<button className='btn btn-info w-100 btn-lists' data-ba-i={'add-automation-'+type}
						onClick={() => this.addNodePopup(type)}>
					{i18n.t('cfb-add-automation')}
				</button>
			</div>
		);
	}
}

export default CampaignEdition;
