import React from "react";

import './edition.css';
import i18n from "../../i18n";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import {ButtonGroup, DropdownButton, Dropdown, Modal} from "react-bootstrap";
import {EventEmitter} from "../events";
import SplitTest from "./element/splitTest";
import pageTypes from "../../utils/editor/pageTypes";
import template from "../../utils/editor/template";
import website from "../../utils/editor/website";
import Select from "react-select/creatable/dist/react-select.esm";
import {ShareData} from "../shareData";
import {toast} from "react-toastify";
import FileBase64 from 'react-file-base64';

class PageEdition extends React.Component {

  constructor() {
    super();

    this.state = {
      showCanHavePopup: false,
      showCanHaveExitPopup: false,
      showCanHaveTimePopup: false,
      showCanHaveForm: false,
      showCanHaveCheckout: false,
      showCanHaveWebinar: false,
      showCanHaveCalendar: false,
      showChoosePage: false,
      page: {
        data: {
          label: '',
        },
        id: null
      },

      userTemplates: [],
      baTemplates: [],

      pagesSelect: [],
      pageSelected: {}
    }

  }

  componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
    if (typeof nextProps.page != 'undefined') {
      this.setState({page: nextProps.page})

      if (typeof nextProps.page.data !== 'undefined') {

        this.setState({
          showCanHavePopup: this.checkVars(nextProps, 'have_click_popup', 'has_click_popup')
        });
        this.setState({
          showCanHaveExitPopup: this.checkVars(nextProps, 'have_exit_popup', 'has_exit_popup')
        });
        this.setState({
          showCanHaveTimePopup: this.checkVars(nextProps, 'have_time_popup', 'has_time_popup')
        });
        this.setState({
          showCanHaveForm: this.checkVars(nextProps, 'have_form', 'has_form')
        });
        this.setState({
          showCanHaveCheckout: this.checkVars(nextProps, 'have_checkout', 'has_checkout')
        });
        this.setState({
          showCanHaveWebinar: this.checkVars(nextProps, 'have_webinar', 'has_webinar')
        });
        this.setState({
          showCanHaveCalendar: this.checkVars(nextProps, 'have_calendar', 'has_calendar')
        });
      }
    }
  }

  checkVars(nextProps, var1, var2) {
    return nextProps.page.data.options[var1] ? false : nextProps.page.data.options[var2];
  }


  handleChange = e => {
    const name = e.target.value;
    if (typeof name !== 'undefined') {

      this.setState(
        {
          page: {
            ...this.state.page,
            data: {
              ...this.state.page.data,
              label: name,
            }
          }
        }
      )
      let rc = this;
      setTimeout(() => {
        rc.props.handlePage(this.state.page);
      }, 200)
    }
  }

  handleChangeImg = (e, img) => {

    if (typeof img !== 'undefined') {

      this.setState(
        {
          page: {
            ...this.state.page,
            data: {
              ...this.state.page.data,
              img: img,
            }
          }
        }
      )
      let rc = this;
      setTimeout(() => {
        rc.props.handlePage(this.state.page);
      }, 200)
    }
  }


  handleChangeHome = e => {
    EventEmitter.dispatch('setHomePage', {idPage: this.state.page.id, value: e.target.checked});
  }


  addNodeSubNode = (e) => {
    const type = e.target.dataset.type;

    let obj = {};
    if (type === 'form') {
      obj.have_form = true;
    }
    if (type === 'checkout') {
      obj.have_checkout = true;
    }
    if (type === 'webinar') {
      obj.have_webinar = true;
    }
    if (type === 'calendar') {
      obj.have_calendar = true;
    }

    this.setState(
      {
        page: {
          ...this.state.page,
          data: {
            ...this.state.page.data,
            options: {
              ...this.state.page.data.options,
              ...obj
            }
          }
        }
      }
    )

    let rc = this;
    setTimeout(() => {
      rc.props.handlePage(this.state.page);
      rc.props.createSubNode(this.state.page, type);
    }, 200)
  }

  addNodeTag = (e) => {
    this.props.createTag(this.state.page);
  }

  addNodePopup = (e) => {
    const popup = e.target.dataset.popup;
    if (popup !== '' && typeof popup !== 'undefined') {

      let obj = {};
      if (popup === 'click') {
        obj.have_click_popup = true;
      }
      if (popup === 'time') {
        obj.have_time_popup = true;
      }
      if (popup === 'exit') {
        obj.have_exit_popup = true;
      }

      this.setState(
        {
          page: {
            ...this.state.page,
            data: {
              ...this.state.page.data,
              options: {
                ...this.state.page.data.options,
                ...obj
              }
            }
          }
        }
      )

      let rc = this;
      setTimeout(() => {
        rc.props.handlePage(this.state.page);
        rc.props.createPopup(this.state.page, popup);
      }, 200)
    }
  }

  closePopup = () => {
    EventEmitter.dispatch('closeSidebars');
  }

  applyPage = (page) => {
    this.setState({page: page});

    let rc = this;
    setTimeout(() => {
      rc.props.handlePage(this.state.page);
    }, 200)
  }

  previewPage = () => {
    pageTypes.getPreview(this.props.funnel.themes_id, this.state.page.data.options.id).then(response => {
      pageTypes.getWithoutAuth(response.data.page).then(data => {
        let pageURL = data.data.url;

        if (data.data.layout.website.dominio == null) {
          window.open('https://' + data.data.userEditor.subdominio + '-' + data.data.layout.website.url + '.cheetah.builderall.com/' + pageURL, '_blank');
        } else {
          window.open('https://' + data.data.layout.website.dominio + '/' + pageURL, '_blank');
        }
      });
    })
  }

  // https://cheetah-editor.builderall.com/#/editor/
  // eyJpdiI6Ik8rckVBb1l6YTdDbVF3VllkXC9raktRPT0iLCJ2YWx1ZSI6ImpaVFwvSXhaK0JQVDBXS3RTd1hwMjBRPT0iLCJtYWMiOiI0OTQzZjczNjc4MzlmZWUyZjZmODM1NjJkNGNmZjFhZGY4NDhjZTQwM2Q4YTI4NjM3MzkyYTVkMWRhY2M0ZGRjIn0=

  editPage = () => {

    let url;
    switch (process.env.REACT_APP_ENV) {

      case 'local':
        url = 'http://localhost:8080/#/editor/';
        break;
      case 'homologation':
        url = 'https://editor-dev.builderall.io/#/editor/';
        break;
      case 'production':
        url = 'https://cheetah-editor.builderall.com/#/editor/';
        break;
      default:
        url = 'http://localhost:8080/#/editor/';
        break;
    }

    website.getDataPagesEncrypted(this.props.funnel.encrypted_id).then(data => {
      data.data.layouts[0].pages.forEach(page => {
        if (page.title.toLowerCase() == this.state.page.data.label.toLowerCase() || page.canvas_reference == this.state.page.id) {
          window.open(url + page.encrypted_id, '_blank');
        }
      })
    })
  }

  choosePage = () => {

    this.setState({showChoosePage: true});

    let baTemplates = ShareData.recover('baTemplates');

    if (baTemplates) {
      this.setState({baTemplates: baTemplates});
    } else {
      //all templates from builderall
      template.getAll().then(response => {
        this.setState({baTemplates: response.data.templates})
        ShareData.subscribe('baTemplates', response.data.templates);
      })
    }

    let userTemplates = ShareData.recover('userTemplates');

    if (userTemplates) {
      this.setState({userTemplates: userTemplates});
    } else {
      //all templates from user
      website.getAllUserWebsites().then(response => {
        this.setState({userTemplates: response.data})
        ShareData.subscribe('userTemplates', response.data);
      })
    }
  }

  handleClose = () => {
    this.setState({showChoosePage: false});
  }

  handleApply = () => {
    this.setState({showChoosePage: false});

    if (Object.keys(this.state.pageSelected).length !== 0) {

      let page = this.state.page;
      page['userPage'] = this.state.pageSelected;

      this.props.handlePage(page);
    }
  }

  render() {
    return (
      <div id={'sidebar-edition'}
           className='card sidebar-canvas sidebar-right-absolute sidebar-page-edition-absoulte'>
        <button type="button" className="close close-popup"
                onClick={this.closePopup}>&times;</button>
        {this.state.showCanHaveForm || this.state.showCanHaveCheckout || this.state.showCanHaveWebinar || this.state.showCanHaveCalendar ? this.setHeaderPage() : null}
        {this.titlePage()}

        <Tabs defaultActiveKey="page" id="uncontrolled-tab-example">
          <Tab eventKey="page" title={i18n.t('cfb-edit-tab-page')}>
            <div className={'form-group'} id={'page-edition'}>
              <label className='w-100'>
                {i18n.t('cfb-edit-name-page')}
                {this.inputPageName()}
              </label>
            </div>
            <div className={'form-group'} id={'page-edition'}>
              <label className='w-100'>
                {i18n.t('cfb-edit-img-page')}
                {this.inputImgName()}
              </label>
            </div>
            <div className={'form-group'} id={'page-edition'}>
              <label className='w-100'>
                {this.inputHomePage()}
              </label>
            </div>
            {typeof this.state.page.userPage === 'undefined' ? this.previewButtonArea() : null}
            <div className={'form-group'} id={'page-edition'}>
              <label className='w-100'>
                {i18n.t('cfb-choose-page')}
                <button className='btn btn-info w-100 mt-2 btn-lists mb-0' onClick={this.choosePage}>
                  {i18n.t('cfb-choose')}
                </button>
                {typeof this.state.page.userPage !== 'undefined' ? i18n.t('cfb-page-selected') + ' ' + this.state.page.userPage.label : null}
              </label>
            </div>
            {this.props.funnel.website_id !== null ? this.editPageAtCheetah() : null}
          </Tab>
          <Tab eventKey="popups" title={i18n.t('cfb-edit-tab-popup')}>
            <div className={'form-group '} id={'page-edition'}>
              {this.state.showCanHavePopup ? this.addPopupButton('click') : null}
              {this.state.showCanHaveTimePopup ? this.addPopupButton('time') : null}
              {this.state.showCanHaveExitPopup ? this.addPopupButton('exit') : null}
            </div>
          </Tab>
          <Tab eventKey="splitTest" title={i18n.t('cfb-edit-tab-split-test')}>
            <div className={'form-group'} id={'page-edition'}>
              <SplitTest page={this.state.page} funnel={this.props.funnel} applyPage={this.applyPage}/>
            </div>
          </Tab>
          <Tab title={i18n.t('cfb-edit-tab-tags')} eventKey="tags">
            <div className='form-group'>
              {this.addTagButton()}
            </div>
          </Tab>
        </Tabs>

        <Modal show={this.state.showChoosePage} onHide={this.handleClose} dialogClassName="modal-campaign">
          <Modal.Header>
            <h3 className="modal-title">{i18n.t('cfb-choose-page-title')}</h3>
            <button type="button" className="close close-popup" onClick={this.handleClose}>&times;</button>
          </Modal.Header>

          <Modal.Body className="place-templates-body">
            <Tabs defaultActiveKey="ba" id="uncontrolled-tab-example" onClick={this.clickTab}>
              <Tab eventKey="ba" title={i18n.t('cfb-ba-template')}>
                <div className='container-template-choose'>
                  <label className='w-100'>
                    {i18n.t('cfb-choose-ba-template')}
                  </label>
                  {this.getSelectBaTemplates()}
                  {this.getSelectPages()}
                </div>
              </Tab>
              <Tab eventKey="user" title={i18n.t('cfb-user-template')}>
                <div className='container-template-choose'>
                  <label className='w-100'>
                    {i18n.t('cfb-choose-user-template')}
                  </label>
                  {this.getSelectTemplates()}
                  {this.getSelectPages()}
                </div>
              </Tab>
            </Tabs>
          </Modal.Body>

          <Modal.Footer>
            <button type="button" className="btn btn-link" onClick={this.handleClose}>
              {i18n.t('cfb-close')}
            </button>
            <button type="button" className="btn btn-success btn-upper" onClick={this.handleApply}>
              {i18n.t('cfb-save-changes')}
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  previewButtonArea() {
    return <div className={'form-group'} id={'page-edition'}>
      <label className='w-100'>
        {i18n.t('cfb-preview-page')}
        <button className='btn btn-info w-100 mt-2' onClick={this.previewPage}>
          {i18n.t('cfb-preview')}
        </button>
      </label>
    </div>;
  }

  editPageAtCheetah() {
    return <div className={'form-group'} id={'page-edition'}>
      <label className='w-100'>
        {i18n.t('cfb-access-cheetah-edit-page')}
        <button className='btn btn-info w-100 mt-2 mb-0' onClick={this.editPage}>
          {i18n.t('cfb-access-cheetah')}
        </button>
      </label>
    </div>;
  }

  clickTab = () => {
    this.setState({pagesSelect: []});
  }

  handleChangeTemplate = (data) => {
//all pages from website
    let toastId = toast(i18n.t('cfb-loading-data'), {autoClose: false});

    pageTypes.getPagesFromWebsite(data.value, false).then(response2 => {
      this.setState({pagesSelect: response2.data.data});

      toast.update(toastId, {
        render: i18n.t('cfb-loaded'),
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        className: 'rotateY animated'
      });
    })
  }

  getSelectTemplates() {
    let options = [];

    this.state.userTemplates.forEach((template) => {
      options.push({value: template.encrypted_id, label: template.title})
    })

    return <Select options={options} menuPlacement="auto" onChange={this.handleChangeTemplate}/>;
  }

  handleChangeBaTemplate = (data) => {
//all pages from website
    let toastId = toast(i18n.t('cfb-loading-data'), {autoClose: false});

    pageTypes.getPagesFromWebsite(data.value, true).then(response2 => {
      this.setState({pagesSelect: response2.data.data});

      toast.update(toastId, {
        render: i18n.t('cfb-loaded'),
        type: toast.TYPE.SUCCESS,
        autoClose: 5000,
        className: 'rotateY animated'
      });
    })
  }

  getSelectBaTemplates() {
    let options = [];

    this.state.baTemplates.forEach((template) => {
      options.push({
        value: template.encrypted_id,
        label: <div>
          <div className={'template-item-select'} style={{
            backgroundImage: 'url(https://sitebuilder-admin.omb11.com/storage/' + template.thumbnail + ')'
          }}/>
          {i18n.t(template.title)}
        </div>
      })
    })

    return <Select classNamePrefix={'react-select-templates'} options={options}
                   menuPlacement="auto"
                   onChange={this.handleChangeBaTemplate}/>;
  }

  handleChangePage = (data) => {
    this.setState({pageSelected: data});
  }

  getSelectPages() {
    let options = [];

    this.state.pagesSelect.forEach((page) => {
      options.push({
        value: page.encrypted_id,
        label: page.title
      })
    })

    if (this.state.pagesSelect.length > 0)
      return <>
        <label className='w-100 pt-3'>
          {i18n.t('cfb-choose-page-select')}
        </label>
        <Select options={options} menuPlacement="auto" onChange={this.handleChangePage}/>
      </>;
  }

  setHeaderPage() {
    return <div className="sidebar-brand d-flex alert-primary">
      <DropdownButton as={ButtonGroup} title={i18n.t('cfb-add-app')} id="bg-nested-dropdown">
        {this.state.showCanHaveForm ? this.addSubNode('form') : null}
        {this.state.showCanHaveCheckout ? this.addSubNode('checkout') : null}
        {this.state.showCanHaveWebinar ? this.addSubNode('webinar') : null}
        {this.state.showCanHaveCalendar ? this.addSubNode('calendar') : null}
      </DropdownButton>
    </div>;
  }

  titlePage() {
    let value = ''
    if (typeof this.state.page.data != 'undefined' && typeof this.state.page.data.label != 'undefined')
      value = this.state.page.data.label;

    return <h2 className='title-page-sidebar'>{value}</h2>;
  }

  addTagButton() {
    return (
      <div className='popup-element-add'>
        <label className='w-100 text-left mb-3'>{i18n.t('cfb-add-tag-label')}</label>
        <button className='btn btn-info w-100 btn-lists' onClick={this.addNodeTag}
                data-ba-i={'add-tag-to-page'}>
          {i18n.t('cfb-add-tag')}
        </button>
      </div>
    );
  }

  addPopupButton(type) {
    return (
      <div className='popup-element-add'>
        <label className='w-100 text-left mb-3'>{i18n.t('cfb-add-popup-label-' + type)}</label>
        <button className='btn btn-info w-100 btn-lists' data-popup={type} onClick={this.addNodePopup}
                data-ba-i={'add-popup-' + type}>
          {i18n.t('cfb-add-popup-' + type)}
        </button>
      </div>
    );
  }

  addSubNode(type) {
    return (
      <Dropdown.Item data-type={type}
                     onClick={this.addNodeSubNode}>{i18n.t('cfb-add-node-' + type)}</Dropdown.Item>
    );
  }

  getImg(img) {
    if (img !== null)
      return <img style={{width: '100%', maxHeight: '250px'}} src={img} alt={'image-page'}/>;
  }

  getFiles(file) {
    this.handleChangeImg('image', file.base64);
  }

  inputPageName() {

    let value = ''
    if (typeof this.state.page.data != 'undefined' && typeof this.state.page.data.label != 'undefined')
      value = this.state.page.data.label;

    return <input type="text" value={value}
                  className={'form-control'}
                  onBlur={this.handleChange}
                  onChange={this.handleChange}/>;
  }

  inputImgName() {
    let value = null
    if (typeof this.state.page.data != 'undefined' && typeof this.state.page.data.img != 'undefined')
      value = this.state.page.data.img;

    return <>
      {this.getImg(value)}
      <FileBase64
        multiple={false}
        onDone={this.getFiles.bind(this)}/>
    </>;
  }

  inputHomePage() {
    let value = false;
    if (typeof this.state.page.data != 'undefined' && typeof this.state.page.data.home != 'undefined')
      value = this.state.page.data.home;

    return <>
      <input type="checkbox" checked={value}
             className={'mr-2'}
             onBlur={this.handleChangeHome}
             onChange={this.handleChangeHome}/>
      {i18n.t('cfb-edit-homepage')}
    </>;
  }
}


export default PageEdition;
