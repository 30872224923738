import Http from "./http";

const prefix = '';


const loginApi = new (function () {

	this.getToken = function (layoutId) {
		return Http.api().get(`${prefix}/${layoutId}`)
	};

	this.getUser = function (id) {
		return Http.authApi().get(`${prefix}/${id}`);
	}

	this.register = function (user) {
		return Http.api().post(`${prefix}/register`, user);
	}

	this.login = function (user) {
		return Http.api().post(`${prefix}/loginCanvas`, user);
	}

	this.logout = function (user) {
		return Http.authApi().post(`${prefix}/logout`);
	}


})();

export default loginApi;