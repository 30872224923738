import React from "react";

import './edition.css';
import i18n from "../../i18n";
import {FormCheck} from "react-bootstrap";
import {SketchPicker} from 'react-color';
import restrictArea from "../../utils/editor/restrictArea";
import {EventEmitter} from "../events";

class restrictedAreaEdition extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			displayColorPicker: false,
			auth: ['token', 'register', 'both'],
			types: [],
			element: {}
		}
	}

	componentDidMount() {
		restrictArea.getTypes().then((res) => {
			this.setState({types: res.data.data})
		});
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
		if (typeof nextProps.element != 'undefined') {
			this.setState({element: nextProps.element})

			if (typeof nextProps.element.data != 'undefined' && typeof nextProps.element.data['type_id'] != 'undefined')
				document.querySelector('[name=inputRadioType][value="' + nextProps.element.data['type_id'] + '"]').setAttribute('checked', true);

			if (typeof nextProps.element.data != 'undefined' && typeof nextProps.element.data['auth_type'] != 'undefined')
				document.querySelector('[name=inputRadioAuth][value="' + nextProps.element.data['auth_type'] + '"]').setAttribute('checked', true);
		}
	}

	handleChange = (e, prop) => {
		const value = e.target.value
		if (value !== '' && typeof value !== 'undefined') {
			this.setChangesToRestrictedAreaNode(prop, value);
		}
	}

	setChangesToRestrictedAreaNode(prop, value) {
		let obj = {};
		obj[prop] = value;

		this.setState(
			{
				element: {
					...this.state.element,
					data: {
						...this.state.element.data,
						...obj
					}
				}
			}
		)
		let rc = this;
		setTimeout(() => {
			rc.props.handleResArea(this.state.element);
		}, 200)
	}

	handleChangeColor = (color) => {
		this.setChangesToRestrictedAreaNode('color', color.hex);
	}

	handlePage = (e) => {
		this.props.setPageInRestrictedArea(e.target.value, this.state.element.id, e.target.checked)
	}

	handleType = (e) => {
		this.setChangesToRestrictedAreaNode('type_id', e.target.value);
	}

	handleAuth = (e) => {
		this.setChangesToRestrictedAreaNode('auth_type', e.target.value);
	}

	closePopup = () =>{
		EventEmitter.dispatch('closeSidebars');
	}

	render() {
		return (
			<div id={'sidebar-edition-restricted-area'} className='card sidebar-canvas sidebar-right-absolute'>
				{this.titlePage()}
				<button type="button" className="close close-popup"
						onClick={this.closePopup}>&times;</button>
				<div className={''} id={'element-edition-restricted'}>
					<label className='w-100'>
						{i18n.t('cfb-edit-name-element')}
						{this.inputElement('label')}
					</label>
					<label className='w-100'>
						{i18n.t('cfb-edit-desc-element')}
						{this.inputElement('desc')}
					</label>
					<label className='w-100'>
						{i18n.t('cfb-edit-color-element')}
						{this.inputElementColor()}
					</label>
					<label className='w-100'>
						{i18n.t('cfb-edit-select-auth')}
						{this.inputAuth()}
					</label>
					<label className='w-100'>
						{i18n.t('cfb-edit-select-type')}
						{this.inputTypes()}
					</label>
					<label className='w-100'>
						{i18n.t('cfb-edit-select-pages')}
						{this.inputPages()}
					</label>
				</div>
			</div>
		)
	}

	titlePage() {
		let value = ''
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.label != 'undefined')
			value = this.state.element.data.label;

		return <h2 className='title-page-sidebar line-bottom'>{value}</h2>;
	}

	handleClick = () => {
		this.setState({displayColorPicker: !this.state.displayColorPicker})
	}

	handleClose = () => {
		if (this.state.displayColorPicker)
			this.setState({displayColorPicker: false})
	}

	inputElement(prop) {
		let value = ''
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data[prop] != 'undefined')
			value = this.state.element.data[prop];

		return <input type="text" value={value}
					  className={'form-control'}
					  onBlur={(event) => this.handleChange(event, prop)}
					  onChange={(event) => this.handleChange(event, prop)}/>;
	}

	inputElementColor() {
		let value = 'blue'
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.color != 'undefined')
			value = this.state.element.data.color;

		return (
			<div>
				<div className="swatch" onClick={this.handleClick}>
					<div className='swatch-color' style={{backgroundColor: value}}/>
				</div>
				{this.state.displayColorPicker ? <div className='popover'>
					<div className='cover' onClick={this.handleClose}/>
					<SketchPicker color={value} onChange={this.handleChangeColor}/>
				</div> : null}
			</div>
		)
	}

	inputPages() {

		const _id = this.state.element.id;
		const options = [];

		this.props.elements.forEach((elem, idx) => {
			if (elem.type === 'pageNode') {
				options.push(
					<div className='' key={idx}>
						<FormCheck type="checkbox" label={elem.data.label}
								   value={elem.data.idNode}
								   checked={typeof elem.data.options['restrictedAreas'] !== 'undefined' && (elem.data.options['restrictedAreas'].indexOf(_id) !== -1)}
								   onChange={this.handlePage}/>
					</div>
				)
			}
		})

		return (<div>{options}</div>);
	}

	inputAuth() {

		const options = [];

		this.state.auth.forEach((elem, idx) => {
			options.push(
				<div className='' key={idx}>
					<FormCheck type="radio" label={i18n.t('cfb-res-auth-' + elem)}
							   value={elem}
							   name="inputRadioAuth"
							   onChange={this.handleAuth}/>
				</div>
			)
		})

		return (<div>{options}</div>);
	}

	inputTypes() {

		const options = [];

		function getLabel(elem) {
			return <div dangerouslySetInnerHTML={
				{__html: i18n.t('cfb-type-' + elem.key, {interpolation: {escapeValue: false}})}
			} />
		}

		this.state.types.map((elem, idx) => {
			options.push(
				<div className='' key={idx}>
					<FormCheck type="radio" label={getLabel(elem)}
							   value={elem.id}
							   name="inputRadioType"
							   onChange={this.handleType}/>
				</div>
			)
		})

		return (<div>{options}</div>);
	}
}


export default restrictedAreaEdition;