import React from "react";
import Form from './element/form'
import Checkout from "./element/checkout";
import Webinar from "./element/webinar";
import Calendar from "./element/calendar";
import Tag from "./element/tag";
import Popup from "./element/popup";

import './edition.css';
import i18n from "../../i18n";
import {EventEmitter} from "../events";

class ElementEdition extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			element: {
				data: {
					label: '',
				},
				id: null
			}
		}
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
		if (typeof nextProps.element != 'undefined') {
			this.setState({element: nextProps.element})
		}
	}

	handleChange = e => {
		const name = e.target.value;
		if (typeof name !== 'undefined') {

			this.setState(
				{
					element: {
						...this.state.element,
						data: {
							...this.state.element.data,
							label: name,
						}
					}
				}
			)
			let rc = this;
			setTimeout(() => {
				rc.props.handleElem(this.state.element);
			}, 200)
		}
	}

	handleChangeOptions = (data) => {

		this.setState(
			{
				element: {
					...this.state.element,
					data: {
						...this.state.element.data,
						...data
					}
				}
			}
		)
		let rc = this;
		setTimeout(() => {
			rc.props.handleElem(this.state.element);
		}, 200)

	}

	handleChangeOptionsURL = (data) => {
		this.setState(
			{
				element: {
					...this.state.element,
					data: {
						...this.state.element.data,
						urls: data
					}
				}
			}
		)
		let rc = this;
		setTimeout(() => {
			rc.props.handleElem(this.state.element);
		}, 200)

	}

	closePopup = () => {
		EventEmitter.dispatch('closeSidebars');
	}

	render() {
		return (
			<div id={'sidebar-edition-element'} className='card sidebar-canvas sidebar-right-absolute'>
				<button type="button" className="close close-popup"
						onClick={this.closePopup}>&times;</button>

				{this.titleElem()}
				<div className={'title-page-sidebar'} id={'element-edition'}>
					<label className='w-100'>
						{i18n.t('cfb-edit-name-element')}
						{this.inputElementName()}
					</label>
				</div>
				{this.isForm()}
				{this.isCheckout()}
				{this.isWebinar()}
				{this.isCalendar()}
				{this.isPopup()}
				{this.isTag()}
			</div>
		)
	}

	titleElem() {
		let value = ''
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.label != 'undefined')
			value = this.state.element.data.label;

		return <h2 className='title-page-sidebar line-bottom'>{value}</h2>;
	}

	isForm() {
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.type != 'undefined')
			if (this.state.element.data.type === 'form')
				return <Form handleChangeOptions={this.handleChangeOptions}
							 handleChangeOptionsURL={this.handleChangeOptionsURL}
							 setCampaignOnArea={this.props.setCampaignOnArea}
							 formElement={this.state.element.data}
							 elements={this.props.elements}/>
			else
				return null;
	}

	isCheckout() {
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.type != 'undefined')
			if (this.state.element.data.type === 'checkout')
				return <Checkout handleChangeOptions={this.handleChangeOptions}
								 handleChangeOptionsURL={this.handleChangeOptionsURL}
								 checkoutElement={this.state.element.data}
								 elements={this.props.elements}/>
			else
				return null;
	}

	isWebinar() {
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.type != 'undefined')
			if (this.state.element.data.type === 'webinar')
				return <Webinar handleChangeOptions={this.handleChangeOptions}
								webinarElement={this.state.element.data}/>
			else
				return null;
	}

	isCalendar() {
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.type != 'undefined')
			if (this.state.element.data.type === 'calendar')
				return <Calendar handleChangeOptions={this.handleChangeOptions}
								 calendarElement={this.state.element.data}/>
			else
				return null;
	}

	isPopup() {
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.type != 'undefined')
			if (this.state.element.data.type === 'click' || this.state.element.data.type === 'time' || this.state.element.data.type === 'exit')
				return <Popup handleChangeOptions={this.handleChangeOptions}
							  popupElement={this.state.element.data}
							  elements={this.props.elements}/>
			else
				return null;
	}

	isTag() {
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.type != 'undefined')
			if (this.state.element.data.type === 'tag')
				return <Tag handleChangeOptions={this.handleChangeOptions}
							funnel_id={this.props.funnel_id}
							tagElement={this.state.element.data}
							elements={this.props.elements}/>
			else
				return null;
	}

	inputElementName() {
		let value = ''
		if (typeof this.state.element.data != 'undefined' && typeof this.state.element.data.label != 'undefined')
			value = this.state.element.data.label;

		return <input type="text" value={value}
					  className={'form-control'}
					  onBlur={this.handleChange}
					  onChange={this.handleChange}/>;
	}
}


export default ElementEdition;
