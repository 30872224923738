import React, {Component} from "react";
import i18n from "../i18n";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faArrowRight,
	faDollarSign,
	faFunnelDollar,
	faPaperPlane,
	faTable, faTrashAlt
} from "@fortawesome/free-solid-svg-icons";
import analyserReport from "../utils/editor/analyser";
import {toast} from "react-toastify";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import {EventEmitter} from "./events";
import {Modal} from "react-bootstrap";

class analyser extends Component {

	constructor(props) {
		super(props);

		this.state = {

			leads: 0,
			clients: 0,
			firstCheckoutRecover: 0,
			totalCheckoutRecover: 0,

			costPerLead: 0,
			profitPerLead: 0,

			costPerClient: 0,
			profitPerClient: 0,

			firstROI: 0,
			totalROI: 0,

			investmentsValue: 0,
			investmentsEachValue: 0,
			investments: [],

			dataLoaded: [],
			dataCompare: [],

			pages: [],
			products: [],

			calc: false,

			show: false,
			newInvestmentsName: ''
		}
	}

	handleClose = () => {
		EventEmitter.dispatch('closeSidebars');
	}

	handleShowAnalyser = () => {

		this.handleClose();

		let toastId = toast(i18n.t('cfb-loading-data'), {autoClose: false});

		this.setState({calc: false});

		let pages = [];
		let products = this.state.products;
		let propsElements = JSON.parse(JSON.stringify(this.props.elements));

		propsElements.forEach(element => {
			if (element.type === 'pageNode') {
				pages.push(element);
			}
		})

		if (products.length === 0) {

			products = [
				[{
					source: 0,
					target: 0,
					conversion: 0,
					value: 0,
					clients: 0
				}]
			];
		}

		this.setState({pages: pages});
		this.setState({products: products});

		analyserReport.get(this.props.funnel.encrypted_id).then(response => {

			toast.update(toastId, {
				render: i18n.t('cfb-loaded'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});

			if (typeof response.data.data.report !== 'undefined') {

				let report = JSON.parse(response.data.data.report);

				// this.setState({pages: report.pages});
				this.setState({products: report.products});
				this.setState({investmentsValue: report.investmentsValue});
				this.setState({investmentsEachValue: report.investmentsEachValue});
				this.setState({investments: report.investments});
			}

			document.getElementById('sidebar-analysis').classList.add('active');
		});
	}

	saveData = () => {

		let data = {};
		data['investmentsValue'] = this.state.investmentsValue;
		data['investmentsEachValue'] = this.state.investmentsEachValue;
		data['investments'] = this.state.investments;
		data['pages'] = this.state.pages;
		data['products'] = this.state.products;

		let toastId = toast(i18n.t('cfb-saving-data'), {autoClose: false});

		analyserReport.post({report: data}, this.props.funnel.encrypted_id).then(response => {

			toast.update(toastId, {
				render: i18n.t('cfb-saved'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});

			let report = JSON.parse(response.data.data.report);

			this.setState({dataLoaded: report});
		});
	}

	closePopup = () => {
		document.getElementById('sidebar-analysis').classList.remove('active');
	}

	render() {

		return (
			<>
				<OverlayTrigger placement="bottom" overlay={
					<Tooltip id={`tooltip-bottom`}>{i18n.t('cfb-handle-analyser')}</Tooltip>
				}>
					<FontAwesomeIcon icon={faTable} data-ba-i={'open-analyser-funnel-builder'}
									 onClick={this.handleShowAnalyser}
									 color='#808B96'/>
				</OverlayTrigger>

				<div id={'sidebar-analysis'} className='card sidebar-analysis big-sidebar sidebar-right-absolute'>
					<button type="button" className="close close-popup" onClick={this.closePopup}>&times;</button>
					<Tabs defaultActiveKey="forecasting" id="uncontrolled-tab-example">
						<Tab eventKey="forecasting" title={i18n.t('cfb-analyses-forecasting')}>
							<button className='btn btn-upper btn-success button-save'
									data-ba-i={'open-analyser-funnel-builder'}
									onClick={this.saveData}>
								{i18n.t('cfb-save')}
							</button>
							<div className={'form-group'} id={'forecasting-edition'}>

								{this.resultsArea()}

								<div style={{padding: '20px'}}/>

								<div className='place-elements-of-analysis'>
									{this.investmentsArea()}
									<div className={'divisor'}/>
									{this.productsArea()}
								</div>
							</div>
						</Tab>
					</Tabs>
				</div>
			</>
		);
	}

	addStep = (idFlux) => {
		let productsArea = this.state.products;

		productsArea[idFlux].push({
			source: 0,
			target: 0,
			conversion: 0,
			value: 0,
			clients: 0
		});

		this.setState({products: productsArea});
	}

	addFlux = () => {
		let productsArea = this.state.products;

		productsArea.push(
			[{
				source: 0,
				target: 0,
				conversion: 0,
				value: 0,
				clients: 0
			}]
		);

		this.setState({products: productsArea});
	}

	productsArea() {

		if (this.state.pages.length === 0) {
			return null
		}

		return <>
			<div className='d-block w-100 text-right'>
				<button className='btn btn-info w-25 mt-2 mb-0 btn-lists' onClick={this.addFlux}>
					{i18n.t('cfb-analyser-add-flow')}
				</button>
			</div>
			{this.productsAreaInputs()}
		</>;
	}

	handleChange = (e, idFlux, idProduct, prop) => {
		let productsArea = this.state.products;
		productsArea[idFlux][idProduct][prop] = e.target.value;
		this.setState({products: productsArea});
	}


	removeFlow = (id) => {
		let productsArea = this.state.products;
		delete productsArea[id];
		this.setState({products: productsArea});
	}

	removeProduct = (id, idProduct) => {
		let productsArea = this.state.products;
		delete productsArea[id][idProduct];
		this.setState({products: productsArea});
	}

	productsAreaInputs() {

		let items = [];

		const options = [];
		options.push(
			<option value=''>{i18n.t('cfb-page-select')}</option>
		)
		this.state.pages.forEach((elem) => {
			options.push(
				<option value={elem.data.idNode}>{elem.data.label}</option>
			)
		})

		this.state.products.forEach((flux, id) => {

			items.push(
				<div className='d-block w-100 text-right'>
					<h2 className={'w-100 d-inline-flex position-relative mt-3 mb-4'}>
						{id !== 0 ?
							<FontAwesomeIcon icon={faTrashAlt} color={'#ff0000'}
											 onClick={(e) => this.removeFlow(id)}/>
							:
							null
						}
						{i18n.t('cfb-analyser-flow')} {id + 1}
					</h2>
				</div>
			);

			try {

				Object.keys(flux).forEach((idFlux, idProduct) => {
					let product = flux[idFlux];

					if (product !== null)
						items.push(
							<div className={'place-pill'}>
								<div>
									<div className='d-inline-flex w-100'>
										<h4 className={'w-33'}>{i18n.t('cfb-analyser-title-source')}</h4>
										<h4 className={'w-25'}>{i18n.t('cfb-analyser-title-target')}</h4>
										<h4 className={'w-18'}>{i18n.t('cfb-analyser-title-conversions')}</h4>
										<h4 className={'w-18'}>{i18n.t('cfb-analyser-title-results-value')}</h4>
										<h4 className={'w-10'}>{i18n.t('cfb-analyser-title-results-clients')}</h4>
									</div>
								</div>
								<div>
									<div className='d-inline-flex w-100 position-relative'>
										<select className='form-control w-25'
												onChange={(e) => this.handleChange(e, id, idProduct, 'source')}
												value={product.source}>
											{options}
										</select>
										<FontAwesomeIcon icon={faArrowRight}/>
										<select className='form-control w-25'
												onChange={(e) => this.handleChange(e, id, idProduct, 'target')}
												value={product.target}>
											{options}
										</select>

										<input className={'form-control w-18'}
											   onChange={(e) => this.handleChange(e, id, idProduct, 'conversion')}
											   value={product.conversion}/>

										<input className={'form-control w-18'}
											   onChange={(e) => this.handleChange(e, id, idProduct, 'value')}
											   value={product.value}/>

										<span className={'w-10 text-center'}>{product.clients.toFixed(2)}</span>

										<FontAwesomeIcon icon={faTrashAlt} color={'#ff0000'}
														 onClick={(e) => this.removeProduct(id, idProduct)}
														 style={{
															 position: 'absolute',
															 right: '-30px',
															 top: '5px',
															 border: 'none'
														 }}/>
									</div>
								</div>
							</div>
						);
				})

				items.push(
					<div className='d-block w-100 text-right'>
						<button className='btn btn-info w-25 mt-2 mb-0' onClick={() => this.addStep(id)}>
							{i18n.t('cfb-analyser-add-step')}
						</button>
					</div>
				);

			} catch (e) {
				console.log(e)
			}
		})

		return <>{items}</>;
	}

	resultsArea() {

		let leads = 0;
		let clients = 0;
		let firstCheckoutRecover = 0;
		let totalCheckoutRecover = 0;
		let costPerLead = 0;
		let profitPerLead = 0;
		let costPerClient = 0;
		let profitPerClient = 0;
		let firstROI = 0;
		let totalROI = 0;

		try {

			const __ret = this.extractResults(this.state.products);

			leads = __ret.leads;
			clients = __ret.clients;
			firstCheckoutRecover = __ret.firstCheckoutRecover;
			totalCheckoutRecover = __ret.totalCheckoutRecover;

			costPerLead = __ret.costPerLead;
			profitPerLead = __ret.profitPerLead;

			costPerClient = __ret.costPerClient;
			profitPerClient = __ret.profitPerClient;

			firstROI = __ret.firstROI;
			totalROI = __ret.totalROI;

		} catch (e) {
			// console.log(e);
		}

		return this.showResults(leads, clients, firstCheckoutRecover, totalCheckoutRecover, costPerLead, profitPerLead, costPerClient, profitPerClient, firstROI, totalROI);
	}

	recursiveCallAgain(page) {

		let elementToReturn = null;

		let propsElements = JSON.parse(JSON.stringify(this.props.elements));

		propsElements.forEach((element) => {
			if (typeof element.target !== 'undefined' && typeof element.source !== 'undefined') {
				if (element.target === page.id) {
					propsElements.forEach((element2) => {
						if (typeof element2.id !== 'undefined' && element2.id === element.source) {
							if (element2.type === 'pageNode') {
								elementToReturn = element2;
							} else {
								elementToReturn = this.recursiveCallAgain(element2);
							}
						}
					})
				}
			}
		})

		return elementToReturn;
	}

	isEnableProp = (idSource, prop) => {

		let hasProp = false;

		this.state.pages.forEach(page => {
			if (page.id == idSource) {
				hasProp = page.data.options[prop];
			}
		})

		return hasProp;
	}

	extractResults(pages) {

		// const pagesLoadeds = [];
		let leads = 0;
		let clients = 0;
		let firstClients = 0;
		let firstCheckoutRecover = 0;
		let totalCheckoutRecover = 0;
		let costPerLead = 0;
		let profitPerLead = 0;
		let costPerClient = 0;
		let profitPerClient = 0;
		let firstROI;
		let totalROI;

		let investmentValue = this.state.investmentsValue.replace(",", ".");
		let amountOfLeads = this.state.investmentsValue.replace(",", ".") / this.state.investmentsEachValue.replace(",", ".");

		try {
			this.state.investments.forEach((investment) => {
				if (investment !== null) {
					investmentValue = ~~investmentValue + ~~investment.totalValue.replace(",", ".");
					amountOfLeads += (~~investment.totalValue.replace(",", ".") / ~~investment.eachValue.replace(",", "."));
				}
			})
		} catch (e) {
			investmentValue = 0;
			amountOfLeads = 0;
		}


		let clientsSubFlow = [];

		pages.forEach((flux, idFlux) => {
			if (flux !== null)
				flux.forEach((product, idProduct) => {

					if (product !== null) {

						if (idFlux === 0) {
							if (clients === 0) {
								clients = amountOfLeads * product.conversion / 100;
							} else {
								clients = clients * product.conversion / 100;
							}

							pages[idFlux][idProduct]['clients'] = clients;

							if (this.isEnableProp(product.source, 'has_form') && leads === 0) {
								leads = clients;
							}
							// if (this.isEnableProp(product.source, 'has_checkout') && firstClients === 0) {
							if (firstClients === 0 && product.value !== '' && product.value != 0) {
								firstClients = clients;
								firstCheckoutRecover = clients * product.value;
							}

							// if (this.isEnableProp(product.source, 'has_checkout')) {
							if (product.value !== '' && product.value != 0)
								totalCheckoutRecover += clients * product.value;
							// }
						} else {
							let clientsFlow = 0;

							pages.forEach((flux2) => {
								flux2.forEach((product2) => {
									if (product2.target === product.source && clientsFlow === 0) {
										console.log(product2);
										clientsFlow = product2.clients * product.conversion / 100;
									}
								})
							})

							if (typeof clientsSubFlow[idFlux] === 'undefined')
								clientsSubFlow[idFlux] = clientsFlow;
							else {
								clientsSubFlow[idFlux] = clientsSubFlow[idFlux] * product.conversion / 100;
							}

							pages[idFlux][idProduct]['clients'] = clientsSubFlow[idFlux];

							// if (this.isEnableProp(product.source, 'has_checkout')) {
							if (product.value !== '' && product.value != 0)
								totalCheckoutRecover += clientsSubFlow[idFlux] * product.value;
							// }
						}
					}
				})
		})


		if (clients !== 0) {
			costPerClient = investmentValue / clients
			profitPerClient = totalCheckoutRecover / clients;

			if (clients < 1) {
				costPerClient = costPerClient / 100;
				profitPerClient = profitPerClient / 100;
			}
		}
		if (leads !== 0) {
			costPerLead = investmentValue / leads;
			profitPerLead = totalCheckoutRecover / leads;
		}

		firstROI = ((firstCheckoutRecover - investmentValue) / investmentValue);
		totalROI = ((totalCheckoutRecover - investmentValue) / investmentValue);

		if (isNaN(firstROI)) {
			firstROI = 0
		}
		if (isNaN(totalROI)) {
			totalROI = 0
		}

		clients = firstClients;

		return {
			leads, 			//ok
			clients,  		//ok
			firstCheckoutRecover, //ok
			totalCheckoutRecover, //ok
			costPerLead,//ok
			profitPerLead,//ok
			costPerClient,//ok
			profitPerClient,//ok
			firstROI,
			totalROI
		};
	}

	showResults(leads, clients, firstCheckoutRecover, totalCheckoutRecover, costPerLead, profitPerLead, costPerClient, profitPerClient, firstROI, totalROI) {

		function bottom(ciffron, value, value2, normal, big, suffix) {
			if (value2 !== null)
				return <>
					<div className={big ? 'w-100' : 'w-50 float-left'}>
						{ciffron ? '$' : ''}<strong>{value.toFixed(2)}</strong>
						<br/>
						<small>{normal ? i18n.t('cfb-lead' + suffix) : i18n.t('cfb-first-sale')}</small>
						<br/>
					</div>
					<div className={big ? 'w-100' : 'w-50 float-right text-right'}>
						{ciffron ? '$' : ''}<strong>{value2.toFixed(2)}</strong>
						<br/>
						<small>{normal ? i18n.t('cfb-client' + suffix) : i18n.t('cfb-total-sale')}</small>
					</div>
				</>;
		}

		function box(icon, text, value, value2, ciffron = false, normal = true, big = true, suffix = '') {
			return <div className='large-box'>
				<label className='w-100 d-flex'>
					<div className='rounded-circle icon-results'>
						<FontAwesomeIcon icon={icon}/>
					</div>
					<span>{i18n.t(text)}</span>
				</label>
				<div className='bottom-data'
					 style={{paddingTop: '30px', paddingLeft: '10px', paddingRight: '10px'}}>
					<div className='float-left w-100'>
						{bottom(ciffron, value, value2, normal, big, suffix)}
					</div>
				</div>
			</div>;
		}

		return <>
			<div className='first-line d-flex'>
				{box(faPaperPlane, 'cfb-users', leads, clients, null, true, false)}
				{box(faDollarSign, 'cfb-sales', firstCheckoutRecover, totalCheckoutRecover, true, false, false)}
			</div>
			<div className='second-line d-flex'>
				{box(faDollarSign, 'cfb-costs', costPerLead, costPerClient, true, true, true, '-per')}
				{box(faDollarSign, 'cfb-profits', profitPerLead, profitPerClient, true, true, true, '-per')}

				{/*roi area*/}
				<div className='large-box'>
					<label className='w-100 d-flex'>
						<div className='rounded-circle icon-results'>
							<FontAwesomeIcon icon={faFunnelDollar}/>
						</div>
						<span>{i18n.t('cfb-rois')}</span>
					</label>
					<div className='bottom-data w-100'
						 style={{paddingTop: '30px', paddingLeft: '10px', paddingRight: '10px'}}>
						<div className='float-left w-100 roi-area'>
							<strong>{firstROI.toFixed(2)}X</strong>
							<br/>
							<small>{i18n.t('cfb-first-sale')}</small>
							<br/>
							<strong>{totalROI.toFixed(2)}X</strong>
							<br/>
							<small>{i18n.t('cfb-total-sale')}</small>
						</div>
					</div>
				</div>
			</div>
		</>;
	}

	handleChangeAll = (e) => {
		this.setState({investmentsValue: e.target.value});
	}

	handleChangeEach = (e) => {
		this.setState({investmentsEachValue: e.target.value});
	}

	handleChangeInvestmentsAll = (e, idx) => {

		let investments = this.state.investments;
		investments[idx].totalValue = e.target.value;

		this.setState({investments: investments});
	}

	handleChangeInvestmentsEach = (e, idx) => {

		let investments = this.state.investments;
		investments[idx].eachValue = e.target.value;

		this.setState({investments: investments});
	}

	removeInvestment = (idx) => {
		let investments = this.state.investments;
		delete investments[idx];
		this.setState({investments: investments});
	}

	handleOptionName = (e) => {
		this.setState({newInvestmentsName: e.target.value});
	}

	handleClosePopup = () => {
		this.setState({show: false});
	}

	showPopup = () => {
		this.setState({newInvestmentsName: ''});
		this.setState({show: true});
	}

	handleApply = () => {

		let investments = this.state.investments;

		investments.push({
			name: this.state.newInvestmentsName,
			totalValue: 0,
			eachValue: 0,
		})

		this.setState({investments: investments});
		this.setState({show: false});
	}

	investmentsArea() {
		return <>
			{i18n.t('cfb-analyser-title-investments')}
			<button className='btn btn-info btn-lists position-absolute'
					onClick={this.showPopup}
					style={{right: '30px'}}>{i18n.t('cfb-investments-add')}
			</button>
			<div style={{padding: '5px'}}/>

			<div className='place-pill'>
				<label className='w-50' title={i18n.t('cfb-analyser-title-investments-total-ads')}>
					{i18n.t('cfb-analyser-title-investments-total-ads')}
				</label>

				<label className='w-25'>
					{i18n.t('cfb-analyser-title-investments-total')}
					<input type='input' id='investment-input' className='form-control'
						   onBlur={this.handleChangeAll}
						   onChange={this.handleChangeAll}
						   value={this.state.investmentsValue}/>
				</label>
				<label className='w-25'>
					{i18n.t('cfb-analyser-title-investments-each')}
					<input type='input' id='investment-each-input' className='form-control'
						   onBlur={this.handleChangeEach}
						   onChange={this.handleChangeEach}
						   value={this.state.investmentsEachValue}/>
				</label>
			</div>

			{this.state.investments.map((item, idx) => this.putInvestmentsArea(item, idx))}

			<Modal show={this.state.show} onHide={this.handleClosePopup} dialogClassName="modal-campaign">
				<Modal.Header>
					<h3 className="modal-title">{i18n.t('cfb-create-investments-title')}</h3>
					<button type="button" className="close close-popup" onClick={this.handleClosePopup}>&times;</button>
				</Modal.Header>

				<Modal.Body>
					<label className='w-100'>
						{i18n.t('cfb-investment-name')}
						<input className='form-control'
							   onChange={this.handleOptionName}
							   value={this.state.newInvestmentsName}/>
					</label>
				</Modal.Body>

				<Modal.Footer>
					<button type="button" className="btn btn-link" onClick={this.handleClosePopup}>
						{i18n.t('cfb-close')}
					</button>
					<button type="button" className="btn btn-success btn-upper" onClick={this.handleApply}>
						{i18n.t('cfb-save-changes')}
					</button>
				</Modal.Footer>
			</Modal>
		</>;
	}

	putInvestmentsArea(item, idx) {
		try {
			return <div className='place-pill'>
				<label className='w-50' title={item.name}>
					{item.name}
				</label>

				<label className='w-25'>
					{i18n.t('cfb-analyser-title-investments-total')}
					<input type='input' id='investment-input' className='form-control'
						   onBlur={(e) => this.handleChangeInvestmentsAll(e, idx)}
						   onChange={(e) => this.handleChangeInvestmentsAll(e, idx)}
						   value={item.totalValue}/>
				</label>
				<label className='w-25' style={{position: 'relative'}}>
					{i18n.t('cfb-analyser-title-investments-each')}
					<input type='input' id='investment-each-input' className='form-control'
						   style={{width: '70%'}}
						   onBlur={(e) => this.handleChangeInvestmentsEach(e, idx)}
						   onChange={(e) => this.handleChangeInvestmentsEach(e, idx)}
						   value={item.eachValue}/>
					<FontAwesomeIcon icon={faTrashAlt} color={'#ff0000'}
									 onClick={(e) => this.removeInvestment(idx)}
									 style={{position: 'absolute', right: 0, top: '25px', border: 'none'}}/>
				</label>
			</div>;
		} catch (e) {
			console.log(item, e);
			this.removeInvestment(idx);
		}
	}
}

export default analyser;
