import Http from './../http';

const prefix = '/calendar';

const calendar = new (function () {

	this.getCalendars = function () {
		return Http.authApi().get(`${prefix}`);
	};

})();

export default calendar;