import React from "react";
import {EventEmitter} from "../events";
import mailingBoss from "../../utils/editor/mailingboss";
import {Modal, Button} from "react-bootstrap";
import {toast} from "react-toastify";
import i18n from "../../i18n";

class MbossEmailEditor extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			show: false,
			uuid: null,
			loaded: false,
			subject: '',
		}
	}

	componentDidMount() {
		EventEmitter.subscribe('editionEmail', (id) => this.editionEmail(id));
	}

	editionEmail(id) {

		this.setState({uuid: id});

		let toastId = toast(i18n.t('cfb-loading-data'), {autoClose: false});

		mailingBoss.getCampaignData(id).then((response) => {
			this.setState({show: true});

			toast.update(toastId, {
				render: i18n.t('cfb-loaded'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});

			this.setState({subject: response.data.data.subject});

			document.getElementById('idEditorContent').value = response.data.data.template.content;

			// eslint-disable-next-line no-undef
			jQuery(function () {
				// eslint-disable-next-line no-undef
				jQuery('#mail-editor').mbeditor('idEditorContent', {
					autoChangeContent: true,
					height: 600
				});
			});
		})
	}

	handleChange = e => {
		const value = e.target.value;
		if (value !== '' && typeof value !== 'undefined') {
			this.setState({subject: value})
		}
	}

	inputSubject() {

		return <input type="text" value={this.state.subject}
					  className={'form-control'}
					  onBlur={this.handleChange}
					  onChange={this.handleChange}/>;
	}

	render() {
		const handleClose = () => this.setState({show: false});
		const handleApply = () => {

			this.setState({show: false});

			let toastId = toast(i18n.t('cfb-saving-data'), {autoClose: false});

			mailingBoss.updateCampaign({
				subject: this.state.subject,
				content: document.getElementById('idEditorContent').value
			}, this.state.uuid).then(() => {
				toast.update(toastId, {
					render: i18n.t('cfb-saved'),
					type: toast.TYPE.SUCCESS,
					autoClose: 5000,
					className: 'rotateY animated'
				});
			});
		}

		return (
			<div>
				<Modal show={this.state.show} onHide={handleClose} size="xl"
					   dialogClassName="modal-90w">
					<Modal.Header>
						<h3 className="modal-title">{i18n.t('cfb-email-edition')}</h3>
						<button type="button" className="close close-popup" onClick={handleClose}>&times;</button>
					</Modal.Header>

					<Modal.Body>
						<div className={'form-group'} id={'page-edition'}>
							<label className='w-100'>
								{i18n.t('cfb-subject-name')}
								{this.inputSubject()}
							</label>
						</div>
						<textarea id="idEditorContent" style={{display: 'none'}}/>
						<div id="mail-editor" className='w-100'/>
					</Modal.Body>

					<Modal.Footer>
						<button type="button" className="btn btn-link" onClick={handleClose}>
							{i18n.t('cfb-close')}
						</button>
						<button type="button" className="btn btn-success btn-upper" onClick={handleApply}>
							{i18n.t('cfb-save-changes')}
						</button>

					</Modal.Footer>
				</Modal>
			</div>
		)
	}
}

export default MbossEmailEditor;