import React, {Component} from 'react';

import './index.css'

import EditorFlow from './Flow/flow';

import AdditionSidebar from "./AdditionSidebar/addition";
import PageEdition from "./EditionSidebar/pageEdition";
import ElementEdition from "./EditionSidebar/elementEdition";
import RestrictedAreaEdition from "./EditionSidebar/restrictedAreaEdition";
import MbossEmailEditor from "./Flow/mbossEmailEditor";

import FunnelElements from '../utils/editor/funnelsElements'
import Funnels from '../utils/dashboard/funnels'

import Header from "../Header";
import {toast} from 'react-toastify';
import {addEdge} from "react-flow-renderer";
import i18n from "../i18n";

import {EventEmitter} from "./events";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import {Modal} from "react-bootstrap";
import CampaignEdition from "./EditionSidebar/campaignEdition";
import AutomationEdition from "./EditionSidebar/automationEdition";

class Editor extends Component {

	constructor() {
		super();
		this.state = {
			show: false,
			websiteNew: {},
			started: false,
			id: (window.location.pathname + window.location.search).substr(8),
			elements: [],
			page: {},
			element: {},
			funnel: {},
			newID: new Date().valueOf()
		}
	}

	componentDidMount() {

		FunnelElements.get(this.state.id).then((response) => {
			try {

				let elements = JSON.parse(response.data.data[0].elements);

				elements.forEach((element, idx) => {
					if (
						typeof element.source !== 'undefined' &&
						typeof element.target !== 'undefined'
					) {
						elements[idx].label = '';
					}
				})

				this.setState({elements: elements});
				this.setState({started: true});
				this.setColorRestrictedAreas();
			} catch (e) {
				this.setState({started: true});
			}

		}, (error) => {
			console.log(error);
		});

		Funnels.getFunnel(this.state.id).then((response) => {
			this.setState({funnel: response.data.data});
		}, (error) => {
			console.log(error);
		});

		EventEmitter.subscribe('closeSidebars', () => this.closeSidebars());
		EventEmitter.subscribe('setHomePage', (data) => this.setHomePage(data));
		EventEmitter.subscribe('editionPage', (id) => this.editionPage(id));
		EventEmitter.subscribe('editionSubElement', (id) => this.editionSubElement(id));
		EventEmitter.subscribe('editionRestrictedArea', (id) => this.editionRestrictedArea(id));
		EventEmitter.subscribe('showDataOnArea', (data) => this.showDataOnArea(data));
		EventEmitter.subscribe('changeOptionsNode', (optionsData) => this.changeOptionsNode(optionsData));
	}

	closeSidebars() {
		try {
			document.getElementById('addPagePopup').style.display = 'none';
			document.getElementById('sidebar-edition-element').classList.remove('active');
			document.getElementById('sidebar-edition-restricted-area').classList.remove('active');
			document.getElementById('sidebar-campaign-element').classList.remove('active');
			document.getElementById('sidebar-automation-element').classList.remove('active');
			document.getElementById('sidebar-edition').classList.remove('active');
			if (document.getElementById('sidebar-analysis'))
				document.getElementById('sidebar-analysis').classList.remove('active');
		} catch (e) {
			console.log(e);
		}
	}

	setHomePage(data) {

		let elements = this.state.elements;

		Object.keys(elements).forEach(idx => {
			if (elements[idx].id === data.idPage) {
				if (typeof elements[idx].data !== 'undefined')
					elements[idx].data['home'] = data.value;
			} else {
				if (typeof elements[idx].data !== 'undefined')
					elements[idx].data['home'] = false;
			}
		})

		this.handleElements(elements)
	}

	//todo fazer um controlador disso tudo
	editionPage(id) {

		this.setState({page: this.findElement(id)});

		document.getElementById('addPagePopup').style.display = 'none';
		document.getElementById('sidebar-edition-element').classList.remove('active');
		document.getElementById('sidebar-campaign-element').classList.remove('active');
		document.getElementById('sidebar-edition-restricted-area').classList.remove('active');
		document.getElementById('sidebar-edition').classList.add('active');
	}

	editionSubElement(id) {

		this.setState({element: this.findElement(id)});

		document.getElementById('addPagePopup').style.display = 'none';
		document.getElementById('sidebar-edition').classList.remove('active');
		document.getElementById('sidebar-campaign-element').classList.remove('active');
		document.getElementById('sidebar-edition-restricted-area').classList.remove('active');
		document.getElementById('sidebar-edition-element').classList.add('active');
	}

	editionRestrictedArea(id) {

		this.setState({element: this.findElement(id)});

		document.getElementById('addPagePopup').style.display = 'none';
		document.getElementById('sidebar-edition').classList.remove('active');
		document.getElementById('sidebar-campaign-element').classList.remove('active');
		document.getElementById('sidebar-edition-element').classList.remove('active');
		document.getElementById('sidebar-edition-restricted-area').classList.add('active');
	}

	showDataOnArea(data) {

		let pagesNodes = {};

		Object.keys(data).forEach(idx => {
			this.state.elements.forEach(element => {
				if (
					typeof element.data !== 'undefined' &&
					typeof element.data.label !== 'undefined' &&
					typeof element.type !== 'undefined' &&
					element.type === 'pageNode' &&
					element.data.label === idx
				) {
					pagesNodes[element.id] = data[idx];
				}
			})
		})

		let elements = this.state.elements;
		this.state.elements.forEach((element, idx) => {
			if (
				typeof element.source !== 'undefined' &&
				typeof element.target !== 'undefined'
			) {

				let source = element.source
				let target = element.target

				if (typeof pagesNodes[source] === 'undefined') {
					let tempSource = '';
					this.state.elements.forEach(element2 => {
						if (
							typeof element2.source !== 'undefined' &&
							typeof element2.target !== 'undefined'
						) {
							if (element2.target === source) {
								tempSource = element2.source;
								return false;
							}
						}
					})
					source = tempSource;
				}

				if (
					typeof pagesNodes[source] !== 'undefined' &&
					typeof pagesNodes[target] !== 'undefined'
				) {
					let per = (~~pagesNodes[target] * 100 / ~~pagesNodes[source]).toFixed(2).toString() + '%';
					elements[idx] = {...elements[idx], label: per};
				}
			}
		})

		this.setState({elements: elements});

		let pages = document.getElementsByClassName('title-page');
		Object.keys(data).forEach(idx => {
			Object.keys(pages).forEach(i => {
				if (pages[i].innerHTML === idx) {

					let placePageCount = document.createElement("div");
					placePageCount.setAttribute("class", "counter-page");
					placePageCount.textContent = data[idx]

					pages[i].parentNode.appendChild(placePageCount);
				}
			})
		})

	}

	findElement(id) {
		let elem = {};
		this.state.elements.forEach(el => {
			if (el.id === id) {
				elem = el
			}
		})
		return elem;
	}

	findElementsSource(id) {
		let elem = [];
		this.state.elements.forEach(el => {
			if (el.source === id) {
				elem.push(el)
			}
		})
		return elem;
	}

	getNextID = () => {
		return new Date().valueOf() + Math.floor((Math.random() * 1000) + 1);
	}

	setColorRestrictedAreas = () => {

		let elements = this.state.elements;

		setTimeout(() => {

			elements.forEach((el) => {
				if (el.type === 'restrictedAreaNode')
					document.querySelectorAll('#restrictedArea' + el.data.idNode).forEach((elem) => {
						elem.style.backgroundColor = el.data.color;
					})
			})

		}, 1000);
	}


	changeOptionsNode = (options) => {
		let element = this.findElement(options.idNode);

		element.options = options;
		this.setState({elements: this.changeElement(element, this.state.elements)});
	}

	addBlueprint = options => {

		const rc = this;

		let elementsBlueprint = JSON.parse(options.elements);

		let objNewIds = {};
		elementsBlueprint.forEach((element) => {
			if (typeof element.id !== 'undefined' && typeof element.source === 'undefined') {
				objNewIds[element.id] = rc.getNextID().toString();
			}
		})

		elementsBlueprint.forEach((element, idx) => {

			if (typeof objNewIds[element.id] !== 'undefined') {
				let newId = objNewIds[element.id];
				if (typeof element.id !== 'undefined') {
					elementsBlueprint[idx]['id'] = newId
				}
				if (typeof element.data != 'undefined' && typeof element.data.idNode != 'undefined') {
					elementsBlueprint[idx]['data']['idNode'] = newId;
				}
				if (typeof element.data != 'undefined' && typeof element.data.options != 'undefined' && typeof element.data.options.idNode != 'undefined') {
					elementsBlueprint[idx]['data']['options']['idNode'] = objNewIds[elementsBlueprint[idx]['data']['options']['idNode']];
				}
				if (typeof element.data != 'undefined' && typeof element.data.params != 'undefined' && typeof element.data.params.idNode != 'undefined') {
					elementsBlueprint[idx]['data']['params']['idNode'] = newId;
				}
			}

			if (typeof element.source !== 'undefined' && typeof element.target !== 'undefined') {
				elementsBlueprint[idx]['source'] = objNewIds[element.source];
				elementsBlueprint[idx]['target'] = objNewIds[element.target];
				elementsBlueprint[idx]['id'] = 'reactflow__edge-' + elementsBlueprint[idx]['source'] + '-' + elementsBlueprint[idx]['target'];
			}
		})

		let elements = this.state.elements.concat(elementsBlueprint);

		this.setState({elements: elements});
	}

	addNode = (options, userPage) => {

		let element = document.getElementsByClassName('react-flow__nodes')[0],
			scaleX = element.getBoundingClientRect().width / element.offsetWidth,
			scaleY = element.getBoundingClientRect().height / element.offsetHeight;

		options['have_checkout'] = (!!options.has_checkout);
		options['have_webinar'] = (!!options.has_webinar);
		options['have_calendar'] = (!!options.has_calendar);
		options['have_form'] = (!!options.has_form);
		options['type'] = options.has_form ? 'form' : (options.has_checkout ? 'checkout' : (options.has_webinar ? 'webinar' : (options.has_calendar ? 'calendar' : 'default')));


		let _id = this.getNextID().toString();
		let elements = this.state.elements.concat({
			id: _id,
			type: 'pageNode',
			data: {...{label: options.desc, type: 'page', idNode: _id}, ...{options}},
			position: {x: window.innerWidth / scaleX / 2, y: window.innerHeight / scaleY / 2},
			userPage: userPage
		});

		elements = this.addSubElement(JSON.parse(JSON.stringify(options)), elements, scaleX, scaleY, _id);

		this.setState({elements: elements});
	}

	addExternalNode = (options, isUserImg) => {


		let element = document.getElementsByClassName('react-flow__nodes')[0],
			scaleX = element.getBoundingClientRect().width / element.offsetWidth,
			scaleY = element.getBoundingClientRect().height / element.offsetHeight;

		let _id = this.getNextID().toString();
		let elements = this.state.elements.concat({
			id: _id,
			type: 'externalNode',
			data: {
				...{
					label: !isUserImg ? options.desc : '',
					type: 'external',
					idNode: _id,
					userImg: isUserImg
				}, ...{options}
			},
			position: {x: window.innerWidth / scaleX / 2, y: window.innerHeight / scaleY / 2},
		});

		this.setState({elements: elements});

	}

	addRestrictedNode = () => {

		let element = document.getElementsByClassName('react-flow__nodes')[0],
			scaleX = element.getBoundingClientRect().width / element.offsetWidth,
			scaleY = element.getBoundingClientRect().height / element.offsetHeight;

		let _id = this.getNextID().toString();
		let elements = this.state.elements.concat({
			id: _id,
			type: 'restrictedAreaNode',
			data: {
				label: i18n.t('cfb-restricted-area-label'),
				desc: i18n.t('cfb-restricted-area-desc'),
				type: 'restrictedArea',
				idNode: _id,
				color: 'blue'
			},
			position: {x: window.innerWidth / scaleX / 2, y: window.innerHeight / scaleY / 2},
		});

		this.setState({elements: elements});
	}

	addAutomationNode = options => {


		let campaignNode = this.findElement(options.idNode);

		let x = campaignNode.position.x;
		let y = campaignNode.position.y;


		if (options.type === 'open') {
			x += 250;
			y -= 50;
		}
		if (options.type === 'not-open') {
			x += 250;
			y += 50;
		}
		if (options.type === 'click') {
			x += 500;
			y -= 50;
		}
		if (options.type === 'not-click') {
			x += 500;
			y += 50;
		}

		options['config'] = 'none';
		options['tag'] = 'none';
		options['tag_name'] = '';
		options['day'] = 0;
		options['min'] = 0;

		let _id = this.getNextID().toString();
		let elements = this.state.elements.concat({
			id: _id,
			type: 'automationNode',
			data: {...{label: options.desc, type: 'automation', idNode: _id}, ...{options}},
			position: {x: x, y: y},
		});

		if (options.type === 'open' || options.type === 'not-open') {

			elements = addEdge({
				source: options.idNode,
				target: _id,
				type: 'canvasEdge',
				arrowHeadType: 'arrowclosed'

			}, elements);
		}

		if (options.type === 'click' || options.type === 'not-click') {

			let elementsTarget = this.findElementsSource(options.idNode);
			let idNodeOpen = '';

			elementsTarget.forEach((el) => {
				let findElement = this.findElement(el.target);
				if (findElement.data.options.type === 'open')
					idNodeOpen = findElement.id;
			})

			if (idNodeOpen !== '') {

				elements = addEdge({
					source: idNodeOpen,
					target: _id,
					type: 'canvasEdge',
					arrowHeadType: 'arrowclosed'
				}, elements);
			}
		}

		this.setState({elements: elements});
	}

	addSubElement(options, elements, scaleX, scaleY, _id) {
		if (options.has_checkout || options.has_form || options.has_webinar || options.has_calendar) {
			let _targetID = this.getNextID().toString();

			console.log(_targetID);

			let name = (options.has_checkout ? 'checkout' : (options.has_webinar ? 'webinar' : (options.has_calendar ? 'calendar' : 'form')));
			let className = (options.has_checkout ? 'checkout-element' : (options.has_webinar ? 'webinar-element' : (options.has_calendar ? 'calendar-element' : 'form-element')));

			options['idNode'] = _targetID;

			elements = elements.concat({
				id: _targetID,
				deleteable: true,
				type: 'subElementNode',
				data: {...{label: name, type: name, idNode: _targetID}, ...{params: options}},
				position: {x: window.innerWidth / scaleX / 2, y: (window.innerHeight / scaleY / 2) + (210)},
				className: className + ' flow-sub-element',
			})

			elements = addEdge({
				source: _id,
				target: _targetID,
				type: 'canvasEdge',
				arrowHeadType: 'arrowclosed'
			}, elements);
		}
		return elements;
	}

	changeElement = (page, els) => {
		els.forEach((el, i) => {
			if (el.id === page.id) {
				els[i] = page;
			}
		})
		return els;
	}

	handleElements = elements => {
		this.setState({elements: elements});
	}

	handlePage = params => {

		this.setState({page: {...this.state.page, ...params}});
		this.setState({elements: this.changeElement(this.state.page, this.state.elements)});

		document.querySelector('[data-nodeid="' + this.state.page.id + '"] > .title-page').textContent = this.state.page.data.label;
	}

	handleResArea = params => {
		this.setDataElements(params);

		document.querySelector('[data-nodeid="' + this.state.element.id + '"] > .restricted-area-circle').style.backgroundColor = this.state.element.data.color;

		document.querySelectorAll('#restrictedArea' + this.state.element.id).forEach((elem) => {
			elem.style.backgroundColor = this.state.element.data.color;
		})
	}

	setCampaignAutomationItemOnArea = (data, idParent) => {

		let elements = this.state.elements;
		let elementParent = this.findElement(idParent);

		let fixObj = {
			campaign_uid: data.value,
			name: data.label
		}

		elements = this.setCampaignItemOnArea(elements, fixObj, idParent, 3, elementParent.position.x, elementParent.position.y);

		this.setState({elements: elements});
	}

	setCampaignOnArea = (data) => {

		let element = document.getElementsByClassName('react-flow__nodes')[0],
			scaleX = element.getBoundingClientRect().width / element.offsetWidth,
			scaleY = element.getBoundingClientRect().height / element.offsetHeight;

		let _id = this.getNextID().toString();

		let nodeGroup = {
			id: _id,
			type: 'campaignNode',
			data: {
				label: data.name,
				type: 'campaignNodeGroup',
				isEmail: false,
				group_uid: data.group_uid,
				idNode: _id
			},
			position: {x: window.innerWidth / scaleX / 2, y: window.innerHeight / scaleY / 2},
		};

		let elements = this.state.elements.concat(nodeGroup);

		data.campaigns.forEach((campaign, index) => {
			elements = this.setCampaignItemOnArea(elements, campaign, _id, index, (window.innerWidth / scaleX / 2), (window.innerHeight / scaleY / 2 + (210)));
		})

		this.setState({elements: elements});
	}

	setCampaignItemOnArea = (elements, data, idParent, number, scaleX, scaleY) => {

		let _targetID = this.getNextID().toString();

		elements = elements.concat({
			id: _targetID,
			type: 'campaignNode',
			data: {
				label: data.name,
				deleteable: true,
				type: 'campaignNodeGroup',
				campaign_uid: data.campaign_uid,
				idNode: _targetID
			},
			position: {x: scaleX + (100 * number), y: scaleY},
		})

		elements = addEdge({
			source: idParent,
			target: _targetID,
			type: 'canvasEdge',
			arrowHeadType: 'arrowclosed'
		}, elements);

		return elements;

	}

	handleElem = params => {
		this.setDataElements(params);
	}

	setDataElements(params) {

		this.setState({element: {...this.state.element, ...params}});
		this.setState({elements: this.changeElement(this.state.element, this.state.elements)});

		document.querySelector('[data-nodeid="' + this.state.element.id + '"] > .title-elem').textContent = this.state.element.data.label;
	}

	setPageInRestrictedArea = (idPage, idRestrictedArea, set) => {

		let page = this.findElement(idPage);

		if (!page.data.options.restrictedAreas)
			page.data.options['restrictedAreas'] = [];

		if (set) {
			if (page.data.options['restrictedAreas'].indexOf(idRestrictedArea) === -1)
				page.data.options['restrictedAreas'].push(idRestrictedArea);
		} else {
			if (page.data.options['restrictedAreas'].indexOf(idRestrictedArea) !== -1)
				page.data.options['restrictedAreas'].splice(page.data.options['restrictedAreas'].indexOf(idRestrictedArea), 1);
		}

		this.setState({elements: this.changeElement(page, this.state.elements)});
		this.setColorRestrictedAreas();
	}

	createSubNode = (params, type) => {

		let _targetID = this.getNextID().toString();

		let className = (type === 'checkout' ? 'checkout-element' : 'form-element');

		params = JSON.parse(JSON.stringify(params));
		params['idNode'] = _targetID;

		let elements = this.state.elements.concat({
			id: _targetID,
			deleteable: true,
			data: {...{label: type, type: type}, ...{params}},
			type: 'subElementNode',
			position: {x: params.position.x, y: params.position.y + (180)},
			className: className + ' flow-sub-element'
		})

		elements = addEdge({
			source: params.id,
			target: _targetID,
			// animated: true,
			type: 'canvasEdge',
			arrowHeadType: 'arrowclosed'
		}, elements);

		this.setState({elements: elements});
	}

	createTag = (params) => {

		let _targetID = this.getNextID().toString();

		params = JSON.parse(JSON.stringify(params));
		params['idNode'] = _targetID;

		let elements = this.state.elements.concat({
			id: _targetID,
			deleteable: true,
			data: {...{label: 'tag', type: 'tag'}, ...{params}},
			type: 'subElementNode',
			position: {x: params.position.x + (200), y: params.position.y + (200)},
			className: 'tag-element flow-sub-element',
		})

		elements = addEdge({
			source: params.id,
			target: _targetID,
			type: 'canvasEdge',
			arrowHeadType: 'arrowclosed'
		}, elements);

		this.setState({elements: elements});

	}

	createPopup = (params, type) => {

		let _targetID = this.getNextID().toString();

		let valuePosPopup = (type === 'click' ? 200 : (type === 'time' ? 250 : 300));

		params = JSON.parse(JSON.stringify(params));
		params['idNode'] = _targetID;

		let elements = this.state.elements.concat({
			id: _targetID,
			deleteable: true,
			type: 'subElementNode',
			option: 'button',
			data: {...{label: type + ' popup', type: type}, ...{params}},
			position: {x: params.position.x + (valuePosPopup), y: params.position.y + (valuePosPopup)},
			className: 'popup-element flow-sub-element',
		})

		elements = addEdge({
			source: params.id,
			target: _targetID,
			type: 'canvasEdge',
			arrowHeadType: 'arrowclosed'
		}, elements);

		this.setState({elements: elements});
	}

	//header functions
	showSideBarAddPage = () => {
		document.getElementById('sidebar-edition').classList.remove('active');
		document.getElementById('addPagePopup').style.display = 'block';
	}

	changeFunnel = (funnel) => {

		let funnelProps = this.state.funnel;
		funnelProps.website_id = funnel.website_id;

		this.setState({funnel: funnelProps});
	}

	saveFunnel = () => {

		let toastId = toast(i18n.t('cfb-saving-data'), {autoClose: false});

		FunnelElements.put({
			encrypted_id: this.state.id,
			elements: JSON.stringify(this.state.elements)
		}).then(() => {
			toast.update(toastId, {
				render: i18n.t('cfb-success-save'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		}, (error) => {
			console.log(error);
		});
	}

	generateSite = () => {
		let toastId = toast(i18n.t('cfb-generating-site'), {autoClose: false});
		Funnels.generateSite({
			encrypted_id: this.state.id,
			elements: JSON.stringify(this.state.elements)
		}).then((response) => {

			this.setState({websiteNew: response.data.data})

			let funnel = this.state.funnel;
			funnel.website_id = response.data.data.id;
			this.setState({funnel: funnel});

			toast.update(toastId, {
				render: i18n.t('cfb-generating-site-success'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
			this.setState({show: true});
		}, (error) => {
			console.log(error);
		});
	}

	render() {
		return (
			<div className='container-fluid'>
				<Header type='editor'
						addNode={this.addNode}
						showSideBarAddPage={this.showSideBarAddPage}
						saveFunnel={this.saveFunnel}
						changeFunnel={this.changeFunnel}
						funnel={this.state.funnel}
						elements={this.state.elements}
						gotoWebsite={this.goToSite}
						generateSite={this.generateSite}/>
				<div className={'container-fluid wrapper'}>
					<CampaignEdition addAutomationNode={this.addAutomationNode}/>
					<AutomationEdition addSubAutomationNode={this.addAutomationNode}
									   setCampaignAutomationItemOnArea={this.setCampaignAutomationItemOnArea}
									   funnel_id={this.state.funnel.encrypted_id}/>
					<EditorFlow elements={this.state.elements}
								handleElements={this.handleElements}/>
					<AdditionSidebar addNode={this.addNode}
									 addBlueprint={this.addBlueprint}
									 addExternalNode={this.addExternalNode}
									 elements={this.state.elements}
									 addRestrictedNode={this.addRestrictedNode}
									 themes_id={this.state.funnel.themes_id}/>
					<PageEdition page={this.state.page}
								 handlePage={this.handlePage}
								 funnel={this.state.funnel}
								 createPopup={this.createPopup}
								 createTag={this.createTag}
								 createSubNode={this.createSubNode}/>
					<ElementEdition element={this.state.element}
									elements={this.state.elements}
									funnel_id={this.state.funnel.encrypted_id}
									setCampaignOnArea={this.setCampaignOnArea}
									handleElem={this.handleElem}/>
					<RestrictedAreaEdition element={this.state.element}
										   elements={this.state.elements}
										   setPageInRestrictedArea={this.setPageInRestrictedArea}
										   handleResArea={this.handleResArea}/>
					{this.state.elements.length === 0 && this.state.started ? this.buttonStart() : null}
					<MbossEmailEditor/>

				</div>
				{this.modalGenerateSite()}
			</div>
		)
	}

	buttonStart() {
		return <div className='center-button'>
			<button className='btn btn-info btn-center-button btn-start btn-upper cursor-pointer'
					onClick={this.showSideBarAddPage}>
				<FontAwesomeIcon icon={faPlus} color='#F8FAFD'/>
				<label className='cursor-pointer'>{i18n.t('cfb-start-funnel-btn-add-page')}</label>
			</button>
			<button className='btn btn-outline-primary redo-tour'
					onClick={() => {
						EventEmitter.dispatch('startTour');
					}}>
				{i18n.t('cfb-start-funnel-redo-tour')}
			</button>
		</div>;
	}

	goToSite = () => {
		this.setState({show: false});

		Funnels.getWebsite(this.state.funnel.encrypted_id).then((response) => {

			let url;
			switch (process.env.REACT_APP_ENV) {

				case 'local':
					url = 'http://localhost:8080/#/site/';
					break;
				case 'homologation':
					url = 'https://editor-dev.builderall.io/#/site/';
					break;
				case 'production':
					url = 'https://cheetah-editor.builderall.com/#/site/';
					break;
				default:
					url = 'http://localhost:8080/#/site/';
					break;
			}

			window.open(url + response.data.data.encrypted_id + '?tour=init-cfb');
		})
		// window.open('https://office.builderall.com/br/office/cheetah-builder');

	}

	modalGenerateSite() {
		const handleClose = () => {
			this.setState({show: false});
		}

		return <Modal show={this.state.show} onHide={handleClose} dialogClassName="modal-campaign">
			<Modal.Header>
				<h3 className="modal-title">{i18n.t('cfb-generate-site-success')}</h3>
				<button type="button" className="close close-popup" onClick={handleClose}>&times;</button>
			</Modal.Header>

			<Modal.Body>
				{i18n.t('cfb-gen-success-website-keep-going')}
			</Modal.Body>

			<Modal.Footer>
				<button type="button" className="btn btn-link" onClick={handleClose}>
					{i18n.t('cfb-close')}
				</button>
				<button type="button" className="btn btn-success btn-upper" onClick={this.goToSite}>
					{i18n.t('cfb-go-to-website')}
				</button>
			</Modal.Footer>
		</Modal>
	}
}

export default Editor;
