import React, {memo} from 'react';

import {Handle} from 'react-flow-renderer';
import {EventEmitter} from "../events";
import i18n from "../../i18n";

export default memo(({data}) => {

	function showPopupEdit(ev) {
		ev.preventDefault();

		if (data.campaign_uid) {
			EventEmitter.dispatch('editionSidebar', data);
		}
	}

	function getHandleLeft() {
		return <Handle type="target" position="left"/>;
	}

	const deleteNode = (idNode) => {
		EventEmitter.dispatch('deleteElement', idNode);
	}

	function getConfigDiv() {
		if (typeof data.campaign_uid !== 'undefined')
			return <div className='automation-config'>
				{i18n.t('cfb-node-identification')}: {data.label}
			</div>;
		else {
			return <div className='automation-config'>
				{i18n.t('cfb-node-email')}: {data.label}
			</div>;
		}
	}

	return (
		<div className='sub-element-element flow-sub-element' data-nodeid={data.idNode}
			 onContextMenu={showPopupEdit}>
			{typeof data.campaign_uid !== 'undefined' ? getHandleLeft() : null}
			<h6 className='title-elem'>
				{typeof data.campaign_uid !== 'undefined' ?
					i18n.t('cfb-node-email') :
					i18n.t('cfb-node-campaign-email')
				}
			</h6>
			{getConfigDiv()}
			<Handle type="source" position="right"/>

			<div className={'removeIcon'} onClick={() => deleteNode(data.idNode)}/>
		</div>
	);
});
