import Http from "../http";

const prefix = '/funnels-elements';

const funnelElements = new (function () {

	this.get = function (encryptedId) {
		return Http.authApi().get(`${prefix}/${encryptedId}`);
	};

	this.put = function (data) {
		return Http.authApi().put(`${prefix}`, data);
	}

})();

export default funnelElements;