const ShareData = {
	data: {},
	recover: function (key) {
		if (!this.data[key]) return
		return this.data[key];
	},
	subscribe: function (key, data) {
		if (!this.data[key]) this.data[key] = [];
		this.data[key] = data
	}
}

module.exports = {ShareData};