import i18next from 'i18next';
import Backend from 'i18next-http-backend';

i18next
	.use(Backend)
	.init({
		fallbackLng: 'en_US',
		interpolation: {
			// React already does escaping
			escapeValue: false,
		},
		lng: window.getCookie('languageCanvas') || 'en_US', // 'en' | 'es'
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json'
		}
	})

export default i18next