import React from "react";
import i18n from "../../../i18n";

class Webinar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			webinar: {
				url: props.webinarElement.url
			}
		}
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
		this.setState({webinar: nextProps.webinarElement});
	}

	handleChange = (prop, value) => {

		let obj = this.state.webinar;
		obj[prop] = value;
		this.setState({webinar: obj});

		this.props.handleChangeOptions(this.state.webinar);
	}

	changeUrl = (e) => {
		this.handleChange('url', e.target.value);
	}

	render() {
		return <div className='title-page-sidebar no-top'>
			<a href="https://office.builderall.com/br/office/new-webinar" target="_blank">
				<button className='btn btn-info btn-lists w-100'  data-ba-i={'webinar-by-funnel-builder'}>
					{i18n.t('cfb-webinar-url-info')}
				</button>
			</a>
			<label className='w-100'>
				{i18n.t('cfb-webinar-url-embed')}
				<input type='text'
					   className='form-control'
					   value={this.state.webinar.url}
					   onChange={this.changeUrl}
					   onBlur={this.changeUrl}/>
			</label>
		</div>;
	}
}

export default Webinar;
