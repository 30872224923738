import React from "react";
import i18n from './i18n';
import logo from './img/logo-ba.svg'
import {Accordion, ButtonGroup, Card, Dropdown, DropdownButton, Modal} from "react-bootstrap";
import Select from "react-select/creatable/dist/react-select.esm";
import translate from "./utils/translate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
	faChartPie,
	faChevronDown, faChevronUp, faLink,
	faProjectDiagram,
	faShare,
	faSitemap,
	faSyncAlt
} from '@fortawesome/free-solid-svg-icons';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import website from "./utils/editor/website";
import funnels from "./utils/dashboard/funnels";
import {Doughnut, Line} from "react-chartjs-2";
import {toast} from "react-toastify";
import {EventEmitter} from "./Editor/events";
import Analyser from "./Editor/analyser";
import PageTypes from "./utils/editor/pageTypes";
import {ShareData} from "./Editor/shareData";
import Funnels from "./utils/dashboard/funnels";

class Header extends React.Component {

	constructor() {
		super();

		let lang = window.getCookie('languageCanvas') || 'en_US';

		this.state = {
			show: false,
			showSync: false,
			showLink: false,
			showSyncPage: false,
			alreadySynced: false,
			device: {},
			system: {},
			browser: {},
			date: {},
			newPage: {},
			newPageType: {},
			pages: [],
			pagesTypes: [],
			openPageArea: false,
			openElementArea: false,
			languages: [],
			lang: lang,
			userTemplates: [],
			userTemplate: {},
			options: {
				bezierCurve: false,
				responsive: true,
				maintainAspectRatio: false,
				legend: {
					display: true,
					position: 'right',
				},
				title: {
					display: true,
					fontSize: 16,
					fontFamily: "'Lato', 'sans-serif'",
					position: 'left'
				},
				elements: {
					line: {
						fill: false
					}
				}
			}
		}
	}

	logout = () => {
		localStorage.removeItem('token');
		window.location.href = 'https://office.builderall.com/br/office';
	}

	handleChange = (data) => {
		funnels.postLanguageUser({lang: data.value}).then(() => {
			window.setCookie('languageCanvas', data.value);
			window.location.reload();
		})
	}

	handleData = () => {

		this.setState({show: true});

		function getDataToGraph(data, type) {

			let labels = [];
			let dataSet = [];
			for (let i in data) {
				if (i !== 'Unknown') {
					labels.push(i !== '' ? i : 'home');
					dataSet.push(data[i]);
				}
			}

			return {
				labels: labels,
				datasets: [{
					data: dataSet,
					borderColor: type === 'line' ? '#142459' : 'white',
					backgroundColor: type === 'line' ? '#142459' : [
						'#142459',
						'#176BA0',
						'#19AADE',
						'#1DE4BD',
						'#7D3AC1',
						'#AF4BCE',
						'#DB4CB2',
						'#EA7369',
						'#820401',
						'#C02323',
						'#EF7E32',
						'#EE9A3A',
						'#E7E34E'
					]
				}]
			};
		}

		let toastId = toast(i18n.t('cfb-loading-data'), {autoClose: false});

		funnels.getWebsite(this.props.funnel.encrypted_id).then((responseFunnel) => {
			website.getDataGraphs(responseFunnel.data.data.encrypted_id).then((response) => {

				toast.update(toastId, {
					render: i18n.t('cfb-loaded'),
					type: toast.TYPE.SUCCESS,
					autoClose: 5000,
					className: 'rotateY animated'
				});

				this.setState({device: getDataToGraph(response.data.device, 'pie')});
				this.setState({browser: getDataToGraph(response.data.browser, 'pie')});
				this.setState({system: getDataToGraph(response.data.system, 'pie')});
				this.setState({date: getDataToGraph(response.data.date, 'line')});
			})
		})
	}

	handleDataOnArea = () => {

		let toastId = toast(i18n.t('cfb-loading-data'), {autoClose: false});

		funnels.getWebsite(this.props.funnel.encrypted_id).then((responseFunnel) => {
			website.getDataAccess(responseFunnel.data.data.encrypted_id).then((response) => {

				toast.update(toastId, {
					render: i18n.t('cfb-loaded'),
					type: toast.TYPE.SUCCESS,
					autoClose: 5000,
					className: 'rotateY animated'
				});

				EventEmitter.dispatch('showDataOnArea', response.data.request_uri);
			})
		})
	}

	componentDidMount() {
		translate.getLanguages().then(response => {
			this.setState({languages: response.data});
		})

		funnels.getLanguageUser().then(response => {
			if (response.data.lang !== window.getCookie('languageCanvas')) {
				window.setCookie('languageCanvas', response.data.lang);
				window.location.reload();
			}
		})

		let userTemplates = ShareData.recover('userTemplates');
		if (userTemplates) {
			this.setState({userTemplates: userTemplates});
		} else {
			//all templates from user
			website.getAllUserWebsites().then(response => {
				this.setState({userTemplates: response.data})
				ShareData.subscribe('userTemplates', response.data);
			})
		}
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {

		// let params = (new URL(document.location)).searchParams;
		// let pullPages = params.get("pull-pages");
		//
		// if (
		// 	pullPages !== null &&
		// 	this.state.showSync === false &&
		// 	this.state.alreadySynced === false &&
		// 	typeof nextProps.funnel.encrypted_id !== 'undefined'
		// ) {
		// 	this.setState({alreadySynced: true});
		// 	this.showSyncModel();
		// }

	}

	handleClose = () => {
		this.setState({show: false});
		this.setState({showSync: false});
		this.setState({showLink: false});
	}

	handleClosePage = () => {
		this.setState({showSyncPage: false});
	}

	handleApplyTemplate = () => {

		let toastId = toast(i18n.t('cfb-saving'), {autoClose: false});
		let newFunnelData = JSON.parse(JSON.stringify(this.props.funnel));

		if (typeof this.state.userTemplate.value !== 'undefined') {
			newFunnelData['new_website_id'] = this.state.userTemplate.value;
		}

		Funnels.post(newFunnelData).then((response) => {

			this.handleClose();
			this.props.changeFunnel(response.data.funnel);

			toast.update(toastId, {
				render: i18n.t('cfb-saved'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});

		}, (error) => {
			console.log(error);
		});

	}

	handleApply = () => {

		let toastId = toast(i18n.t('cfb-adding-data'), {autoClose: false});

		let pageOptions;

		this.state.pagesTypes.forEach(page => {
			if (page.page_types_id === this.state.newPageType.value) {
				pageOptions = page.options;
			}
		})

		this.setState({showSyncPage: false})
		this.setState({showSync: false})

		if (pageOptions) {

			pageOptions.desc = this.state.newPage.name;
			let userPage = {
				value: this.state.newPage.encrypted_id,
				label: this.state.newPage.name
			};

			this.props.addNode(pageOptions, userPage);

			toast.update(toastId, {
				render: i18n.t('cfb-added'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		}

	}

	showSyncModel = () => {
		this.setState({showSync: true})

		let toastId = toast(i18n.t('cfb-loading-data'), {autoClose: false});

		if (typeof this.props.funnel.encrypted_id !== 'undefined')
			website.getDataPages(this.props.funnel.encrypted_id).then(data => {

				toast.update(toastId, {
					render: i18n.t('cfb-loaded'),
					type: toast.TYPE.SUCCESS,
					autoClose: 5000,
					className: 'rotateY animated'
				});


				this.setState({pages: data.data});
			})
	}

	checkIfExists(element, page, found, withSplit = false) {
		if (element.data.label == page.name || page.canvas_reference == element.id) {
			found = true;
		}

		if (withSplit) {
			try {
				if (typeof element.data.options.split !== 'undefined' && typeof element.data.options.split.pages !== 'undefined')
					element.data.options.split.pages.forEach(splitPage => {
						if (splitPage && typeof splitPage.title !== 'undefined' && splitPage.title == page.name || page.canvas_reference == splitPage.id)
							found = true;
					})
			} catch (e) {
				console.log(e);
			}
		}

		return found;
	}

	checkIfExistsPage(pageSplit, page, foundAb) {
		if (pageSplit.title == page.name || page.canvas_reference == pageSplit.id) {
			foundAb = true;
		}
		return foundAb;
	}

	setPages = () => {

		const options = [];

		if (typeof this.state.pages !== 'undefined')
			this.state.pages.forEach(page => {

				let found = false;

				this.props.elements.forEach(element => {
					if (typeof element.type !== 'undefined' && element.type === 'pageNode') {
						found = this.checkIfExists(element, page, found, true);
					}
				});

				if (!found) {
					options.push(
						<div className='position-relative' style={{marginBottom: '5px'}}>
							<div className='card-page'>
								{page.title}
							</div>
							<button type="button" className="btn btn-info btn-sync-page"
									onClick={() => this.sendPageToArea(page)}>
								<FontAwesomeIcon icon={faShare}/>
							</button>
						</div>
					);
				}

			})

		return options;
	}

	setElements = () => {
		const options = [];

		if (typeof this.props.elements !== 'undefined')
			this.props.elements.forEach(element => {
				if (typeof element.type !== 'undefined' && element.type === 'pageNode') {

					let found = false;

					this.state.pages.forEach(page => {
						found = this.checkIfExists(element, page, found);
					});

					if (!found) {
						options.push(
							<div className='position-relative' style={{marginBottom: '5px'}}>
								<div className='card-page'>
									{element.data.label}
								</div>
								<button type="button" className="btn btn-info btn-sync-page"
										onClick={() => this.sendElementToCheetah(element)}>
									<FontAwesomeIcon icon={faShare}/>
								</button>
							</div>
						);
					}

					try {
						if (
							typeof element != 'undefined' &&
							typeof element.data != 'undefined' &&
							typeof element.data.options != 'undefined' &&
							typeof element.data.options.split != 'undefined' &&
							element.data.options.split.active
						) {

							if (typeof element.data.options.split.pages !== 'undefined')
								element.data.options.split.pages.forEach(pageSplit => {
									if (pageSplit !== null) {

										let foundAb = false;

										this.state.pages.forEach(page => {
											foundAb = this.checkIfExistsPage(pageSplit, page, foundAb);
										});

										if (!foundAb)
											options.push(
												<div className='position-relative' style={{marginBottom: '5px'}}>
													<div className='card-page'>
														{pageSplit.title}
													</div>
													<button type="button" className="btn btn-info btn-sync-page"
															onClick={() => this.sendElementToCheetah(element, pageSplit)}>
														<FontAwesomeIcon icon={faShare}/>
													</button>
												</div>
											);
									}
								})
						}
					} catch (e) {
					}
				}
			})

		return options;
	}

	handleChangePageType = (data) => {
		this.setState({newPageType: data})
	}

	getSelectPages = () => {
		if (Object.keys(this.state.pagesTypes).length === 0 && typeof this.props.funnel !== 'undefined' && typeof this.props.funnel.themes_id !== 'undefined') {
			PageTypes.get(this.props.funnel.themes_id).then((response) => {
				this.setState({pagesTypes: response.data.data});
			})
		}

		let options = [];

		this.state.pagesTypes.forEach((pageType) => {
			options.push({value: pageType.page_types_id, label: pageType.options.desc})
		})

		return <Select options={options} menuPlacement="auto" onChange={this.handleChangePageType}/>;

	}

	sendPageToArea = (page) => {
		this.setState({newPage: page});
		this.setState({showSyncPage: true});
	}

	sendElementToCheetah = (element, page = null) => {

		let toastId = toast(i18n.t('cfb-adding-data'), {autoClose: false});

		this.setState({showSyncPage: false})
		this.setState({showSync: false})

		let elementToSave = JSON.parse(JSON.stringify(element));

		elementToSave['encrypted_id'] = this.props.funnel.encrypted_id;

		if (page !== null) {
			elementToSave.id = page.id;
			elementToSave.data.label = page.title;
			elementToSave.data.options.desc = page.title;
		}

		website.sendDataPages(elementToSave).then(() => {
			toast.update(toastId, {
				render: i18n.t('cfb-added'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		})
	}

	render() {

		return (
			<header>
				<div className='name-header navbar-brand'>
					<img src={logo} alt='logo' className='logo d-inline-block mr-3' style={{marginTop: '1px'}}/>
					<span className="d-inline-block title-obj">|</span>
					<a className="btn btn-default logo" href={'/'}>
						Funnel Builder
					</a>
					{/*<h1 className='d-inline-flex'>{process.env.REACT_APP_ENV != }</h1>*/}
					{this.getIconsGraph()}
					{this.getFunnelName()}
				</div>

				<nav className={'float-right d-flex ' + (this.props.type === 'editor' ? '' : 'hidden')}>
					{this.getIconsGraphSite()}
					<button className="active btn btn-success mr-4 btn-upper" href="#" onClick={this.props.saveFunnel}
							id={'saveEditor'} >{i18n.t('cfb-save')}</button>
					<button className="active btn btn-secondary mr-4 btn-upper" href="#"
							onClick={this.props.generateSite}
							id={'generateSite'}  data-ba-i={'generate-site-by-funnel-builder'}>{i18n.t('cfb-' + (typeof this.props.funnel != 'undefined' && this.props.funnel.website_id === null ? '' : 're') + 'generate')}</button>
				</nav>

				{this.modalGraphs()}
				{this.modalSync()}
				{this.modalLinkWebsite()}

			</header>
		);
	}

	getFunnelName() {
		if (typeof this.props.funnel != 'undefined' && this.props.funnel.title !== null)
			return <div className="btn btn-default logo">
				{i18n.t('cfb-funnel-name')} {this.props.funnel.title}
			</div>;
	}

	handleChangeTemplate = (data) => {
		this.setState({userTemplate: data});
	}

	getSelectTemplates() {

		let options = [];
		let optionSelected = {};

		if (typeof this.state.userTemplates !== 'undefined' && this.state.userTemplates.length !== 0)
			this.state.userTemplates.forEach((template) => {
				options.push({value: template.encrypted_id, label: template.title})

				if (typeof this.props.funnel !== 'undefined' && template.id == this.props.funnel.website_id) {
					optionSelected = {value: template.encrypted_id, label: template.title};
				}
			})

		return <Select options={options} menuPlacement="auto" onChange={this.handleChangeTemplate}
					   value={optionSelected}/>;
	}

	modalLinkWebsite() {
		return (
			<>
				<Modal show={this.state.showLink} onHide={this.handleClose} dialogClassName="modal-padding">
					<Modal.Header>
						<h3 className="modal-title">{i18n.t('cfb-link-title')}</h3>
						<button type="button" className="close close-popup" onClick={this.handleClose}>&times;</button>
					</Modal.Header>

					<Modal.Body>
						<label className='w-100 pb-4'>
							{i18n.t('cfb-funnel-link-website')}
							<div className='separator'/>
							{this.getSelectTemplates()}
							<small>{i18n.t('cfb-funnel-link-website-helper')}</small>
						</label>
					</Modal.Body>

					<Modal.Footer>
						<button type="button" className="btn btn-link" onClick={this.handleClose}>
							{i18n.t('cfb-close')}
						</button>
						<button type="button" className="btn btn-success btn-upper" onClick={this.handleApplyTemplate}>
							{i18n.t('cfb-save-changes')}
						</button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	modalSync() {

		const rc = this;

		function openPage() {
			rc.setState({openPageArea: !rc.state.openPageArea});
			rc.setState({openElementArea: false});
		}

		function openElement() {
			rc.setState({openPageArea: false});
			rc.setState({openElementArea: !rc.state.openElementArea});
		}

		const iconPageClass = this.state.openPageArea
			? faChevronUp
			: faChevronDown;

		const iconElementClass = this.state.openElementArea
			? faChevronUp
			: faChevronDown;

		return (
			<>
				<Modal show={this.state.showSync} onHide={this.handleClose} dialogClassName="modal-padding">
					<Modal.Header>
						<h3 className="modal-title">{i18n.t('cfb-sync-title')}</h3>
						<button type="button" className="close close-popup" onClick={this.handleClose}>&times;</button>
					</Modal.Header>

					<Modal.Body>
						<small style={{paddingBottom: '50px'}}>{i18n.t('cfb-sync-site-helper')}</small>

						<Accordion className='marginTopSpacing'>
							<Card className="card-push">
								<Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => openPage()}>
									{i18n.t('cfb-sync-site-page')}
									<FontAwesomeIcon icon={iconPageClass}/>
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="0">
									<Card.Body className={'row'}>
										{this.setPages()}
									</Card.Body>
								</Accordion.Collapse>
							</Card>
							<Card className="card-push">
								<Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => openElement()}>
									{i18n.t('cfb-sync-site-element')}
									<FontAwesomeIcon icon={iconElementClass}/>
								</Accordion.Toggle>
								<Accordion.Collapse eventKey="1">
									<Card.Body className={'row'}>
										{this.setElements()}
									</Card.Body>
								</Accordion.Collapse>
							</Card>
						</Accordion>

					</Modal.Body>

					<Modal.Footer>
						<button type="button" className="btn btn-link" onClick={this.handleClose}>
							{i18n.t('cfb-close')}
						</button>
					</Modal.Footer>
				</Modal>

				<Modal show={this.state.showSyncPage} onHide={this.handleClosePage} dialogClassName="modal-padding">
					<Modal.Header>
						<h3 className="modal-title">{i18n.t('cfb-sync-select-page')}</h3>
						<button type="button" className="close close-popup"
								onClick={this.handleClosePage}>&times;</button>
					</Modal.Header>

					<Modal.Body>
						<label className='w-100 pb-4'>
							{i18n.t('cfb-select-page-type')}
							{this.getSelectPages()}
						</label>
					</Modal.Body>

					<Modal.Footer>
						<button type="button" className="btn btn-link" onClick={this.handleClosePage}>
							{i18n.t('cfb-close')}
						</button>
						<button type="button" className="btn btn-success btn-upper" onClick={this.handleApply}>
							{i18n.t('cfb-save-changes')}
						</button>
					</Modal.Footer>
				</Modal>
			</>
		);
	}

	modalGraphs() {

		const optionsDevice = {
			...this.state.options,
			title: {...this.state.options.title, text: i18n.t('cfb-graphs-title-device')}
		};
		const optionsBrowser = {
			...this.state.options,
			title: {...this.state.options.title, text: i18n.t('cfb-graphs-title-browser')}
		};
		const optionsSystem = {
			...this.state.options,
			title: {...this.state.options.title, text: i18n.t('cfb-graphs-title-system')}
		};
		const optionsLine = {
			...this.state.options,
			title: {
				...this.state.options.title,
				text: i18n.t('cfb-graphs-title-date')
			},
			legend: {display: false}
		};

		return (
			<Modal show={this.state.show} onHide={this.handleClose} dialogClassName="modal-90w">
				<Modal.Header>
					<h3 className="modal-title">{i18n.t('cfb-graphs-title')}</h3>
					<button type="button" className="close close-popup" onClick={this.handleClose}>&times;</button>
				</Modal.Header>

				<Modal.Body>
					<div className='d-inline-flex container' style={{minHeight: '300px'}}>
						<div style={{width: '330px'}}>
							<Doughnut data={this.state.device} width={300} height={300}
									  options={optionsDevice}/>
						</div>
						<div style={{width: '330px'}}>
							<Doughnut data={this.state.browser} width={300} height={300}
									  options={optionsBrowser}/>
						</div>
						<div style={{width: '330px'}}>
							<Doughnut data={this.state.system} width={300} height={300}
									  options={optionsSystem}/>
						</div>
					</div>
					<div className='d-inline-flex container' style={{minHeight: '300px'}}>
						<Line data={this.state.date} height={300}
							  options={optionsLine}/>
					</div>
				</Modal.Body>

				<Modal.Footer>
					<button type="button" className="btn btn-link" onClick={this.handleClose}>
						{i18n.t('cfb-close')}
					</button>
				</Modal.Footer>
			</Modal>
		);
	}

	getIconsGraph() {

		if (
			typeof this.props.funnel != 'undefined' &&
			typeof this.props.funnel.website_id != 'undefined' &&
			this.props.funnel.website_id !== null
		) {
			return <div className='place-icons'>
				<OverlayTrigger placement="bottom" overlay={
					<Tooltip id={`tooltip-bottom`}>{i18n.t('cfb-handle-data-popup')}</Tooltip>}>
					<FontAwesomeIcon icon={faChartPie} data-ba-i={'data-access-funnel-builder'} onClick={this.handleData} color='#808B96'/>
				</OverlayTrigger>

				<OverlayTrigger placement="bottom" overlay={
					<Tooltip id={`tooltip-bottom`}>{i18n.t('cfb-handle-data-area')}</Tooltip>}>
					<FontAwesomeIcon icon={faProjectDiagram} data-ba-i={'data-access-per-page-funnel-builder'} onClick={this.handleDataOnArea} color='#808B96'/>
				</OverlayTrigger>

				<Analyser elements={this.props.elements} funnel={this.props.funnel}/>

			</div>;
		} else
			return null;
	}

	clickWebsite = () => {
		this.setState({showLink: true});
	}

	getIconsGraphSite() {
		return <div className='place-icons' style={{marginRight: '25px'}}>
			{this.getIconsWithWebsite()}
			<OverlayTrigger placement="bottom" overlay={
				<Tooltip id={`tooltip-bottom`}>{i18n.t('cfb-link-with-website')}</Tooltip>}>
				<FontAwesomeIcon icon={faLink} onClick={this.clickWebsite} color='#808B96'/>
			</OverlayTrigger>
		</div>;
	}

	getIconsWithWebsite() {
		if (
			typeof this.props.funnel != 'undefined' &&
			typeof this.props.funnel.website_id != 'undefined' &&
			this.props.funnel.website_id !== null
		) {
			return <>
				<OverlayTrigger placement="bottom" overlay={
					<Tooltip id={`tooltip-bottom`}>{i18n.t('cfb-go-to-site')}</Tooltip>}>
					<FontAwesomeIcon icon={faSitemap} data-ba-i={'access-site-by-funnel-builder'} onClick={this.props.gotoWebsite} color='#808B96'/>
				</OverlayTrigger>

				<OverlayTrigger placement="bottom" overlay={
					<Tooltip id={`tooltip-bottom`}>{i18n.t('cfb-sync-site')}</Tooltip>}>
					<FontAwesomeIcon icon={faSyncAlt} data-ba-i={'sync-site-by-funnel-builder'} id={'show-sync-modal'} onClick={this.showSyncModel}
									 color='#808B96'/>
				</OverlayTrigger>
			</>;
		} else
			return null;
	}

	getLanguages() {

		if (this.state.languages.length > 0) {
			const options = [];
			let value = {};
			this.state.languages.forEach(lang => {
				let src = '/static/flags/' + lang.descricao + '.svg';
				let objLang = {
					value: lang.descricao,
					label: <div>
						<img src={src} height="30px" width="30px" style={{marginRight: '5px'}}/>
						{lang.label_desc}
					</div>
				};

				options.push(objLang)

				if (lang.descricao === this.state.lang) {
					value = objLang
				}
			})

			return (
				<Select classNamePrefix='select-countries' options={options} onChange={this.handleChange}
						menuPlacement="auto"
						value={value}/>
			);
		} else
			return null;
	}
}

export default Header;
