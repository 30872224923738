import Http from "../http";

const prefix = '/themes';

const themes = new (function() {

	this.get = function() {
		return Http.authApi().get(`${prefix}`);
	};

})();

export default themes;