import Http from "../utils/http";

const prefix = '/translate';

const translate = new (function () {

	this.getLanguages = function () {
		return Http.api().get(`${prefix}/all/languages`);
	};


})();

export default translate;