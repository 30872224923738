import React, { Component } from 'react';

import Themes from '../utils/dashboard/themes'
import Funnels from '../utils/dashboard/funnels'

import Header from "../Header";

import { Link } from "react-router-dom";
import i18n from "../i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faPlus,
	faClone,
	faPen,
	faShareAlt,
	faTrashAlt,
	faSitemap,
	faUpload,
	faSearch
} from '@fortawesome/free-solid-svg-icons';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Dropdown from 'react-bootstrap/Dropdown';

import imgFunnel from '../img/firstFunnel-img.svg';
import bgCard from '../img/bg_funnil.svg';
import './dashboard.css'
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";
import Select from "react-select/creatable/dist/react-select.esm";
import { ShareData } from "../Editor/shareData";
import website from "../utils/editor/website";

let toastIdDelete = null;

class Dashboard extends Component {

	constructor() {
		super();

		this.state = {
			filterText: '',
			started: false,
			newEmail: '',
			idSelected: null,
			showShare: false,
			themeFunnel: { value: 0, label: null },
			themes: [],
			funnels: [],
			newFunnel: {
				title: '',
				themes_id: 0
			},
			websiteFunnel: false,
			userTemplate: []
		}
	}

	componentDidMount() {

		const rc = this;

		Themes.get().then((response) => {
			this.setState({ themes: response.data.data });

			if (localStorage.getItem('createFunnel')) {

				if (localStorage.getItem('theme') !== 'undefined') {
					let nameOfTheme = '';
					response.data.data.forEach(theme => {
						if (theme.id == localStorage.getItem('theme')) {
							nameOfTheme = theme.desc;
						}
					})
					this.setState({
						newFunnel: {
							...this.state.newFunnel,
							...{
								title: i18n.t('cfb-name-funnel-generic') + nameOfTheme,
								themes_id: localStorage.getItem('theme')
							}
						}
					})
					localStorage.removeItem('theme');
					setTimeout(() => {
						rc.postFunnel();
					}, 1000);
				}
			}

		}, (error) => {
			console.log(error);
		});

		Funnels.get().then((response) => {
			this.setState({ funnels: response.data.data });
			this.setState({ started: true });
		}, (error) => {
			console.log(error);
		});

		let userTemplates = ShareData.recover('userTemplates');
		if (userTemplates) {
			this.setState({ userTemplates: userTemplates });
		} else {
			//all templates from user
			website.getAllUserWebsites().then(response => {
				this.setState({ userTemplates: response.data })
				ShareData.subscribe('userTemplates', response.data);
			})
		}
	}

	hidePopup = () => {
		document.getElementById('createFunnelPopup').style.display = 'none';
		document.getElementById('editFunnelPopup').style.display = 'none';

		this.setState({ showShare: false })
	}

	showPopup = (websiteFunnel = false) => {

		this.setState({ newFunnel: { title: '', themes_id: 0 } })
		this.setState({ websiteFunnel: websiteFunnel })
		this.setState({ userTemplate: {} });

		document.getElementById('createFunnelPopup').style.display = 'block';
	}

	showSharePopup = (id) => {
		this.setState({
			newEmail: '',
			shareId: id,
			showShare: true
		})
	}

	postFunnel = () => {

		if (this.state.newFunnel.title === '' || this.state.newFunnel.themes_id === 0) {
			toast(i18n.t('cfb-need-valid-data'), { autoClose: false, type: toast.TYPE.ERROR });
		} else {
			if (this.state.newFunnel.title.length > 90) {
				toast(i18n.t('cfb-name-long-error'), { autoClose: false, type: toast.TYPE.ERROR });
			} else {
				this.hidePopup();

				let toastId = toast(i18n.t('cfb-saving'), { autoClose: false });
				let newFunnelData = JSON.parse(JSON.stringify(this.state.newFunnel));

				if (this.state.websiteFunnel) {
					newFunnelData['new_website_id'] = this.state.userTemplate.value;
				}

				Funnels.post(newFunnelData).then((response) => {
					this.setState({ funnels: response.data.data });

					if (!this.state.newFunnel.id) {
						window.location.href = '/editor/' + response.data.funnel.encrypted_id;
					}
					// if (!this.state.newFunnel.id) {
					// 	window.location.href = '/editor/' + response.data.funnel.encrypted_id + (this.state.websiteFunnel ? '?pull-pages=true' : '');
					// }

					toast.update(toastId, {
						render: i18n.t('cfb-saved'),
						type: toast.TYPE.SUCCESS,
						autoClose: 5000,
						className: 'rotateY animated'
					});

				}, (error) => {
					console.log(error);
				});
			}
		}
	}

	handleChangeTitle = (e) => {
		this.setState({ newFunnel: { ...this.state.newFunnel, ...{ title: e.target.value } } })
	}

	handleNewEmail = (e) => {
		this.setState({ newEmail: e.target.value })
	}

	handleFilter = (e) => {
		this.setState({ filterText: e.target.value })
	}

	handleChangeTheme = (data) => {
		this.setState({ themeFunnel: data })
		this.setState({ newFunnel: { ...this.state.newFunnel, ...{ themes_id: data.value } } })
	}

	goToWebsite = (id) => {
		this.state.funnels.forEach(fn => {
			if (fn.id === id) {
				Funnels.getWebsite(fn.encrypted_id).then((response) => {

					let url;
					switch (process.env.REACT_APP_ENV) {

						case 'local':
							url = 'http://localhost:8080/#/site/';
							break;
						case 'homologation':
							url = 'https://editor-dev.builderall.io/#/site/';
							break;
						case 'production':
							url = 'https://cheetah-editor.builderall.com/#/site/';
							break;
						default:
							url = 'http://localhost:8080/#/site/';
							break;
					}

					window.open(url + response.data.data.encrypted_id);
				})
			}
		});
	}

	shareImg = (id) => {
		this.setState({ idSelected: id });
		document.getElementById('single').click();
	}

	onSelectedImage = (e) => {
		const files = Array.from(e.target.files)
		this.setState({ uploading: true })

		const formData = new FormData()

		files.forEach((file, i) => {
			formData.append('file', file)
		})

		let toastId = toast(i18n.t('cfb-uploading'), { autoClose: false });

		this.state.funnels.forEach(fn => {
			if (fn.id === this.state.idSelected) {
				Funnels.shareImage(formData, fn.encrypted_id).then((response) => {

					this.setState({ funnels: response.data.data });

					toast.update(toastId, {
						render: i18n.t('cfb-uploaded'),
						type: toast.TYPE.SUCCESS,
						autoClose: 5000,
						className: 'rotateY animated'
					});

				})
			}
		});
	}

	duplicateFunnel = (id) => {
		let toastId = toast(i18n.t('cfb-duplicating'), { autoClose: false });

		this.state.funnels.forEach(fn => {
			if (fn.id === id) {
				Funnels.duplicate(fn.encrypted_id).then((response) => {
					this.setState({ funnels: response.data.data });

					toast.update(toastId, {
						render: i18n.t('cfb-duplicated'),
						type: toast.TYPE.SUCCESS,
						autoClose: 5000,
						className: 'rotateY animated'
					});

				}, (error) => {
					console.log(error);
				});
			}
		})
	}

	editFunnel = (id) => {
		document.getElementById('editFunnelPopup').style.display = 'block';

		this.state.funnels.forEach(fn => {
			if (fn.id === id) {
				this.setState({ newFunnel: fn })
			}
		})
	}

	shareFunnel = () => {

		this.hidePopup();

		let toastId = toast(i18n.t('cfb-sharing'), { autoClose: false });

		this.state.funnels.forEach(fn => {
			if (fn.id === this.state.shareId) {
				Funnels.share({
					newEmail: this.state.newEmail,
					id: fn.encrypted_id
				}).then(() => {

					toast.update(toastId, {
						render: i18n.t('cfb-shared'),
						type: toast.TYPE.SUCCESS,
						autoClose: 5000,
						className: 'rotateY animated'
					});

				}, (error) => {
					console.log(error);
				});
			}
		})
	}

	deleteFunnel = (id) => {
		toastIdDelete = toast(this.getContentDelete(id), { autoClose: false });
	}

	cancelDelete = () => {
		toast.update(toastIdDelete, {
			render: i18n.t('cfb-canceled'),
			type: toast.TYPE.DEFAULT,
			autoClose: 2000,
			className: 'rotateY animated'
		});

		toastIdDelete = null;
	}

	confirmDelete = (id) => {

		this.state.funnels.forEach(fn => {
			if (fn.id === id) {
				Funnels.delete(fn.encrypted_id).then((response) => {
					this.setState({ funnels: response.data.data });

					toast.update(toastIdDelete, {
						render: i18n.t('cfb-deleted'),
						type: toast.TYPE.ERROR,
						autoClose: 5000,
						className: 'rotateY animated'
					});

				}, (error) => {
					console.log(error);
				});
			}
		})

	}

	getContentDelete = (id) => {
		return <>
			{i18n.t('cfb-deleting-confirm')}
			<div className='w-100 text-right mt-3'>
				<button className='btn btn-light' onClick={() => this.cancelDelete()}>
					{i18n.t('cfb-cancel')}
				</button>
				<button className='btn btn-danger ml-1' onClick={() => this.confirmDelete(id)}>
					{i18n.t('cfb-confirm')}
				</button>
			</div>
		</>;
	}

	render() {
		return (
			<div>
				<Header type={'dashboard'} />
				{this.buildTutorial()}
				{this.modalCreateFunnel('create')}
				{this.modalCreateFunnel('edit')}
				{this.modalShareFunnel()}
				<div className='container-fluid'>
					{this.state.started ? this.containerDashboard() : null}
				</div>
				<input style={{ 'display': 'none' }} type='file' id='single' onChange={this.onSelectedImage} />
			</div>
		);
	}

	containerDashboard() {
		return <div className='container'>

			{this.placeFilters()}
			{this.placeFunnels()}

			{/* {typeof this.state.funnels != 'undefined' && this.state.funnels.length > 0 ?
				this.placeFilters() :
				<div style={{ padding: '25px' }} />
			}

			{typeof this.state.funnels != 'undefined' && this.state.funnels.length > 0 ?
				this.placeFunnels() :
				this.placeFirstFunnel()} */}
		</div>;
	}

	placeFilters() {
		return <div className='filters-area d-flex justify-content-end w-100 col-sm-12'>
			<button data-ba-i={'add-funnel'}
				className='btn btn-blue cursor-pointer d-flex align-items-center justify-content-center mr-2'
				onClick={() => this.showPopup(false)}>
				<FontAwesomeIcon icon={faPlus} color='#FFF' />
				<span className='text-uppercase'>{i18n.t('cfb-add-funnel')}</span>
			</button>

			<button data-ba-i={'add-funnel-based-on-website'}
				className='btn btn-blue cursor-pointer d-flex align-items-center justify-content-center mr-2'
				onClick={() => this.showPopup(true)}>
				<FontAwesomeIcon icon={faPlus} color='#FFF' />
				<span className='text-uppercase'>{i18n.t('cfb-add-funnel-based')}</span>
			</button>

			<div className='w-25 position-relative float-right'>
				<input placeholder={i18n.t('cfb-search')} className='form-control mt-0' onChange={this.handleFilter} />
				<FontAwesomeIcon icon={faSearch} color='#434343' />
			</div>
		</div>;
	}

	placeFunnels() {
		return <div className='row place-funnels-area'>
			{this.state.funnels.map(funnel => this.getEditPanel(funnel))}
		</div>;
	}

	getEditPanel(funnel) {
		function goToWebsiteOverlay() {
			return <OverlayTrigger placement={'right'}
				overlay={<Tooltip id={`tooltip-right`}>{i18n.t('cfb-go-to-site')}</Tooltip>}>
				<FontAwesomeIcon icon={faSitemap} onClick={() => this.goToWebsite(funnel.id)} />
			</OverlayTrigger>;
		}

		const styleImg = funnel.thumbnail != null ?
			'https://storage.builderall.com/' + funnel.thumbnail :
			bgCard

		if (funnel.title.toLowerCase().indexOf(this.state.filterText.toLowerCase()) === -1) {
			return null;
		} else {
			return <div className={'card card-funnel'} key={funnel.id}>
				<picture>
					<img className='place-img-card' src={styleImg} alt="bg" />
				</picture>
				{/* <div className='place-buttons-card'>
					{funnel.website_id !== null ? goToWebsiteOverlay.call(this) : null}
					<OverlayTrigger overlay={<Tooltip id={`tooltip-right`}>{i18n.t('cfb-upload-share-img')}</Tooltip>}>
						<FontAwesomeIcon icon={faUpload} onClick={() => this.shareImg(funnel.id)} />
					</OverlayTrigger>
					<div className='sidebar-icons'>
						<OverlayTrigger overlay={<Tooltip id={`tooltip-right`}>{i18n.t('cfb-clone')}</Tooltip>}>
							<FontAwesomeIcon icon={faClone} onClick={() => this.duplicateFunnel(funnel.id)} />
						</OverlayTrigger>
						<OverlayTrigger overlay={<Tooltip id={`tooltip-right`}>{i18n.t('cfb-edit')}</Tooltip>}>
							<FontAwesomeIcon icon={faPen} onClick={() => this.editFunnel(funnel.id)} />
						</OverlayTrigger>
						<OverlayTrigger overlay={<Tooltip id={`tooltip-right`}>{i18n.t('cfb-share')}</Tooltip>}>
							<FontAwesomeIcon icon={faShareAlt} onClick={() => this.showSharePopup(funnel.id)} />
						</OverlayTrigger>
						<OverlayTrigger overlay={<Tooltip id={`tooltip-right`}>{i18n.t('cfb-delete')}</Tooltip>}>
							<FontAwesomeIcon icon={faTrashAlt} onClick={() => this.deleteFunnel(funnel.id)} />
						</OverlayTrigger>
					</div>
				</div> */}
				<h5 className='text-uppercase text-ellipsis mt-3 ml-3' title={funnel.title}>{funnel.title}</h5>
				<div className='d-flex'>
					<OverlayTrigger overlay={<Tooltip id={`tooltip-right`}>{i18n.t('cfb-edit')}</Tooltip>}>
						<Link to={'/editor/' + funnel.encrypted_id} className='mr-2 ba-btn ba-btn-normal'>
							<builderall-icon code='edit' />
						</Link>
					</OverlayTrigger>

					<Dropdown>
						<OverlayTrigger overlay={<Tooltip id={`tooltip-right`}>{i18n.t('cfb-settings')}</Tooltip>}>
							<Dropdown.Toggle href='#' variant='' bsPrefix='text-center p-0 ba-btn ba-btn-normal'>
								<builderall-icon code='fbba'></builderall-icon>
							</Dropdown.Toggle>
						</OverlayTrigger>

						<Dropdown.Menu className='py-3'>
							<Dropdown.Item className='pl-2 pr-5' onClick={() => this.duplicateFunnel(funnel.id)} >
								<FontAwesomeIcon icon={faClone} className='mr-3' />
								{i18n.t('cfb-clone')}
							</Dropdown.Item>
							<Dropdown.Item className='pl-2 pr-5' onClick={() => this.shareImg(funnel.id)} >
								<FontAwesomeIcon icon={faUpload} className='mr-3' />
								{i18n.t('cfb-upload-share-img')}
							</Dropdown.Item>
							<Dropdown.Item className='pl-2 pr-5' onClick={() => this.showSharePopup(funnel.id)} >
								<FontAwesomeIcon icon={faShareAlt} className='mr-3' />
								{i18n.t('cfb-share')}
							</Dropdown.Item>
							<Dropdown.Divider />
							<Dropdown.Item className='pl-2 pr-5' onClick={() => this.deleteFunnel(funnel.id)} >
								<FontAwesomeIcon icon={faTrashAlt} className='mr-3 text-danger' />
								{i18n.t('cfb-delete')}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				</div>
			</div>;
		}
	}

	placeFirstFunnel() {
		return <div className='first-funnel-panel'>
			<h1>{i18n.t('cfb-my-funnels')}</h1>
			<h4>{i18n.t('cfb-my-funnels-description')}</h4>
			<div className='text-center place-img-first-funnel'>
				<img src={imgFunnel} alt='first-funnel-img' className='img-first-funnel mt-5 mb-5' />
				<h2>{i18n.t('cfb-create-funnel-description')}</h2>
				<button className='btn btn-info button-first-funnel mt-2' data-ba-i={'add-funnel'}
					onClick={() => this.showPopup(false)}>
					{i18n.t('cfb-create-funnel-button')}
				</button>
			</div>
		</div>
	}

	modalCreateFunnel(type) {
		return <div className="modal" id={type + "FunnelPopup"}>
			<div className="modal-dialog">
				<div className="modal-content p-5">
					<div className='pb-4 title-modal'>
						<h3 className="modal-title">{i18n.t('cfb-' + type + '-modal-title')}</h3>
						<button type="button" className="close close-popup" onClick={this.hidePopup}>&times;</button>
					</div>

					<label className='w-100 pb-4'>
						{i18n.t('cfb-' + type + '-funnel-title')}
						<input type='input' className='form-control' value={this.state.newFunnel.title}
							onBlur={this.handleChangeTitle}
							onChange={this.handleChangeTitle} />
						<small>{i18n.t('cfb-' + type + '-funnel-title-helper')}</small>
					</label>
					<label className='w-100 pb-4'>
						{i18n.t('cfb-' + type + '-funnel-theme')}
						<div className='separator' />
						{this.getSelectThemes()}
						<small>{i18n.t('cfb-' + type + '-funnel-theme-helper')}</small>
					</label>

					{type === 'create' ? this.getSelectWebsites() : null}

					<div className="modal-footer" style={{ border: 'none' }}>
						<button type="button" className="btn btn-link" onClick={this.hidePopup}>
							{i18n.t('cfb-' + type + '-funnel-close')}
						</button>
						<button type="button" className="btn btn-success btn-upper" onClick={this.postFunnel}>
							{i18n.t('cfb-' + type + '-funnel-create')}
						</button>
					</div>
				</div>
			</div>
		</div>;
	}

	getSelectWebsites() {

		if (!this.state.websiteFunnel) {
			return null;
		}

		return <label className='w-100 pb-4'>
			{i18n.t('cfb-funnel-select-website')}
			<div className='separator' />
			{this.getSelectTemplates()}
			<small>{i18n.t('cfb-funnel-select-website-helper')}</small>
		</label>;
	}

	handleChangeTemplate = (data) => {
		this.setState({ userTemplate: data });
	}

	getSelectTemplates() {
		let options = [];

		if (typeof this.state.userTemplates !== 'undefined' && this.state.userTemplates.length !== 0)
			this.state.userTemplates.forEach((template) => {
				options.push({ value: template.encrypted_id, label: template.title })
			})

		return <Select options={options} menuPlacement="auto" onChange={this.handleChangeTemplate} />;
	}

	getSelectThemes() {

		let options = [];
		let objSelected = {};

		this.state.themes.forEach((theme) => {
			if (theme.id === this.state.newFunnel.themes_id) {
				objSelected = { value: theme.id, label: theme.desc };
			}
			options.push({ value: theme.id, label: theme.desc })
		})

		return <Select options={options} menuPlacement="auto" onChange={this.handleChangeTheme} value={objSelected} />;
	}

	modalShareFunnel() {
		return (
			<div>
				<Modal show={this.state.showShare} onHide={this.hidePopup} size="sm"
					dialogClassName="modal-share">
					<Modal.Header>
						<h3 className="modal-title">{i18n.t('cfb-share-funnel-title')}</h3>
						<button type="button" className="close close-popup" onClick={this.hidePopup}>&times;</button>
					</Modal.Header>

					<Modal.Body>
						<label className='w-100 spacing-50'>
							{i18n.t('cfb-email-receiver')}
							<input type='input' className='form-control' value={this.state.newEmail}
								onBlur={this.handleNewEmail}
								onChange={this.handleNewEmail} />
							<small>{i18n.t('cfb-email-receiver-helper')}</small>
						</label>
					</Modal.Body>

					<Modal.Footer>
						<button type="button" className="btn btn-link" onClick={this.hidePopup}>
							{i18n.t('cfb-close')}
						</button>
						<button type="button" className="btn btn-success btn-upper" onClick={this.shareFunnel}>
							{i18n.t('cfb-save-changes')}
						</button>
					</Modal.Footer>
				</Modal>
			</div>
		)
	}

	buildTutorial() {

		setTimeout(() => {
			if (!document.getElementById('office-script-id')) {
				let s = document.createElement('script')
				s.type = 'text/javascript'
				s.id = 'office-script-id'
				s.src = 'https://office.builderall.com/internacional/public/mix/web-components/js/wc-tutorial-video.js?v=7'
				try {
					var headID = document.getElementsByTagName('head')[0]
					headID.appendChild(s)
				} catch (e) {
				}
			}
		}, 3000)

		function getUrlVideo() {
			let lang = window.getCookie('languageCanvas') || 'en_US';
			let url;

			switch (lang) {
				// case 'pt_BR':
				//   url = '';
				//   break;
				case 'es_ES':
					url = 'https://videomng.builderall.com/embed/6aaVfkfChr/?controls=1&allowpause=1';
					break;
				default:
					url = 'https://videomng.builderall.com/embed/Ajiv5DUbCX/?controls=1&allowpause=1';
			}
			return url;

		}

		function getUrlMore() {
			let lang = window.getCookie('languageCanvas') || 'en_US';
			let url;

			switch (lang) {
				// case 'pt_BR':
				//   url = '';
				//   break;
				case 'es_ES':
					url = 'https://kbspanish.builderallwp.com/?docs=getting-started-with-the-cheetah-funnel-builder';
					break;
				default:
					url = 'https://knowledgebase.builderall.com/?doc_category=cheetahfunnelbuilder';
			}
			return url;

		}

		return (
			<div>
			</div>
		)
	}
}

export default Dashboard;
