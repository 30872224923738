import React from "react";
import i18n from "../../../i18n";
import {faPen, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Modal} from "react-bootstrap";
import website from "../../../utils/editor/website";
import {Polar} from "react-chartjs-2";
import {EventEmitter} from "../../events";

class SplitTest extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			show: false,
			page: this.props.page,
			dataSplit: []
		}
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
		this.setState({page: nextProps.page})
	}

	applyTest = (value) => {

		let page = this.state.page;
		let options = this.state.page.data.options;

		if (typeof options.split === 'undefined') {
			options['split'] = {}
		}
		if (typeof options.split.pages === 'undefined') {
			options['split']['pages'] = []

			this.addPage();
		}

		options.split.active = value;

		page.data.options = options;

		this.setState({page: page});

		this.props.applyPage(page);

	}

	getNextID = () => {
		let newID = new Date().valueOf() + Math.floor((Math.random() * 100) + 1);
		this.setState({newID: newID});
		return newID;
	}

	showTest = () => {
		this.setState({show: true});

		EventEmitter.dispatch('closeSidebars');

		website.getDataTests(this.state.page.id, this.props.funnel.encrypted_id).then(response => {
			this.setState({dataSplit: response.data});
		})
	}

	handleClose = () => {
		this.setState({show: false});
	}

	addPage = () => {

		let page = this.state.page;
		let options = this.state.page.data.options;

		if (typeof options.split.pages === 'undefined') {
			options['split']['pages'] = []
		}

		let obj = {
			title: this.state.page.data.label + '-' + (options['split']['pages'].length + 1),
			id: this.getNextID()
		}

		options.split.pages.push(obj);

		page.data.options = options;

		this.setState({page: page});

		this.props.applyPage(page);

	}

	// editPage = (id) => {
	// 	console.log('edit', id);
	// }

	deletePage = (id) => {

		let page = this.state.page;
		let options = this.state.page.data.options;


		options.split.pages.forEach((page, i) => {
			if (page !== null && page.id === id) {
				delete options.split.pages[i];
			}
		})

		page.data.options = options;

		this.setState({page: page});

		this.props.applyPage(page);

	}

	render() {
		return (
			<div className='place-split-test'>
				<div className='helper text-left '>{i18n.t('cfb-split-test-helper')}</div>
				{this.placePages()}
				{this.getButtonSplitTest()}
				{this.modalData()}
			</div>
		);
	}

	getButtonSplitTest() {

		if (
			typeof this.state.page != 'undefined' &&
			typeof this.state.page.data != 'undefined' &&
			typeof this.state.page.data.options != 'undefined' &&
			typeof this.state.page.data.options.split != 'undefined' &&
			this.state.page.data.options.split.active
		) {
			return (
				<div className='place-btn-remove-all'>
					<button className='btn btn-danger w-100' onClick={() => this.applyTest(false)}>
						{i18n.t('cfb-split-test-remove-test')}
					</button>
				</div>);
		} else {
			return (
				<button style={{marginTop: '25px'}} className='btn btn-info w-100' onClick={() => this.applyTest(true)}  data-ba-i={'split-test-by-funnel-builder'}>
					{i18n.t('cfb-split-test-create-test')}
				</button>);
		}
	}

	placePages() {

		try {
			if (
				typeof this.state.page != 'undefined' &&
				typeof this.state.page.data != 'undefined' &&
				typeof this.state.page.data.options != 'undefined' &&
				typeof this.state.page.data.options.split != 'undefined' &&
				this.state.page.data.options.split.active
			) {

				const pagesHtml = [];
				if (typeof this.state.page.data.options.split.pages !== 'undefined')
					this.state.page.data.options.split.pages.forEach(page => {
						if (page !== null)
							pagesHtml.push(this.getPage(page.title, page.id));
					})

				return (
					<div style={{paddingTop: '25px'}}>
						<button className='btn btn-info w-100'
								onClick={this.addPage}>{i18n.t('cfb-split-test-add-page')}</button>
						<div className='text-left font-weight-bold'
							 style={{paddingTop: '20px'}}>{i18n.t('cfb-split-test-pages')}</div>
						<div className='text-left'>
							{pagesHtml}
						</div>
						<button className='btn btn-info w-100 btn-lists'
								style={{marginTop: '25px', marginBottom: '30px'}}
								onClick={this.showTest}
								data-ba-i={'split-test-data-by-funnel-builder'}>
							{i18n.t('cfb-split-test-view-test')}
						</button>
					</div>
				);
			} else {
				return null;
			}
		} catch (e) {
			return null;
		}
	}

	modalData() {

		const rows = []
		let labels = [];
		let dataSet = [];

		function getPer(split) {

			if (split.access !== 0) {
				return (split.forward * 100 / split.access).toFixed(0);
			}
			return 0;

		}

		this.state.dataSplit.forEach((split) => {

			labels.push(split.page);
			dataSet.push(getPer(split));

			rows.push(
				<tr>
					<td className='text-left'>{split.page}</td>
					<td>{split.access}</td>
					<td>{split.forward}</td>
					<td>{getPer(split) + '%'}</td>
				</tr>
			)
		})

		let options = {
			labels: labels,
			datasets: [{
				data: dataSet,
				borderColor: 'white',
				backgroundColor: [
					'#142459',
					'#176BA0',
					'#19AADE',
					'#1DE4BD',
					'#7D3AC1',
					'#AF4BCE',
					'#DB4CB2',
					'#EA7369',
					'#820401',
					'#C02323',
					'#EF7E32',
					'#EE9A3A',
					'#E7E34E'
				]
			}]
		};

		return (
			<Modal show={this.state.show} onHide={this.handleClose} dialogClassName="modal-90w">
				<Modal.Header>
					<h3 className="modal-title">{i18n.t('cfb-tests-view-title')}</h3>
					<button type="button" className="close close-popup" onClick={this.handleClose}>&times;</button>
				</Modal.Header>

				<div className='modal-body'>
					<div className='d-inline-flex w-100'>
						<table className='table table-striped w-50 text-center'>
							<thead>
							<th className='text-left'>{i18n.t('cfb-tests-view-page')}</th>
							<th>{i18n.t('cfb-tests-view-access')}</th>
							<th>{i18n.t('cfb-tests-view-forward')}</th>
							<th>%</th>
							</thead>
							<tbody>
							{rows}
							</tbody>
						</table>
						<div className='w-50'>
							<Polar data={options}/>
						</div>
					</div>
					<div className='w-100' style={{paddingTop: '50px', fontSize: '12px'}}>
						{i18n.t('cfb-tests-view-helper')}
					</div>
				</div>

				<Modal.Footer>
					<button type="button" className="btn btn-link" onClick={this.handleClose}>
						{i18n.t('cfb-close')}
					</button>
				</Modal.Footer>
			</Modal>
		)
	}

	editPage = (title, id) => {

		let url;
		switch (process.env.REACT_APP_ENV) {

			case 'local':
				url = 'http://localhost:8080/#/editor/';
				break;
			case 'homologation':
				url = 'https://editor-dev.builderall.io/#/editor/';
				break;
			case 'production':
				url = 'https://cheetah-editor.builderall.com/#/editor/';
				break;
			default:
				url = 'http://localhost:8080/#/editor/';
				break;
		}

		website.getDataPagesEncrypted(this.props.funnel.encrypted_id).then(data => {
			data.data.layouts[0].pages.forEach(page => {
				if (page.title.toLowerCase() == title.toLowerCase() || page.canvas_reference == id) {
					window.open(url + page.encrypted_id, '_blank');
				}
			})
		})
	}

	getPage(title, id) {
		return <div>
			<div className='form-control place-page-test'>
				{title}
				{this.props.funnel.website_id !== null ?
					<FontAwesomeIcon icon={faPen} onClick={() => this.editPage(title, id)} color='#0080FC'/> :
					null
				}
				<div className='place-btn-remove'>
					<FontAwesomeIcon icon={faTrashAlt} onClick={() => this.deletePage(id)} color='#dc3545'/>
				</div>
			</div>
		</div>;
	}
}

export default SplitTest;
