import React, {Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';

import Dashboard from './Dashboard/dashboard';
import Editor from './Editor';
import Login from './login';

import 'react-toastify/dist/ReactToastify.css';
import './index.css';

const routes = [
	{
		path: '/',
		component: Dashboard,
		label: 'Overview',
	},
	{
		path: '/editor/:encrypted_id',
		component: Editor,
		label: 'Editor',
	},
];

ReactDOM.render(
	<Suspense fallback={null}>
		<Router forceRefresh={true}>
			<Switch>
				<Route path="/login/:email/:token" component={Login} exact key='login'/>
				<Route path="/login/:email/:token/:theme/:blueprint" component={Login} exact key='login'/>
				{routes.map((route) => (
					<PrivateRoute exact path={route.path} component={route.component} key={route.path}/>
				))}
			</Switch>
		</Router>

		<ToastContainer position="bottom-right"
						autoClose={5000}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable={false}
						pauseOnHover/>

	</Suspense>,
	document.getElementById('root')
);

export function PrivateRoute({component: Component, ...rest}) {
	const isAuthenticated = localStorage.getItem('token');
	return (
		<Route
			{...rest}
			render={props =>
				isAuthenticated !== null ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: "/login",
							state: {from: props.location}
						}}
					/>
				)
			}
		/>
	);
}