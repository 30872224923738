import React from "react";
import i18n from "../../../i18n";
import Select from "react-select/creatable/dist/react-select.esm";
import {EventEmitter} from "../../events";
import FormCreateCampaign from "../element/form";
import {toast} from "react-toastify";
import mailingBoss from "../../../utils/editor/mailingboss";
import {ShareData} from "../../shareData";

class Tag extends FormCreateCampaign {

	constructor(props) {
		super(props);

		this.state = {
			tag: {
				name: props.tagElement.name,
				config: 'none',
				list_tag: {}
			},

			listsOptions: [],
			campaignsAuto: [],

			//tag configuration
			configurationTags: [
				'none',
				'move-lead',
				'copy-lead',
				'remove-tag',
				'start-new-campaign'
			],

			//only visual
			form: {
				list_uid: null,
				name: ''
			},

			show: false,
			isAutomation: true,
			newCampaign: {
				name: '',
				subject: '',
				send_to: 'current',
				amount: 1,
				list_uid: '',
				type: 'automation'
			}
		}

		let lists = ShareData.recover('lists');

		if (lists) {
			this.state.listsOptions = lists;
		}
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
		this.setState({tag: nextProps.tagElement});
	}

	handleChange = (prop, value) => {

		let obj = this.state.tag;
		obj[prop] = value;
		this.setState({tag: obj});

		console.log(prop, value);

		this.props.handleChangeOptions(this.state.tag);
	}

	changeName = (e) => {
		this.handleChange('name', e.target.value);
	}

	saveAutomation = () => {

		let tag = JSON.parse(JSON.stringify(this.state.tag));
		tag['funnel_id'] = this.props.funnel_id;

		let toastId = toast(i18n.t('cfb-saving'), {autoClose: false});

		this.props.elements.forEach(element => {
			if (element.id === tag.params.id) {
				tag.params = JSON.parse(JSON.stringify(element));
			}
		})


		let errorMessage = '';

		if (typeof tag.list_tag === 'undefined' || Object.keys(tag.list_tag).length === 0) {
			errorMessage = 'cfb-select-list'
		}
		if (typeof tag.name === 'undefined' || tag.name === '') {
			errorMessage = 'cfb-put-tag-name'
		}

		if(errorMessage === ''){
			mailingBoss.createAutomationByTag(tag).then(response => {
				this.handleChange('action_id', response.data.action_id);

				toast.update(toastId, {
					render: i18n.t('cfb-saved'),
					type: toast.TYPE.SUCCESS,
					autoClose: 5000,
					className: 'rotateY animated'
				});
			})
		}else{
			toast.update(toastId, {
				render: i18n.t(errorMessage),
				type: toast.TYPE.ERROR,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		}
	}

	render() {
		return <div className='title-page-sidebar no-top'>
			{this.createPlaceToLoadLists(this.state.tag.list_tag, this.handleChangeListTag)}
			<label className='w-100 mt-3'>
				{i18n.t('cfb-tag-name')}
				<input type='text'
					   className='form-control'
					   value={this.state.tag.name}
					   onChange={this.changeName}
					   onBlur={this.changeName}/>
			</label>
			<small className='helper-tag'>{i18n.t('cfb-tag-automation-helper')}</small>
			{i18n.t('cfb-tag-time-insert')}
			{this.placeTime()}
			{this.tagConfigurationArea()}
			{this.setLists('list_tag', 'tag')}
			{this.setCampaign()}

			<div className={'line color'}/>
			{i18n.t('cfb-save-automation-desc')}
			<button className='btn btn-info w-100 margin-top-10' onClick={this.saveAutomation}  data-ba-i={'save-automation'}>
				{i18n.t('cfb-save-automation')}
			</button>

		</div>;
	}

	handleChangeConfig = (data) => {
		this.applyChanges('config', data.value);
	}

	handleRemoveTagName = (value) => {
		this.applyChanges('remove_tag_name', value);
	}

	handleChangeList = (data) => {
		this.applyChanges('list', data);
	}

	handleChangeListTag = (data) => {
		this.applyChanges('list_tag', data);
	}

	handleSetAnotherCampaign = (data) => {
		this.applyChanges('campaign', data);
	}

	applyChanges(prop, value) {

		let tag = this.state.tag;
		tag[prop] = value;

		EventEmitter.dispatch('changeOptionsNode', tag);

		this.setState({tag: tag})
	}

	setLists() {

		if (
			typeof this.state.tag.config !== 'undefined' &&
			(
				'move-lead' === this.state.tag.config ||
				'copy-lead' === this.state.tag.config
			)
		)

			return <>
				<div className='separator'/>
				{this.createPlaceToLoadLists(this.state.tag.list, this.handleChangeList)}
			</>
	}

	loadCampaign = () => {

		let toastId = toast(i18n.t('cfb-loading-campaigns'), {autoClose: false});

		mailingBoss.getCampaignsAuto().then((response) => {

			this.setState({campaignsAuto: response.data.data});
			this.setState({loadedCampaign: true});

			toast.update(toastId, {
				render: i18n.t('cfb-loaded'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		})
	}

	placeCampaignsAutomation() {
		return (
			<div className='place-btn text-left'>
				{i18n.t('cfb-campaigns-automation')}
				<button className='btn btn-info w-100 btn-lists margin-top-10 mb-0' onClick={this.loadCampaign}>
					{i18n.t('cfb-load-campaigns-automation')}
				</button>
			</div>
		)
	}

	placeSelectCampaignsTags() {

		const options = [];

		this.state.campaignsAuto.forEach((elem) => {
			options.push({value: elem.campaign_uid, label: elem.name});
		})

		return (
			<div className='margin-top-10'>
				<Select options={options} onChange={(data) => this.handleSetAnotherCampaign(data)}
						menuPlacement="auto"
						value={this.state.tag.campaign}/>
			</div>
		)
	}

	apply = () => {

		this.setShow(false);

		mailingBoss.createCampaignAutomation(this.state.newCampaign).then(() => {
			this.loadCampaign();
		});
	}

	setCampaign() {
		if (
			typeof this.state.tag.config !== 'undefined' &&
			'start-new-campaign' === this.state.tag.config
		)
			return <>
				<div className='separator'/>
				{i18n.t('cfb-create-campaign')}
				{this.placeCreateCampaigns(this.apply)}
				<div className={'line'}/>

				{this.placeCampaignsAutomation()}
				{this.state.campaignsAuto.length !== 0 ? this.placeSelectCampaignsTags() : null}

				<div className={'line'}/>
				{this.placeEditEmail()}
			</>;
	}

	editEmail = () => {
		EventEmitter.dispatch('editionEmail', this.state.tag.campaign.value);
	}

	placeEditEmail() {
		if (
			typeof this.state.tag.config === 'undefined' ||
			'start-new-campaign' !== this.state.tag.config ||
			typeof this.state.tag.campaign === 'undefined' ||
			typeof this.state.tag.campaign.value === 'undefined' ||
			this.state.tag.campaign.value === ''
		) {
			return null;
		}

		return <>
			{i18n.t('cfb-edit-email')}
			<button className='btn btn-success w-100 btn-lists btn-create-campaign mb-0 margin-top-10'
					onClick={this.editEmail}>{i18n.t('cfb-edit-email')}
			</button>
		</>
	}

	getSelectConfigurations() {

		let options = [];
		let objSelected = {};

		this.state.configurationTags.forEach((config) => {
			if (
				typeof this.state.tag.config !== 'undefined' &&
				config === this.state.tag.config
			) {
				objSelected = {value: config, label: i18n.t('cfb-change-config-' + config)};
			}
			options.push({value: config, label: i18n.t('cfb-change-config-' + config)});
		})

		return <Select options={options}
					   onChange={(data) => this.handleChangeConfig(data)}
					   menuPlacement="auto"
					   value={objSelected}/>;
	}

	removeTagArea() {

		if (
			typeof this.state.tag.config !== 'undefined' &&
			'remove-tag' === this.state.tag.config
		) {

			let tagName = '';

			if (typeof this.state.tag.remove_tag_name !== 'undefined')
				tagName = this.state.tag.remove_tag_name;

			return <label className='w-100  text-left '>
				{i18n.t('cfb-remove-tag-name')}
				<div className='separator'/>
				<input className='form-control' value={tagName}
					   onChange={(e) => this.handleRemoveTagName(e.target.value)}/>
			</label>;
		}
	}

	tagConfigurationArea() {

		return (
			<>
				<div className='separator'/>
				<label className='w-100  text-left '>
					{i18n.t('cfb-tag-configuration')}
					<div className='separator'/>
					{this.getSelectConfigurations(this.state.configurationTags, 'tag')}
				</label>

				<div className='separator'/>
				{this.placeTime(true)}

				<div className='separator'/>
				{this.removeTagArea()}
			</>
		);
	}

	placeTime(doThing = false) {

		if (doThing) {
			if (
				typeof this.state.tag.config !== 'undefined' &&
				'start-new-campaign' !== this.state.tag.config
			) {
				return null;
			}
		}

		return (
			<>
				{doThing ? i18n.t('cfb-tag-time-do-config') : null}
				<div className='d-flex'>
					<div className='w-50' style={{paddingRight: '15px'}}>
						<label className='mb-0'>{doThing ? 'Day' : 'Min'}</label>
						<input className='form-control' value={doThing ? this.state.tag.day_action : this.state.tag.min}
							   type='number' min='0' max='60'
							   onChange={(e) => this.handleChange((doThing ? 'day_action' : 'min'), e.target.value)}/>
					</div>
					<div className='w-50' style={{paddingLeft: '15px'}}>
						<label className='mb-0'>{doThing ? 'Min' : 'Sec'}</label>
						<input className='form-control' value={doThing ? this.state.tag.min_action : this.state.tag.sec}
							   type='number' min='0' max='60'
							   onChange={(e) => this.handleChange((doThing ? 'min_action' : 'sec'), e.target.value)}/>
					</div>
				</div>
			</>
		);
	}
}

export default Tag;
