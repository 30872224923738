import React, {memo} from 'react';

import {Handle} from 'react-flow-renderer';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faHome, faPlus} from '@fortawesome/free-solid-svg-icons';
import {EventEmitter} from "../events";

export default memo(({data}) => {

	let _sourcePosition = 'right';

	if (!data.options.has_button || data.options.has_checkout || data.options.has_webinar || data.options.has_calendar || data.options.has_form) {
		_sourcePosition = 'bottom';
	}

	function showPopupAdd() {
		document.getElementById('sidebar-edition').classList.remove('active');
		document.getElementById('addPagePopup').style.display = 'block';
	}

	function showEditPage(ev) {
		ev.preventDefault();
		EventEmitter.dispatch('editionPage', data.idNode);
	}

	function getButtonAdd() {
		return <span className='btn-secondary btn-add-new-pages' id='add-btn' onClick={showPopupAdd}>
					<FontAwesomeIcon icon={faPlus}/>
				</span>;
	}

	const restrictedAreaCircles = [];

	if (data.options.restrictedAreas) {
		data.options.restrictedAreas.forEach((r, idx) => {
			restrictedAreaCircles.push(
				<div className='rounded-circle restricted-area-circle float-right m-1'
					 key={'res' + idx}
					 id={'restrictedArea' + r}/>
			);
		})
	}

	let hasSplitTest = false
	if (
		typeof data != 'undefined' &&
		typeof data.options != 'undefined' &&
		typeof data.options.split != 'undefined' &&
		data.options.split.active
	) {
		hasSplitTest = true;
	}

	let isHome = false
	if (
		typeof data != 'undefined' &&
		typeof data.home != 'undefined' &&
		data.home
	) {
		isHome = true;
	}

	function getDivSplitTest() {
		return <div className='page-element flow-element second-page'>
			<div className='image-page text-center'>
				<img src={'https://sitebuilder-admin.omb11.com/storage/' + data.options.img} alt='page'/>
			</div>
		</div>;
	}

	function getDivHome() {
		return <div className='home-icon'>
			<FontAwesomeIcon icon={faHome} color={'#FFFFFF'}/>
		</div>;
	}

	const deleteNode = (idNode) => {
		EventEmitter.dispatch('deleteElement', idNode);
	}

	function getImgElementPage() {

		let value = 'https://sitebuilder-admin.omb11.com/storage/' + data.options.img;
		if (typeof data.img != 'undefined')
			value = data.img;

		return <img src={value} alt='page'/>;
	}

	return (
		<>
			{hasSplitTest ? getDivSplitTest() : null}
			<div className='page-element flow-element' data-id={data.options.id} data-nodeid={data.idNode}
					 onContextMenu={showEditPage}>
				<Handle type="target" position="left"/>
				<div className='place-rounded-circle'>
					{restrictedAreaCircles}
				</div>
				{isHome ? getDivHome() : null}
				<div className='image-page text-center'>
					{getImgElementPage()}
					{_sourcePosition !== 'bottom' ? getButtonAdd() : null}
				</div>
				<h5 className='title-page'>{data.label}</h5>
				<Handle type="source" position={_sourcePosition}/>

				<div className={'removeIcon'} onClick={() => deleteNode(data.idNode)}/>
			</div>
		</>
	);
});
