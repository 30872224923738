import Http from "../http";

const prefix = '/website';

const website = new (function () {

	this.getDataGraphs = function (website_id) {
		return Http.authApi().get(`${prefix}/data/graphs/${website_id}`);
	};

	this.getDataAccess = function (website_id) {
		return Http.authApi().get(`${prefix}/data/access/${website_id}`);
	};

	this.getDataTests = function (node_id, funnel_id) {
		return Http.authApi().get(`${prefix}/data/tests/${node_id}/${funnel_id}`);
	};

	this.getDataPages = function (funnel_id) {
		return Http.authApi().get(`${prefix}/data/pages/${funnel_id}`);
	};

	this.getDataPagesEncrypted = function (funnel_id) {
		return Http.authApi().get(`${prefix}/data/pages-encrypted/${funnel_id}`);
	};

	this.sendDataPages = function (data) {
		return Http.authApi().post(`${prefix}/data/receive-pages`, {data});
	};

	this.getAllUserWebsites = function () {
		return Http.authApi().get(`${prefix}s`);
	};

})();

export default website;