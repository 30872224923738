import React, {memo} from 'react';
import {EventEmitter} from "../events";

export default memo(({data}) => {

	function showPopupEditRestrictedArea(ev) {
		ev.preventDefault();
		EventEmitter.dispatch('editionRestrictedArea', data.idNode);
	}

	let color = 'blue';
	if (data.color)
		color = data.color;

	const deleteNode = (idNode) => {
		EventEmitter.dispatch('deleteElement', idNode);
	}

	return (
		<div className='restricted-area-element flow-element' data-nodeid={data.idNode}
			 onContextMenu={showPopupEditRestrictedArea}>
			<div className='rounded-circle restricted-area-circle float-right m-1' style={{backgroundColor: color}}/>
			<h6 className='title-elem'>{data.label}</h6>

			<div className={'removeIcon'}  onClick={() => deleteNode(data.idNode)}/>
		</div>
	);
});
