import React from "react";
import mailingBoss from "../../../utils/editor/mailingboss";
import {toast} from "react-toastify";
import i18n from "../../../i18n";
import {ShareData} from "../../shareData";
import Select from "react-select/creatable/dist/react-select.esm";
import {Modal} from "react-bootstrap";

class Lists extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			showCreationPopup: false,

			mboss: '',
			nameList: '',
			descriptionList: '',
			fromNameList: '',
			fromEmailList: '',
			replyToList: '',
		}
	}

	componentDidMount() {
		this.setState({mboss: 'mb5'})
	}

	load = () => {

		let toastId = toast(i18n.t('cfb-loading-lists'), {autoClose: false});

		mailingBoss.getLists().then((response) => {
			this.setState({lists: response.data.data});

			const items = [];

			this.state.lists.map((list) => {
				items.push({value: list.general.list_uid, label: list.general.name})
			});

			ShareData.subscribe('lists', items);

			this.setState({listsOptions: items})

			toast.update(toastId, {
				render: i18n.t('cfb-loaded'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		});
	}

	handleCloseCreationPopup = () => {
		this.setState({showCreationPopup: false});
	}

	handleOptionChange = (changeEvent) => {
		this.setState({mboss: changeEvent.target.value})
	}

	handleApplyCreationList = () => {
		let objNewList = {};

		objNewList['mboss'] = this.state.mboss;
		objNewList['listName'] = document.getElementById('nameList').value;
		objNewList['listDesc'] = document.getElementById('descriptionList').value;
		objNewList['fromName'] = document.getElementById('fromNameList').value;
		objNewList['fromEmail'] = document.getElementById('fromEmailList').value;
		objNewList['replyTo'] = document.getElementById('replyToList').value;

		let toastId = toast(i18n.t('cfb-creating-lists'), {autoClose: false});

		this.handleCloseCreationPopup();

		mailingBoss.createList(objNewList).then(() => {
			toast.update(toastId, {
				render: i18n.t('cfb-created'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});

			this.load();
		})
	}

	createList = () => {
		this.setState({showCreationPopup: true});
	}

	createPlaceToLoadLists = (selectedValue, callback) => {
		return <>
			<div className='place-btn'>
				<h4 className={'text-left'}>{i18n.t('cfb-create-load-lists-desc')}</h4>
				<button className='btn btn-info w-100 btn-lists' onClick={this.createList}  data-ba-i={'create-list'}>
					{i18n.t('cfb-create-new-list')}
				</button>
			</div>

			<div className='place-btn'>
				<button className='btn btn-info w-100 btn-lists' onClick={this.load}>
					{i18n.t('cfb-load-lists')}
				</button>
			</div>
			<div className=''>
				<Select options={this.state.listsOptions} onChange={callback}
						menuPlacement="auto"
						value={selectedValue}/>
			</div>


			<Modal show={this.state.showCreationPopup} onHide={this.handleCloseCreationPopup}
				   dialogClassName="modal-campaign">
				<Modal.Header>
					<h3 className="modal-title">{i18n.t('cfb-create-list-title')}</h3>
					<button type="button" className="close close-popup"
							onClick={this.handleCloseCreationPopup}>&times;</button>
				</Modal.Header>

				<Modal.Body>

					<label className='w-100'>
						{i18n.t('cfb-create-which-mboss')}
						<div className='w-100'>
							<label>
								<input type='radio'
											 style={{marginRight: '3px'}}
											 value='mb4'
											 id="mboss"
											 onChange={this.handleOptionChange}
											 checked={this.state.mboss === "mb4"}/>
								{'Mailing Boss 4.0'}
							</label>
							<label style={{marginLeft: '20px'}}>
								<input type='radio'
											 style={{marginRight: '3px'}}
											 value='mb5'
											 id="mboss"
											 onChange={this.handleOptionChange}
											 checked={this.state.mboss === "mb5"}/>
								{'Mailing Boss 5.0'}
							</label>
						</div>
					</label>

					<label className='w-100'>
						{i18n.t('cfb-create-name-list')}
						<input className='form-control' id={'nameList'}
							   value={this.state.nameList}/>
					</label>
					<label className='w-100'>
						{i18n.t('cfb-create-description')}
						<input className='form-control' id={'descriptionList'}
							   value={this.state.descriptionList}/>
					</label>
					<label className='w-100'>
						{i18n.t('cfb-create-from-name')}
						<input className='form-control' id={'fromNameList'}
							   value={this.state.fromNameList}/>
					</label>
					<label className='w-100'>
						{i18n.t('cfb-create-from-email')}
						<input className='form-control' id={'fromEmailList'}
							   value={this.state.fromEmailList}/>
					</label>
					<label className='w-100'>
						{i18n.t('cfb-create-reply')}
						<input className='form-control' id={'replyToList'}
							   value={this.state.replyToList}/>
					</label>


				</Modal.Body>

				<Modal.Footer>
					<button type="button" className="btn btn-link" onClick={this.handleCloseCreationPopup}>
						{i18n.t('cfb-close')}
					</button>
					<button type="button" className="btn btn-success btn-upper" onClick={this.handleApplyCreationList}>
						{i18n.t('cfb-save-changes')}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	}

}

export default Lists;
