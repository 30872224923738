import Http from './../http';

const prefix = '/template';

const template = new (function () {

	this.getAll = function(param)  {
		// return Http.authProd().get(`${prefix}?tag_template=${param}`);
		return Http.authApi().get(`${prefix}?tag_template=${param}`);
	};

})();

export default template;