import React from 'react';
import ReactFlow, {addEdge, Background, Controls, removeElements} from 'react-flow-renderer';

import './flow.css';

import PageNode from './pageNode';
import ExternalNode from './externalNode';
import SubElementNode from './subElementNode';
import RestrictedAreaNode from './restrictedAreaNode';
import CampaignNode from './campaignNode';
import AutomationNode from './automationNode';

import CanvasEdge from './edge';

import EditorTour from "./tour";
import {ShareData} from "../shareData";
import {EventEmitter} from "../events";

const onLoad = (reactFlowInstance) => reactFlowInstance.fitView();

function EditorFlow(props) {

	ShareData.subscribe('elements', props.elements);

	EventEmitter.subscribe('deleteElement', (idNode) => deleteElement(idNode));

	const onConnect = (params) => {

		let elements = ShareData.recover('elements');

		if (params.source !== params.target)
			props.handleElements(addEdge({
				...params,
				...{
					// animated: true,
					type: 'canvasEdge',
					arrowHeadType: 'arrowclosed'
				}
			}, elements));
	}

	const deleteElement = (idNode) => {

		let removeThisElements = [];

		props.elements.forEach((el, i) => {
			if (el.id == idNode) {
				removeThisElements.push(props.elements[i]);
			}
		})

		onElementsRemove(removeThisElements);
	}

	const onElementsRemove = elementsToRemove => {

		elementsToRemove.forEach(elR => {
			props.elements.forEach((el, i) => {
				if (el.id === elR.target && props.elements[i].deleteable) {
					elementsToRemove.push(props.elements[i]);
				}
			})
		})

		elementsToRemove.forEach(elR => {
			props.elements.forEach((el, i) => {
				if (el.id === elR.source) {
					let elemTarget = null;
					props.elements.forEach((elS, iS) => {
						if (elS.id === elR.target) {
							elemTarget = props.elements[iS];
						}
					})

					if (elemTarget) {
						if (elemTarget.data.type === 'exit') props.elements[i].data.options.have_exit_popup = false;
						if (elemTarget.data.type === 'time') props.elements[i].data.options.have_time_popup = false;
						if (elemTarget.data.type === 'click') props.elements[i].data.options.have_click_popup = false;
						if (elemTarget.data.type === 'form') props.elements[i].data.options.have_form = false;
						if (elemTarget.data.type === 'checkout') props.elements[i].data.options.have_checkout = false;
						if (elemTarget.data.type === 'webinar') props.elements[i].data.options.have_webinar = false;
						if (elemTarget.data.type === 'calendar') props.elements[i].data.options.have_calendar = false;

					}
				}
			})
		})

		props.handleElements(removeElements(elementsToRemove, props.elements));
	}

	const onNodeDragStop = (e, node) => {

		props.elements.forEach((el, i) => {
			if (el.id === node.id) {
				props.elements[i].position = node.position;
			}
		})

		props.handleElements(props.elements);
	}

	return (
		<ReactFlow
			elements={props.elements}
			onLoad={onLoad}
			nodeTypes={{
				pageNode: PageNode,
				externalNode: ExternalNode,
				subElementNode: SubElementNode,
				restrictedAreaNode: RestrictedAreaNode,
				campaignNode: CampaignNode,
				automationNode: AutomationNode
			}}
			onElementsRemove={onElementsRemove}
			onConnect={onConnect}
			onNodeDragStop={onNodeDragStop}
			className="react-flow-basic-example dropzone"
			connectionLineStyle={{stroke: '#a4c9ff', strokeWidth: 2}}
			defaultZoom={1.5}
			minZoom={0.2}
			maxZoom={4}
			edgeTypes={{
				canvasEdge: CanvasEdge
			}}
			arrowHeadColor={'#a4c9ff'}
		>
			<Controls/>
			<Background
				color="#888"
				gap={2000}/>
			<EditorTour/>

		</ReactFlow>
	)
}

export default EditorFlow;