import Http from './../http';

const prefix = '/mailing_boss';

const mailingBoss = new (function () {

	this.getLists = function () {
		return Http.authApi().get(`${prefix}/get-lists`);
	};

	this.getCampaigns = function (list) {
		return Http.authApi().get(`${prefix}/get-campaigns/${list}`);
	};

	this.getCampaignsAuto = function () {
		return Http.authApi().get(`${prefix}/get-campaigns-automation`);
	};

	this.createCampaign = function (data, list_uid) {
		return Http.authApi().post(`${prefix}/create-campaign`, {data, list_uid});
	};

	this.getCampaignData = function (campaignUid) {
		return Http.authApi().get(`${prefix}/get-campaign-data/${campaignUid}`);
	};

	this.updateCampaign = function (data, campaignUid) {
		return Http.authApi().post(`${prefix}/update-campaign`, {data, campaignUid});
	};

	this.getFields = function (list) {
		return Http.authApi().get(`${prefix}/get-fields/${list}`);
	};

	this.createList = function (data) {
		return Http.authApi().post(`${prefix}/create-list`, {data});
	};

	this.getCustomUrl = function (list) {
		return Http.authApi().get(`${prefix}/get-custom-url/${list}`);
	};

	this.setCustomUrl = function (data, list_uid) {
		Http.authApi().post(`${prefix}/set-custom-url`, {data, list_uid});
	};

	this.getUserInfoBasic = function () {
		return Http.authApi().get(`${prefix}/get-user-info-basic`);
	};

	this.createAutomation = function (data) {
		return Http.authApi().post(`${prefix}/create-automation`, {data});
	};

	this.createAutomationByTag = function (data) {
		return Http.authApi().post(`${prefix}/create-automation-by-tag`, {data});
	};

	this.createCampaignAutomation = function (data) {
		return Http.authApi().post(`${prefix}/create-automation-campaign`, {data});
	}

})();

export default mailingBoss;