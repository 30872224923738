import React from "react";
import i18n from "../../../i18n";
import FileBase64 from 'react-file-base64';

class Checkout extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			checkout: {
				name: props.checkoutElement.name,
				description: props.checkoutElement.description,
				price: props.checkoutElement.price,

				image: props.checkoutElement.image,
				currency: '',
				restricted_area_ids: [],
				urls: [],
				noThanks: null
			}
		}
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
		this.setState({checkout: nextProps.checkoutElement});
	}

	handleChange = (prop, value) => {

		let obj = this.state.checkout;
		obj[prop] = value;
		this.setState({checkout: obj});

		this.props.handleChangeOptions(this.state.checkout);
	}

	changeName = (e) => {
		this.handleChange('name', e.target.value);
	}

	changeDesc = (e) => {
		this.handleChange('description', e.target.value);
	}

	changePrice = (e) => {
		this.handleChange('price', e.target.value);
	}

	getFiles(file) {
		this.handleChange('image', file.base64);
	}

	handleUrl = () => {
		this.handleChange('noThanks', document.getElementById('select-page-no-thanks').value);
	}

	render() {
		return <div className='tab-content'>
			<label className='w-100 image-place-checkout'>
				{i18n.t('cfb-checkout-img-product')}
				{this.state.checkout.image != null ? this.getImg() : null}
				<FileBase64
					multiple={false}
					onDone={this.getFiles.bind(this)}/>
			</label>
			<label className='w-100'>
				{i18n.t('cfb-checkout-name-product')}
				<input type='text'
					   className='form-control'
					   key={this.props.checkoutElement.id}
					   value={this.state.checkout.name}
					   onChange={this.changeName}
					   onBlur={this.changeName}/>
			</label>
			<label className='w-100'>
				{i18n.t('cfb-checkout-description-product')}
				<textarea className='form-control'
						  key={this.props.checkoutElement.id}
						  value={this.state.checkout.description}
						  onChange={this.changeDesc}
						  onBlur={this.changeDesc}/>
			</label>
			<label className='w-100'>
				{i18n.t('cfb-checkout-price-product')}
				<input type='text'
					   className='form-control'
					   key={this.props.checkoutElement.id}
					   value={this.state.checkout.price}
					   onChange={this.changePrice}
					   onBlur={this.changePrice}/>
			</label>

			<label className='w-100'>
				{this.placeUrls()}
			</label>

		</div>;
	}

	placeUrls() {

		const options = [];
		options.push(
			<option value=''>{i18n.t('cfb-url-select-page')}</option>
		)
		this.props.elements.forEach((elem) => {
			if (elem.type === 'pageNode') {
				options.push(
					<option value={elem.data.idNode}>{elem.data.label}</option>
				)
			}
		})

		if (typeof this.props.checkoutElement.params !== 'undefined') {

			if (this.props.checkoutElement.params.has_button) {
				return (
					<div className='w-100'>
						{i18n.t('cfb-checkout-no-thanks')}
						<div className='url-place'>
							<select className='form-control'
									onChange={this.handleUrl}
									id={'select-page-no-thanks'}
									value={this.state.checkout.noThanks}>
								{options}
							</select>
						</div>
					</div>);
			}
		}

		return null;
	}

	getImg() {
		return <img src={this.state.checkout.image} alt={'image-checkout'}/>;
	}
}

export default Checkout;
