import React, {memo} from 'react';
import {EventEmitter} from '../events';

import {Handle} from 'react-flow-renderer';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faExclamationTriangle} from '@fortawesome/free-solid-svg-icons';
import {faClock} from "@fortawesome/free-regular-svg-icons";
import i18n from "../../i18n";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

export default memo(({data}) => {

	function showPopupAdd() {
		document.getElementById('sidebar-edition').classList.remove('active');
		document.getElementById('addPagePopup').style.display = 'block';
	}

	function showEditPage(ev) {
		ev.preventDefault();
		EventEmitter.dispatch('editionSubElement', data.params.idNode);
	}

	let timeArea = null;
	let configArea = null;
	let alertArea = null;

	if (typeof data !== 'undefined' && typeof data['min'] !== 'undefined' && typeof data['sec'] !== 'undefined') {

		timeArea = <div className='timer-tag'>
			<FontAwesomeIcon icon={faClock} color={'#5A5959'}/>
			{data['min']}.{data['sec']}s
		</div>;
	}

	if (data.type === 'tag' && typeof data.name !== 'undefined' && data.name !== '')
		configArea = <div>{i18n.t('cfb-tag-name')}: {data.name}</div>;

	if (data.type === 'form' && typeof data.form !== 'undefined' && typeof data.form.label !== 'undefined')
		configArea = <div>{i18n.t('cfb-list-name')}: {data.form.label}</div>;

	if (data.type === 'checkout' && typeof data.name !== 'undefined' && data.name !== '')
		configArea = <div>{i18n.t('cfb-product-name')}: {data.name}</div>;


	function getAutomationArea() {
		if (configArea !== null)
			return <div className='automation-config'>
				{configArea}
			</div>;
	}

	function getHandle() {
		if (data.type !== 'tag')
			return <>
				<span className='btn-secondary btn-add-new-pages' id='add-btn' onClick={showPopupAdd}>
					<FontAwesomeIcon icon={faPlus}/>
				</span>
				<Handle type="source" position="right"/>
			</>;
	}

	const deleteNode = (idNode) => {
		EventEmitter.dispatch('deleteElement', idNode);
	}

	if (data.type === 'form' && (typeof data.form === 'undefined' || typeof data.form.label === 'undefined'))
		alertArea =
			<OverlayTrigger  placement="right" overlay={
				<Tooltip
					id={`tooltip-right`}>{i18n.t('cfb-need-form-warning')}
				</Tooltip>}>
				<FontAwesomeIcon className={'alert-icon'} onClick={showEditPage} icon={faExclamationTriangle}/>
			</OverlayTrigger>;

	if (data.type === 'checkout' && (typeof data.name === 'undefined' ||  data.name === ''))
		alertArea =
			<OverlayTrigger  placement="right" overlay={
				<Tooltip
					id={`tooltip-right`}>{i18n.t('cfb-need-checkout-warning')}
				</Tooltip>}>
				<FontAwesomeIcon className={'alert-icon'} onClick={showEditPage} icon={faExclamationTriangle}/>
			</OverlayTrigger>;

	return (
		<div className='sub-element-element' onContextMenu={showEditPage}
			 data-nodeid={data.params.idNode}>
			<Handle type="target" position="top"/>
			{alertArea}
			{timeArea}
			<h6 className='title-elem'>{data.label}</h6>
			{getAutomationArea()}
			{getHandle()}

			<div className={'removeIcon'} onClick={() => deleteNode(data.params.idNode)}/>
		</div>
	);
});
