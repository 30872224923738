import React from 'react';
import Tour from 'reactour';
import {EventEmitter} from "../events";
import {Button} from "react-bootstrap";
import i18n from "../../i18n";

class EditorTour extends React.Component {
	constructor() {
		super();

		this.state = {
			steps: [
				{
					selector: '.btn-start',
					// content: i18n.t('cfb-1-step-tour'),
					content: () => (
						<div>
							{i18n.t('cfb-1-step-tour')}
						</div>
					),
				},
				{
					selector: '.modal-dialog',
					content: () => (
						<div>
							{i18n.t('cfb-2-step-tour')}
						</div>
					),
				},
				{
					selector: '.nav-pills',
					content: () => (
						<div>
							{i18n.t('cfb-3-step-tour')}
						</div>
					),
				},
				{
					selector: '.page-add',
					content: () => (
						<div>
							{i18n.t('cfb-4-step-tour')}
						</div>
					),
				},
				{
					selector: '.wrapper',
					content: () => (
						<div>
							{i18n.t('cfb-5-step-tour')}
						</div>
					),
				},
				{
					selector: '.react-flow__controls',
					content: () => (
						<div>
							{i18n.t('cfb-6-step-tour')}
						</div>
					),
				},
				{
					selector: '.sidebar-page-edition-absoulte',
					content: () => (
						<div>
							{i18n.t('cfb-7-step-tour')}
						</div>
					),
				},
				{
					selector: '.btn-add-new-pages',
					content: () => (
						<div>
							{i18n.t('cfb-8-step-tour')}
						</div>
					),
				},
				{
					selector: '.float-right',
					content: () => (
						<div>
							{i18n.t('cfb-9-step-tour')}
						</div>
					),
				}
			],
			isTourOpen: false,
			oneTime: true,
		}

		EventEmitter.subscribe('startTour', () => this.startTour());
	}

	componentDidMount() {
		if (window.getCookie('tourEditor') !== 'loaded') {
			let rc = this;
			setTimeout(() => {
				// eslint-disable-next-line no-undef
				if ($('.flow-element').length === 0) {
					window.setCookie('tourEditor', 'loaded');
					console.log('nao tem');
					rc.startTour();
				} else {
					console.log('ja foi');
				}
			}, 3000);
		}
	}

	startTour = () => {
		this.refs.tour.gotoStep(0);
		this.setState({isTourOpen: true});
	}

	closeTour = () => {
		this.setState({isTourOpen: false})
	}

	blocked = () => {
		alert('invalid');
	}

	currentStep = (step) => {
		if (step === 1)
			document.querySelector('.btn-start').click();
		if (step === 4)
			EventEmitter.dispatch('addFirstPage');
		if (step === 6 && this.state.oneTime) {
			this.setState({oneTime: false});
			// eslint-disable-next-line no-undef
			EventEmitter.dispatch('editionPage', $('.flow-element').attr('data-nodeid'));
		}
		if (step === 7) {
			EventEmitter.dispatch('closeSidebars');
		}
	}

	render() {

		return (<>
				<Tour
					ref='tour'
					steps={this.state.steps}
					getCurrentStep={curr => this.currentStep(curr)}
					closeButtonAriaLabel={'close'}
					prevStep={this.blocked}
					isOpen={this.state.isTourOpen}
					disableDotsNavigation={true}
					showNavigation={false}
					lastStepNextButton={<Button>{i18n.t('cfb-tour-done')}</Button>}
					nextButton={<Button>{i18n.t('cfb-tour-next')}</Button>}
					disableInteraction={true}
					onRequestClose={this.closeTour}/>
			</>
		)
	}

}

export default EditorTour;