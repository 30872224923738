import React from "react";
import i18n from "../../../i18n";
import Lists from "../extends/lists";
import popupGeneric from "../../../img/1.svg";
import popupOptIn from "../../../img/2.svg";
import Select from "react-select/creatable/dist/react-select.esm";

class Popup extends Lists {

	constructor(props) {
		super(props);

		this.state = {
			listsOptions: [],
			popup: props.popupElement
		}
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
		this.setState({popup: nextProps.popupElement});
	}

	handleChange = (prop, value) => {

		let obj = this.state.popup;
		obj[prop] = value;
		this.setState({popup: obj});

		this.props.handleChangeOptions(this.state.popup);
	}

	changeType = (value) => {
		this.handleChange('option', value);
	}

	handlePage = (e) => {
		this.handleChange('targetPage', e.target.value);
	}

	handleChangeList = (data) => {
		this.handleChange('form', data);
	}

	render() {
		return <div className='title-page-sidebar no-top'>
			<div className='helper text-left w-100' style={{marginBottom: '30px'}}>
				{i18n.t('cfb-popup-text-helper')}
			</div>
			<div className='d-flex place-popup-img'>
				<div className='w-50 cursor-pointer' onClick={() => this.changeType('button')}
					 style={{paddingRight: '15px'}}>
					<label>{i18n.t('cfb-generic-popup')}</label>
					<img className={'image-popup ' + (this.state.popup.option === 'button' ? 'active' : '')}
						 src={popupGeneric}/>
				</div>
				<div className='w-50 cursor-pointer' onClick={() => this.changeType('optin')}
					 style={{paddingLeft: '15px'}}>
					<label>{i18n.t('cfb-optin-popup')}</label>
					<img className={'image-popup ' + (this.state.popup.option === 'optin' ? 'active' : '')}
						 src={popupOptIn}/>
				</div>
			</div>

			{this.state.popup.option === 'button' ? this.placePages() : null}
			{this.state.popup.option === 'optin' ? this.placeLists() : null}
			{this.state.popup.type === 'time' ? this.placeTime() : null}
		</div>;
	}

	placePages() {

		const options = [];
		options.push(
			<option value=''>{i18n.t('cfb-select-page')}</option>
		)
		this.props.elements.forEach((elem) => {
			if (elem.type === 'pageNode') {
				options.push(
					<option value={elem.data.idNode}>{elem.data.label}</option>
				)
			}
		})

		const items = [];
		items.push(
			<div className='page-place'>
				<label className='w-100'>
					{i18n.t('cfb-page-target')}
					<select className='form-control'
							onChange={this.handlePage}
							value={this.state.popup.targetPage}>
						{options}
					</select>
				</label>
			</div>
		)

		return (
			<div className=''>
				<div>{items}</div>
			</div>);
	}

	placeLists() {
		return (<>
			<div className='place-btn'>
				<button className='btn btn-info w-100 btn-lists' onClick={this.load}>
					{i18n.t('cfb-load-lists')}
				</button>
			</div>
			<div className=''>
				<Select options={this.state.listsOptions} onChange={this.handleChangeList}
						menuPlacement="auto"
						value={this.state.popup.form}/>
			</div>
		</>);
	}

	placeTime() {
		return (
			<div className='d-flex' style={{paddingTop: '20px'}}>
				<div className='w-50' style={{paddingRight: '15px'}}>
					<label className='mb-0'>Min</label>
					<input className='form-control' value={this.state.popup.min}
						   type='number' min='0' max='60'
						   onChange={(e) => this.handleChange('min', e.target.value)}/>
				</div>
				<div className='w-50' style={{paddingLeft: '15px'}}>
					<label className='mb-0'>Sec</label>
					<input className='form-control' value={this.state.popup.sec}
						   type='number' min='0' max='60'
						   onChange={(e) => this.handleChange('sec', e.target.value)}/>
				</div>
			</div>
		);
	}

}

export default Popup;