import React, {memo} from 'react';

import {Handle} from 'react-flow-renderer';

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from '@fortawesome/free-solid-svg-icons';
import {EventEmitter} from "../events";

export default memo(({data}) => {

	function showPopupAdd() {
		document.getElementById('sidebar-edition').classList.remove('active');
		document.getElementById('addPagePopup').style.display = 'block';
	}

	function showEditPage(ev) {
		ev.preventDefault();
		EventEmitter.dispatch('editionSubElement', data.idNode);
	}

	const deleteNode = (idNode) => {
		EventEmitter.dispatch('deleteElement', idNode);
	}

	let img = 'https://sitebuilder-admin.omb11.com/storage/' + data.options.img
	let classAdd = ''
	if (typeof data.userImg !== 'undefined' && data.userImg) {
		img = data.options.img;
		classAdd = 'user-img-element'
	}

	return (
		<div className={'external-element flow-element ' + classAdd} data-id={data.options.id} data-nodeid={data.idNode}
			 onContextMenu={showEditPage}>
			<Handle type="target" position="left"/>
			<img src={img} alt='external'/>
			<span className='btn-secondary btn-add-new-pages' id='add-btn' onClick={showPopupAdd}>
				<FontAwesomeIcon icon={faPlus}/>
			</span>

			<h6 className='title-elem'>{data.label}</h6>

			<Handle type="source" position="right"/>

			<div className={'removeIcon'} onClick={() => deleteNode(data.idNode)}/>
		</div>
	);
});
