import Http from "../http";

const prefix = '/funnels/report';

const analyserReport = new (function () {

	this.get = function (funnel_id) {
		return Http.authApi().get(`${prefix}/${funnel_id}`);
	};

	this.post = function (data, funnel_id) {
		return Http.authApi().post(`${prefix}/${funnel_id}`, data);
	};

	this.delete = function (report_id) {
		return Http.authApi().delete(`${prefix}/${report_id}`);
	};

})();

export default analyserReport;