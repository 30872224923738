import React from "react";
import i18n from "../../../i18n";
import calendar from "../../../utils/editor/calendar";
import Select from "react-select/creatable/dist/react-select.esm";
import {toast} from "react-toastify";

class Calendar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			calendar: {
				url: props.calendarElement.url,
				title: props.calendarElement.title,
				data: props.calendarElement.data,
				show_event_infos: props.calendarElement.show_event_infos,
				class: props.calendarElement.class,
			},
			calendars: [],
			listsOptions: [],
			optionSelected: {}
		}
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
		this.setState({calendar: nextProps.calendarElement});
	}

	handleChange = (prop, value) => {

		let obj = this.state.calendar;
		obj[prop] = value;
		this.setState({calendar: obj});

		this.props.handleChangeOptions(this.state.calendar);
	}

	loadCalendars = () => {

		let toastId = toast(i18n.t('cfb-loading'), {autoClose: false});

		calendar.getCalendars().then(response => {
			this.setState({calendars: response.data});

			toast.update(toastId, {
				render: i18n.t('cfb-loaded'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		})
	}

	handleChangeEvents = e => {
		this.handleChange('show_event_infos', e.target.checked);
	}

	handleChangeCalendar = (data) => {

		let calendar = this.state.calendar;

		calendar['url'] = data.value;
		calendar['title'] = data.label;
		calendar['data'] = data.data;
		calendar['show_event_infos'] = true;
		calendar['class'] = '';

		this.setState({calendar: calendar});

		this.props.handleChangeOptions(calendar);
	}

	handleChangeCalendarClass = (data) => {
		this.handleChange('class', data.value);
	}

	render() {
		return <div className='title-page-sidebar no-top'>

			<div className='place-btn form-group'>
				<button className='btn btn-info w-100 btn-lists' onClick={this.loadCalendars}>
					{i18n.t('cfb-load-calendars')}
				</button>
			</div>

			<div className='form-group'>
				{this.getSelectCalendars()}
			</div>

			<div className='form-group'>
				{this.getOptionsCalendar()}
			</div>

			<a href="https://office.builderall.com/br/office/booking" target="_blank"
			   style={{paddingTop: '50px', display: 'block'}}>
				<button className='btn btn-info w-100' data-ba-i={'add-booking'}>
					{i18n.t('cfb-calendar-create-calendar')}
				</button>
			</a>
		</div>;
	}

	getSelectCalendars() {

		let options = [];
		let objSelected = {};

		if (this.state.calendars.length > 0) {

			this.state.calendars.forEach((calendar) => {
				if (calendar.url === this.state.calendar.url) {
					objSelected = {value: calendar.url, label: calendar.title, data: calendar};
				}
				options.push({value: calendar.url, label: calendar.title, data: calendar})
			})

			return <Select options={options} onChange={this.handleChangeCalendar}
						   value={objSelected}/>;
		}
	}

	getOptionsCalendar() {
		if (typeof this.state.calendar.url !== 'undefined' && this.state.calendar.url !== '') {
			return (
				<>
					<div className='form-group'>
						<input type="checkbox" checked={this.state.calendar.show_event_infos}
							   className={'mr-2'}
							   onBlur={this.handleChangeEvents}
							   onChange={this.handleChangeEvents}/>
						{i18n.t('cfb-show-events')}
					</div>

					<div className='form-group'>
						{this.state.calendar.data.type < 3 ? this.getSelectClasses(this.state.calendar.data.classes) : null}
					</div>
				</>
			)
		}
	}

	getSelectClasses(classes) {

		let objSelected = {value: '', label: i18n.t('cfb-calendar-show-all-classes')};
		let options = [objSelected];

		classes.forEach((classi) => {
			if (classi.slug === this.state.calendar.class) {
				objSelected = {value: classi.slug, label: classi.title, data: classi};
			}
			options.push({value: classi.slug, label: classi.title, data: classi})
		})

		return <Select options={options} onChange={this.handleChangeCalendarClass}
					   menuPlacement="auto"
					   value={objSelected}/>;
	}
}

export default Calendar;
