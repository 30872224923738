import React from "react";

import './edition.css';
import i18n from "../../i18n";
import {EventEmitter} from "../events";
import {toast} from "react-toastify";
import Select from "react-select/creatable/dist/react-select.esm";
import mailingBoss from "../../utils/editor/mailingboss";
import FormCreateCampaign from "./element/form";

class AutomationEdition extends FormCreateCampaign {

	constructor(props) {
		super(props);

		this.state = {
			automation_uid: null,
			automation: {},
			campaign_uid: null,

			campaignsAuto: [],
			loadedCampaign: false,

			listsOptions: [],

			//campaign Item configuration(email) open/not-open
			configurations: [
				'none',
				'move-lead',
				'copy-lead',
				'insert-tag',
				'start-new-campaign'
			],

			//only visual
			form: {
				list_uid: null,
				name: ''
			},

			//tag configuration
			configurationTags: [
				'none',
				'move-lead',
				'copy-lead',
				'remove-tag',
				'start-new-campaign'
			],

			show: false,
			isAutomation: true,
			newCampaign: {
				name: '',
				subject: '',
				send_to: 'current',
				amount: 1,
				list_uid: '',
				type: 'automation'
			},

			urls: [],
			urlsCampaign: []
		}

		EventEmitter.subscribe('editionAutomation', (data) => {
			this.showCampaignEdition(data)
		})
	}

	showCampaignEdition = (data) => {

		this.setState({automation_uid: data.automation_uid})
		this.setState({automation: data.options})
		this.setState({automationId: data.idNode})
		this.setState({campaign_uid: data.options.campaign_uid})

		EventEmitter.dispatch('closeSidebars');
		document.getElementById('sidebar-automation-element').classList.add('active');

	}

	closePopup = () => {
		EventEmitter.dispatch('closeSidebars');
	}

	handleChangeConfig = (data) => {

		if (data.value !== 'insert-tag') {
			this.applyChanges('tag', 'none');
		}

		this.applyChanges('config', data.value);
	}

	handleChangeConfigTag = (data) => {
		this.applyChanges('tag', data.value);
	}

	handleChangeList = (prop, data) => {
		this.applyChanges(prop, data);
	}

	handleSetAnotherCampaign = (prop, data) => {
		this.applyChanges(prop, data);
	}

	handleChangeTime = (prop, value) => {
		this.applyChanges(prop, value);
	}

	handleTagName = (value) => {
		this.applyChanges('tag_name', value);
	}

	handleRemoveTagName = (value) => {
		this.applyChanges('remove_tag_name', value);
	}

	handleChangeUrl = (value) => {
		this.applyChanges('url', value);
	}

	applyChanges(prop, value) {

		let automation = this.state.automation;
		automation[prop] = value;

		EventEmitter.dispatch('changeOptionsNode', automation);

		this.setState({automation: automation})
	}

	sendCampaignToArea = () => {

		let automation = JSON.parse(JSON.stringify(this.state.automation));

		this.props.setCampaignAutomationItemOnArea(automation.campaign, this.state.automationId)
	}

	saveAutomation = () => {

		let automation = JSON.parse(JSON.stringify(this.state.automation));
		automation['funnel_id'] = this.props.funnel_id;

		let toastId = toast(i18n.t('cfb-adding'), {autoClose: false});


		let errorMessage = '';

		if (automation.config === 'insert-tag') {

			if (automation.tag_name === '')
				errorMessage = 'cfb-put-tag-name'

			if (automation.tag === 'remove-tag' &&
				(typeof automation.remove_tag_name === 'undefined' || automation.remove_tag_name === '')
			) {
				errorMessage = 'cfb-put-tag-name-remove'
			}

			if (automation.tag === 'move-lead' || automation.tag === 'copy-lead') {
				if (typeof automation.list_tag === 'undefined' || Object.keys(automation.list_tag).length === 0) {
					errorMessage = 'cfb-select-list'
				}
			}

			if (automation.tag === 'start-new-campaign') {
				if (typeof automation.campaign_tag === 'undefined' || Object.keys(automation.campaign_tag).length === 0) {
					errorMessage = 'cfb-select-campaign'
				}
			}
		} else {
			if (automation.config === 'move-lead' || automation.config === 'copy-lead') {
				if (typeof automation.list === 'undefined' || Object.keys(automation.list).length === 0) {
					errorMessage = 'cfb-select-list'
				}
			}
			if (automation.config === 'start-new-campaign') {
				if (typeof automation.campaign === 'undefined' || Object.keys(automation.campaign).length === 0) {
					errorMessage = 'cfb-select-campaign'
				}
			}
		}

		if (automation.type === 'click' || automation.type === 'not-click') {
			if (typeof automation.url === 'undefined' || Object.keys(automation.url).length === 0) {
				errorMessage = 'cfb-select-url'
			}
		}

		if (errorMessage === '') {
			mailingBoss.createAutomation(automation).then(() => {
				toast.update(toastId, {
					render: i18n.t('cfb-saved'),
					type: toast.TYPE.SUCCESS,
					autoClose: 5000,
					className: 'rotateY animated'
				});
			})
		} else {
			toast.update(toastId, {
				render: i18n.t(errorMessage),
				type: toast.TYPE.ERROR,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		}
	}

	loadUrls = () => {

		let toastId = toast(i18n.t('cfb-loading-urls'), {autoClose: false});

		mailingBoss.getCampaignData(this.state.campaign_uid).then((response) => {
			let urlArray = [];

			response.data.data.template.urls.forEach((url) => {
				urlArray.push({value: url, label: url});
			})

			this.setState({urlsCampaign: urlArray});

			toast.update(toastId, {
				render: i18n.t('cfb-loaded'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		})
	}

	render() {
		return (
			<div id={'sidebar-automation-element'} className='card sidebar-canvas sidebar-right-absolute'>

				{this.titleElem()}
				<div className={'form-group automation-edition-area'}>

					{this.configurationArea()}
					{this.getLinkArea()}

					<div className={'line color'}/>
					{i18n.t('cfb-save-automation-desc')}
					<button className='btn btn-info w-100 margin-top-10' data-ba-i={'save-automation'}
							onClick={this.saveAutomation}>
						{i18n.t('cfb-save-automation')}
					</button>
					{this.getButtonCampaignToArea()}
				</div>
			</div>
		);
	}

	getButtonCampaignToArea() {

		if (this.state.automation.config === 'start-new-campaign') {
			if (typeof this.state.automation.campaign === 'undefined' || Object.keys(this.state.automation.campaign).length === 0) {
				return null
			} else {
				return <>
					<div className={'line color'}/>
					{i18n.t('cfb-send-campaign-to-area-desc')}
					<button className='btn btn-info w-100 margin-top-10' onClick={this.sendCampaignToArea}>
						{i18n.t('cfb-send-campaign-to-area')}
					</button>
				</>;
			}
		}
	}

	getLinkArea() {
		try {
			if (this.state.automation.type === 'click' || this.state.automation.type === 'not-click') {
				if (this.state.urls.length !== 0)
					return <>
						<h4 className={'text-left'}>{i18n.t('cfb-load-url-desc')}</h4>
						<button className='btn btn-info w-100 btn-lists margin-top-10 mb-3' onClick={this.loadUrls}>
							{i18n.t('cfb-load-links')}
						</button>
						{i18n.t('cfb-select-link')}
						<Select options={this.state.urlsCampaign} onChange={this.handleChangeUrl}
								menuPlacement="auto"
								value={this.state.automation.url}/>
					</>

			} else
				return null;
		} catch (e) {
			console.log(e);
			return null;
		}
	}

	getSelectConfigurations(searchConfiguration, prop) {

		let options = [];
		let objSelected = {};

		searchConfiguration.forEach((config) => {
			if (
				typeof this.state.automation[prop] !== 'undefined' &&
				config === this.state.automation[prop]
			) {
				objSelected = {value: config, label: i18n.t('cfb-change-config-' + config)};
			}
			options.push({value: config, label: i18n.t('cfb-change-config-' + config)});
		})

		return <Select options={options}
					   menuPlacement="auto"
					   onChange={(data) => prop === 'config' ? this.handleChangeConfig(data) : this.handleChangeConfigTag(data)}
					   value={objSelected}/>;
	}

	configurationArea() {
		return (
			<div className='popup-element-add' style={{border: 'none'}}>
				<label className='w-100 text-left'>
					{i18n.t('cfb-add-automation-label')}
				</label>
				{this.placeTime()}
				<div className='separator'/>
				<div className='separator'/>
				<label className='w-100  text-left '>
					{i18n.t('cfb-automation-type')}
					<div className='separator'/>
					{this.getSelectConfigurations(this.state.configurations, 'config')}
				</label>
				{this.setLists('list', 'config')}
				{this.setCampaign('campaign', 'config')}
				{this.tagConfigurationArea()}
				{this.setLists('list_tag', 'tag')}
				{this.setCampaign('campaign_tag', 'tag')}
			</div>
		)
	}

	setCampaign(prop, checkProp) {
		if (
			typeof this.state.automation[checkProp] !== 'undefined' &&
			'start-new-campaign' === this.state.automation[checkProp]
		)
			return <>
				<div className='separator'/>
				{i18n.t('cfb-create-campaign')}
				{this.placeCreateCampaigns(this.apply)}
				<div className={'line'}/>

				{this.placeCampaignsAutomation()}
				{this.state.campaignsAuto.length !== 0 ? this.placeSelectCampaignsAutomation(prop) : null}
				<div className={'line'}/>

				{this.placeEditEmail(prop, checkProp)}
			</>;
	}

	editEmail = (id) => {
		EventEmitter.dispatch('editionEmail', id);
	}

	placeEditEmail(prop, checkProp) {
		if (
			typeof this.state.automation[checkProp] === 'undefined' ||
			'start-new-campaign' !== this.state.automation[checkProp] ||
			typeof this.state.automation[prop] === 'undefined' ||
			typeof this.state.automation[prop].value === 'undefined' ||
			this.state.automation[prop].value === ''
		) {
			return null;
		}

		return <>
			{i18n.t('cfb-edit-email')}
			<button className='btn btn-success w-100 btn-lists btn-create-campaign mb-0 mt-0' data-ba-i={'edit-email'}
					onClick={() => this.editEmail(this.state.automation[prop].value)}>{i18n.t('cfb-edit-email')}
			</button>
		</>
	}

	placeCampaignsAutomation() {
		return (
			<div className='place-btn text-left'>
				{i18n.t('cfb-campaigns-automation')}
				<button className='btn btn-info w-100 btn-lists margin-top-10 mb-0' onClick={this.loadCampaign}
						data-ba-i={'load-automation'}>
					{i18n.t('cfb-load-campaigns-automation')}
				</button>
			</div>
		)
	}

	placeSelectCampaignsAutomation(prop) {

		const options = [];

		this.state.campaignsAuto.forEach((elem) => {
			options.push({value: elem.campaign_uid, label: elem.name});
		})

		return (
			<div className='margin-top-10'>
				<Select options={options} onChange={(data) => this.handleSetAnotherCampaign(prop, data)}
						menuPlacement="auto"
						value={this.state.automation[prop]}/>
			</div>
		)
	}

	apply = () => {

		this.setShow(false);

		mailingBoss.createCampaignAutomation(this.state.newCampaign).then(() => {
			this.loadCampaign();
		});
	}


	loadCampaign = () => {

		let toastId = toast(i18n.t('cfb-loading-campaigns'), {autoClose: false});

		mailingBoss.getCampaignsAuto().then((response) => {

			this.setState({campaignsAuto: response.data.data});
			this.setState({loadedCampaign: true});

			toast.update(toastId, {
				render: i18n.t('cfb-loaded'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		})
	}

	setLists(prop, checkProp) {

		if (
			typeof this.state.automation[checkProp] !== 'undefined' &&
			(
				'move-lead' === this.state.automation[checkProp] ||
				'copy-lead' === this.state.automation[checkProp]
			)
		)

			return <>
				<div className='separator'/>
				{this.createPlaceToLoadLists(this.state.automation[prop], (data) => this.handleChangeList(prop, data))}
			</>
	}

	tagConfigurationArea() {

		let tagName = '';

		if (typeof this.state.automation.tag_name !== 'undefined')
			tagName = this.state.automation.tag_name;

		if (typeof this.state.automation.config !== 'undefined' &&
			'insert-tag' === this.state.automation.config)
			return (
				<>
					<small className='helper-tag'>{i18n.t('cfb-tag-automation-helper')}</small>
					<div className='separator'/>
					<label className='w-100  text-left '>
						{i18n.t('cfb-tag-name')}
						<input className='form-control' value={tagName}
							   onChange={(e) => this.handleTagName(e.target.value)}/>
					</label>
					<div className='separator'/>
					<label className='w-100  text-left '>
						{i18n.t('cfb-tag-configuration')}
						<div className='separator'/>
						{this.getSelectConfigurations(this.state.configurationTags, 'tag')}
					</label>
					<div className='separator'/>
					{this.removeTagArea()}
					<div className='separator'/>
					{this.placeTime(true)}
				</>
			);

		return null;
	}

	removeTagArea() {

		if (
			typeof this.state.automation.tag !== 'undefined' &&
			'remove-tag' === this.state.automation.tag
		) {

			let tagName = '';

			if (typeof this.state.automation.remove_tag_name !== 'undefined')
				tagName = this.state.automation.remove_tag_name;

			return <label className='w-100  text-left '>
				{i18n.t('cfb-remove-tag-name')}
				<div className='separator'/>
				<input className='form-control' value={tagName}
					   onChange={(e) => this.handleRemoveTagName(e.target.value)}/>
			</label>;
		}
	}

	placeTime(doThing = false) {

		if (doThing) {
			if (
				typeof this.state.automation.tag !== 'undefined' &&
				'start-new-campaign' !== this.state.automation.tag
			) {
				return null;
			}
		}

		let day = 0;
		let min = 0;

		if (doThing) {
			if (typeof this.state.automation.day_action !== 'undefined')
				day = this.state.automation.day_action;

			if (typeof this.state.automation.min_action !== 'undefined')
				min = this.state.automation.min_action;

		} else {
			if (typeof this.state.automation.day !== 'undefined')
				day = this.state.automation.day;

			if (typeof this.state.automation.min !== 'undefined')
				min = this.state.automation.min;
		}

		return (
			<>
				{doThing ? i18n.t('cfb-tag-time-do-config') : null}
				<div className='d-flex  text-left '>
					<div className='w-50' style={{paddingRight: '15px'}}>
						<label className='mb-0'>Day(s)</label>
						<input className='form-control' value={day}
							   type='number' min='0' max='360'
							   onChange={(e) => this.handleChangeTime('day' + (doThing ? '_action' : ''), e.target.value)}/>
					</div>
					<div className='w-50' style={{paddingLeft: '15px'}}>
						<label className='mb-0'>Min(s)</label>
						<input className='form-control' value={min}
							   type='number' min='0' max='60'
							   onChange={(e) => this.handleChangeTime('min' + (doThing ? '_action' : ''), e.target.value)}/>
					</div>
				</div>
			</>
		);
	}

	titleElem() {
		let value = ''
		if (typeof this.state.automation.desc != 'undefined')
			value = this.state.automation.desc;

		return <>
			<button type="button" className="close close-popup" onClick={this.closePopup}>&times;</button>
			<h2 className='title-page-sidebar line-bottom'>{i18n.t(value)}</h2>
		</>;
	}
}

export default AutomationEdition;
