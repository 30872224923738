import React from 'react';
import PageTypes from '../../utils/editor/pageTypes'

import './addition.css';
import i18n from "../../i18n";
import {faPlus, faTrashAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {EventEmitter} from "../events";
import Tab from "react-bootstrap/Tab";
import {Col, Nav, Row} from "react-bootstrap";
import funnels from "../../utils/dashboard/funnels";
import FileBase64 from "react-file-base64";
import {ShareData} from "../shareData";
import {toast} from "react-toastify";

class AdditionSidebar extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			theme_id: 0,
			pages: [],
			blueprints: [],
			external_resources: [],
			images: [],
			defaultImages: [],
			added: false,
			categories: [],
			categoriesBlueprints: [],
			categoriesImages: []
		}

		EventEmitter.subscribe('addFirstPage', () => this.addFirstPage());

	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {
		this.setState({theme_id: nextProps.themes_id});

		function createFunnelByLocalStorage(responseData) {
			if (localStorage.getItem('createFunnel')) {
				if (localStorage.getItem('blueprint') !== 'undefined') {
					responseData.blueprints.forEach((blueprint) => {
						if (blueprint.id == localStorage.getItem('blueprint')) {
							localStorage.removeItem('blueprint');
							localStorage.removeItem('createFunnel');
							this.props.addBlueprint(blueprint.funnelElements);
						}
					})
				}
			}
		}

		if (typeof nextProps.themes_id !== 'undefined') {

			let pageType = ShareData.recover('pageTypes' + nextProps.themes_id);

			if (pageType) {

				this.setState({pages: pageType.data});
				this.setState({categories: pageType.categories});

				this.setState({blueprints: pageType.blueprints});
				this.setState({categoriesBlueprints: pageType.categoriesBlueprints});

				this.setState({defaultImages: pageType.defaultImages});
				this.setState({categoriesImages: pageType.categoriesImages});

				this.setState({external_resources: pageType.external_sources});

				createFunnelByLocalStorage.call(this, pageType);

			} else {
				PageTypes.get(nextProps.themes_id).then((response) => {
					this.setState({pages: response.data.data});
					this.setState({categories: response.data.categories});

					this.setState({blueprints: response.data.blueprints});
					this.setState({categoriesBlueprints: response.data.categoriesBlueprints});

					this.setState({external_resources: response.data.external_sources});

					this.setState({defaultImages: response.data.defaultImages});
					this.setState({categoriesImages: response.data.categoriesImages});

					ShareData.subscribe('pageTypes' + nextProps.themes_id, response.data);

					createFunnelByLocalStorage.call(this, response.data);
				})
			}
		}

		let images = ShareData.recover('images');

		if (images) {
			this.setState({images: images});
		} else {
			funnels.getImages().then(response => {
				this.setState({images: response.data.images});

				ShareData.subscribe('images', response.data.images);
			})
		}
	}

	addFirstPage = () => {
		if (!this.state.added) {
			this.setState({added: true});
			this.props.addNode(this.state.pages[0].options);
			document.getElementById('addPagePopup').style.display = 'none';
		}
	}

	getFiles = (file) => {
		funnels.postImages({'img': file.base64}).then(response => {
			this.setState({images: response.data.images});

			ShareData.subscribe('images', response.data.images);
		})
	}

	deleteImage = (e, id) => {
		e.preventDefault();
		e.stopPropagation();

		let toastId = toast(i18n.t('cfb-deleting'), {autoClose: false});

		funnels.deleteImage(id).then(response => {

			toast.update(toastId, {
				render: i18n.t('cfb-deleted'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});

			this.setState({images: response.data.images});

			ShareData.subscribe('images', response.data.images);
		})
	}

	render() {

		const rc = this;

		const closePopup = () => {
			document.getElementById('addPagePopup').style.display = 'none';
		}

		const addNodeRestrictedArea = () => {
			rc.props.addRestrictedNode();
			closePopup();
		}

		function getExternalResources(cats) {
			cats.push(
				<Nav.Item className='category-navbar'
						  onClick={() => {
							  document.getElementById("category-external")
								  .scrollIntoView({
									  behavior: 'smooth'
								  });
						  }}>
					<Nav.Link>{i18n.t('cfb-category-external')}</Nav.Link>
				</Nav.Item>)
		}

		function getImagesCategory(cats) {
			cats.push(
				<Nav.Item className='category-navbar'
						  onClick={() => {
							  document.getElementById("images-user")
								  .scrollIntoView({
									  behavior: 'smooth'
								  });
						  }}>
					<Nav.Link>{i18n.t('cfb-images-user')}</Nav.Link>
				</Nav.Item>)
		}

		function getNavs(pages) {
			const cats = [];

			if (pages === 'pages') {

				Object.keys(rc.state.categories).forEach((idx) => {
					let category = rc.state.categories[idx];
					cats.push(
						<Nav.Item className='category-navbar'
								  onClick={() => {
									  document.getElementById("category-" + category.id)
										  .scrollIntoView({
											  behavior: 'smooth'
										  });
								  }}>
							<Nav.Link>{category.desc}</Nav.Link>
						</Nav.Item>)
				});

				getExternalResources(cats);
			}

			if (pages === 'blueprints') {
				Object.keys(rc.state.categoriesBlueprints).forEach((idx) => {
					let category = rc.state.categoriesBlueprints[idx];
					cats.push(
						<Nav.Item className='category-navbar'
								  onClick={() => {
									  document.getElementById("category-template-" + category.id)
										  .scrollIntoView({
											  behavior: 'smooth'
										  });
								  }}>
							<Nav.Link>{category.desc}</Nav.Link>
						</Nav.Item>)
				});
			}

			if (pages === 'images') {

				getImagesCategory(cats);

				Object.keys(rc.state.categoriesImages).forEach((idx) => {
					let category = rc.state.categoriesImages[idx];
					cats.push(
						<Nav.Item className='category-navbar'
								  onClick={() => {
									  document.getElementById("category-image-" + category.id)
										  .scrollIntoView({
											  behavior: 'smooth'
										  });
								  }}>
							<Nav.Link>{category.desc}</Nav.Link>
						</Nav.Item>)
				});
			}

			return cats;
		}

		function getPagesExternal(items) {
			items.push(
				<div className='title-available-pages' id={'category-external'}>
					<h5 className=''>{i18n.t('cfb-category-external')}</h5>
				</div>)

			rc.state.external_resources.map((external, index) => {
				items.push(
					<div className=' d-inline-block'>
						<div className='external-element external-element-add'
							 data-text={external.desc}
							 data-item={'item-' + index}
							 style={{backgroundImage: 'url(https://sitebuilder-admin.omb11.com/storage/' + external.img + ')'}}
							 onClick={() => {
								 rc.props.addExternalNode(external);
								 closePopup();
							 }}
							 key={index}>
						</div>
						<h4 className='desc-page'>{external.desc}</h4>
					</div>)
			});
		}

		function getImagesUser(items) {
			items.push(
				<div className='title-available-pages' id={'images-user'}>
					<h5 className=''>
						{i18n.t('cfb-images-user')}
						<div className=' d-inline-block float-right'>
							<FileBase64 multiple={false} onDone={rc.getFiles.bind(rc)}/>
						</div>
					</h5>
				</div>)

			rc.state.images.map((image, index) => {
				items.push(
					<div className=' d-inline-block'>
						<div className='user-img-element user-img-element-add page-add'
							 data-item={'item-' + index}
							 style={{backgroundImage: 'url(' + image.img + ')'}}
							 key={index}>
							<div className='plus-button'
								 onClick={() => {
									 rc.props.addExternalNode(image, true);
									 closePopup();
								 }}>
								<FontAwesomeIcon icon={faPlus} color='#F8FAFD'/>
							</div>

							<FontAwesomeIcon
								icon={faTrashAlt}
								onClick={(e) => rc.deleteImage(e, image.id)}
							/>
						</div>
					</div>)
			});
		}

		function getPages(pages) {
			const items = [];

			if (pages === 'pages') {

				Object.keys(rc.state.categories).forEach((idx) => {
					let category = rc.state.categories[idx];

					items.push(
						<div className='title-available-pages' id={'category-' + category.id}>
							<h5 className=''>{category.desc}</h5>
						</div>)

					rc.state.pages.map((page, index) => {
						if (page.categories.indexOf(category.id) !== -1)
							items.push(
								<div className='d-inline-block'>
									<div className='page-element page-add'
										 data-ba-i={'add-page-by-funnel-builder'}
										 data-text={page.options.desc}
										 data-item={'item-' + index}
										 style={{backgroundImage: 'url(https://sitebuilder-admin.omb11.com/storage/' + page.options.img + ')'}}
										 key={index}>
										<div className='plus-button'
											 data-ba-i={'add-page-by-funnel-builder'}
											 onClick={() => {
												 rc.props.addNode(page.options);
												 closePopup();
											 }}>
											<FontAwesomeIcon icon={faPlus} color='#F8FAFD'/>
										</div>
									</div>
									<h4 className='desc-page' title={page.options.desc}>{page.options.desc}</h4>
								</div>)
					});
				});

				getPagesExternal(items);
			}

			if (pages === 'blueprints') {
				Object.keys(rc.state.categoriesBlueprints).forEach((idx) => {
					let category = rc.state.categoriesBlueprints[idx];

					items.push(
						<div className='title-available-pages' id={'category-template-' + category.id}>
							<h5 className=''>{category.desc}</h5>
						</div>)

					rc.state.blueprints.map((page, index) => {
						if (page.category.id === category.id)
							items.push(
								<div className='d-inline-block'>
									<div className='page-element page-add template-add'
										 data-ba-i={'add-blueprint-by-funnel-builder'}
										 data-item={'item-' + index}
										 style={{backgroundImage: 'url(https://sitebuilder-admin.omb11.com/storage/' + page.img + ')'}}
										 key={index}>
										<div className='plus-button'
											 data-ba-i={'add-blueprint-by-funnel-builder'}
											 onClick={() => {
												 rc.props.addBlueprint(page.funnelElements);
												 closePopup();
											 }}>
											<FontAwesomeIcon icon={faPlus} color='#F8FAFD'/>
										</div>
									</div>
									<h4 className='desc-page' title={page.desc}>{page.desc}</h4>
								</div>)
					});
				});
			}

			if (pages === 'images') {
				getImagesUser(items);

				Object.keys(rc.state.categoriesImages).forEach((idx) => {
					let category = rc.state.categoriesImages[idx];

					items.push(
						<div className='title-available-pages' id={'category-image-' + category.id}>
							<h5 className=''>{category.desc}</h5>
						</div>)

					rc.state.defaultImages.map((defaultImage, index) => {
						if (defaultImage.category.id === category.id)
							items.push(
								<div className='d-inline-block'>
									<div className='page-element page-add image-add'
										 data-item={'item-' + index}
										 style={{backgroundImage: 'url(https://sitebuilder-admin.omb11.com/storage/' + defaultImage.img + ')'}}
										 key={index}>
										<div className='plus-button'
											 onClick={() => {
												 rc.props.addExternalNode(defaultImage);
												 closePopup();
											 }}>
											<FontAwesomeIcon icon={faPlus} color='#F8FAFD'/>
										</div>
									</div>
									<h4 className='desc-page' title={defaultImage.desc}>{defaultImage.desc}</h4>
								</div>)
					});
				});
			}

			return items;
		}

		function getPlaceRestrictedArea(addNodeRestrictedArea) {
			return <div className={'form-group restricted-area-button'}>
				<h5>{i18n.t('cfb-restricted-area')}</h5>
				<button className='btn btn-info'  data-ba-i={'add-restricted-area-by-funnel-builder'}  onClick={addNodeRestrictedArea}>
					{i18n.t('cfb-add-restricted-area')}
				</button>
			</div>;
		}

		function modalCreateFunnel() {
			return <div className="modal" id="addPagePopup">
				<div className="modal-dialog">
					<div className="modal-content">
						<div className='modal-header'>
							<div className='title-modal title-modal w-100'>
								<h3 className="modal-title">{i18n.t('cfb-modal-add-available-pages')}</h3>
								{/*<input placeholder='search'></input>*/}
								<button type="button" className="close close-popup"
										onClick={closePopup}>&times;</button>
							</div>
						</div>
						<div className='place-pages-modal'>
							<div style={{paddingTop: '15px'}}/>
							<Tab.Container id="left-tabs-example" defaultActiveKey="pages">
								<Row>
									<Col sm={3}>
										<Nav variant="pills" className="flex-column">
											<Nav.Item>
												<Nav.Link eventKey="pages" className='tab-blue'>
													{i18n.t('cfb-modal-add-pages')}
												</Nav.Link>
												<Tab.Pane eventKey="pages">
													<Nav variant="tabs" className="flex-column">
														{getNavs('pages')}
													</Nav>
												</Tab.Pane>
											</Nav.Item>
											<div style={{paddingTop: '15px'}}/>
											<Nav.Item>
												<Nav.Link eventKey="blueprints" className='tab-blue'>
													{i18n.t('cfb-modal-add-blueprints')}
												</Nav.Link>
												<Tab.Pane eventKey="blueprints">
													<Nav variant="tabs" className="flex-column">
														{getNavs('blueprints')}
													</Nav>
												</Tab.Pane>
											</Nav.Item>
											<div style={{paddingTop: '15px'}}/>
											<Nav.Item>
												<Nav.Link eventKey="images" className='tab-blue'>
													{i18n.t('cfb-modal-add-images')}
												</Nav.Link>
												<Tab.Pane eventKey="images">
													<Nav variant="tabs" className="flex-column">
														{getNavs('images')}
													</Nav>
												</Tab.Pane>
											</Nav.Item>
										</Nav>
									</Col>
									<Col sm={9}>
										<Tab.Content>
											<Tab.Pane eventKey="pages">
												{getPages('pages')}
											</Tab.Pane>
											<Tab.Pane eventKey="blueprints">
												{getPages('blueprints')}
											</Tab.Pane>
											<Tab.Pane eventKey="images">
												{getPages('images')}
											</Tab.Pane>
										</Tab.Content>
									</Col>
								</Row>
							</Tab.Container>

						</div>

						{typeof rc.props.elements != 'undefined' && rc.props.elements.length > 0 ? getPlaceRestrictedArea(addNodeRestrictedArea) : null}
					</div>
				</div>
			</div>;
		}

		return (
			<div>
				{modalCreateFunnel()}
			</div>
		)
	}


}

export default AdditionSidebar;
