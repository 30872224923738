import React from "react";

import loginApi from './utils/loginApi'

class Login extends React.Component {

	constructor(props) {
		super();

		// console.log(props.match.params.email);
		// console.log(props.match.params.token);

		// this.handleSubmit = this.handleSubmit.bind(this);


		localStorage.setItem('createFunnel', true);
		localStorage.setItem('theme', props.match.params.theme);
		localStorage.setItem('blueprint', props.match.params.blueprint);

		const searchParams = new URLSearchParams(props.location.search);


		window.setCookie('email', props.match.params.email);

		if (searchParams.has('office_token')) {
			localStorage.setItem('ba-office-token', searchParams.get('office_token'));
		}
		this.login({
			email: props.match.params.email,
			token: props.match.params.token
		})
	}

	// handleSubmit(event) {
	// 	event.preventDefault();
	//
	// 	this.login({
	// 		email: document.getElementById('email').value,
	// 		password: document.getElementById('password').value
	// 	})
	// }

	login(user) {

		loginApi.login(user)
			.then(data => {
				let response = data.data;

				if (response.success) {
					window.token = response.token;

					localStorage.token = response.token;
					localStorage.setItem('login', true);
					localStorage.setItem('username', response.user.name);
					localStorage.setItem('token', response.token);

					window.location.assign("/")

				} else {
					this.errors = {};
					this.errors.message = response.error;
				}
			})
			.catch(error => {
				console.warn(error);

				if (error.response.data.errors) {
					this.errors = error.response.data.errors;
					this.errors.message = error.response.data.message;
				}
			});
	}

	render() {
		return <div />;
		// (
			// <form onSubmit={this.handleSubmit} className='form-group container card p-5 mt-5'>
			// 	<label className='w-100'>
			// 		Email:
			// 		<input type="text" id="email" className='form-control'/>
			// 	</label>
			// 	<label className='w-100'>
			// 		Password:
			// 		<input type="password" id="password" className='form-control'/>
			// 	</label>
			// 	<input type="submit" className='btn btn-info float-right w-25 mt-3' value="Login"
			// 		   style={{alignSelf: 'flex-end'}}/>
			// </form>
		// );
	}
}

export default Login;
