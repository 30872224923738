import React, {memo} from 'react';
import {EventEmitter} from "../events";
import {faCheck, faTimes} from "@fortawesome/free-solid-svg-icons";
import {faClock, faEnvelopeOpen, faEnvelope} from "@fortawesome/free-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Handle} from "react-flow-renderer";
import i18n from "../../i18n";

export default memo(({data}) => {

	function showEditionAutomation(ev) {
		ev.preventDefault();
		EventEmitter.dispatch('editionAutomation', data);
	}

	let type = data.options.type;
	let icon = '';

	if (type === 'open') {
		icon = faEnvelopeOpen;
	}
	if (type === 'not-open') {
		icon = faEnvelope;
	}
	if (type === 'click') {
		icon = faCheck;
	}
	if (type === 'not-click') {
		icon = faTimes;
	}

	function globalTimerArea() {
		if (data.options['day'] !== '0' || data.options['min'] !== '0')
			return <div className='timer-tag'>
				<FontAwesomeIcon icon={faClock} color={'#5A5959'}/>
				{data.options['day']} Day(s) . {data.options['min']}min(s)
			</div>;

		return null;
	}

	function globalConfigArea() {
		if (data.options['config'] !== 'none')
			return <div>
				{i18n.t('cfb-config')}: {i18n.t('cfb-change-config-' + data.options['config'])}
			</div>;

		if (
			(data.options['config'] === 'move-lead' || data.options['config'] === 'copy-lead') &&
			typeof data.options['list'].label !== 'undefined' &&
			data.options['list'].label !== ''
		)
			return <>
				<div>
					{i18n.t('cfb-config')}: {i18n.t('cfb-change-config-' + data.options['config'])}
				</div>
				<div>
					{i18n.t('cfb-config-list-destiny')}: {data.options['list'].label}
				</div>
			</>;

		return null;
	}

	function globalTagArea() {

		if (data.options['config'] === 'insert-tag' && data.options['tag_name'] !== '') {
			return <div>{i18n.t('cfb-tag-name')}: {data.options['tag_name']}</div>;
		}

		return null;
	}

	function globalTagConfigArea() {

		if (data.options['config'] === 'insert-tag' && data.options['tag_name'] !== '') {
			if (data.options['tag'] !== 'none')
				return <div>
					{i18n.t('cfb-tag-config')}: {i18n.t('cfb-change-config-' + data.options['tag'])}
				</div>;

			if (
				data.options['tag'] === 'start-new-campaign' &&
				typeof data.options['campaign_tag'].label !== 'undefined' &&
				data.options['campaign_tag'].label !== ''
			)
				return <>
					<div>
						{i18n.t('cfb-tag-config')}: {i18n.t('cfb-change-config-' + data.options['tag'])}
					</div>
					<div>
						{i18n.t('cfb-tag-campaign-automation')}: {data.options['campaign_tag'].label}
					</div>
				</>;
		}

		return null;
	}

	let timeArea = globalTimerArea();
	let configArea = globalConfigArea();
	let tagArea = globalTagArea();
	let tagConfigArea = globalTagConfigArea();

	const deleteNode = (idNode) => {
		EventEmitter.dispatch('deleteElement', idNode);
	}

	return (
		<div className='automation-element flow-sub-element' data-nodeid={data.idNode}
			 onContextMenu={showEditionAutomation}>
			<Handle type="target" position="left"/>
			{timeArea}
			<h6 className='title-elem' style={{paddingTop: '15px'}}>
				<FontAwesomeIcon icon={icon} style={{marginRight: '5px'}}/>
				{i18n.t(data.label)}
			</h6>
			<div className='automation-config'>
				{configArea}
				{tagArea}
				{tagConfigArea}
			</div>
			<Handle type="source" position="right"/>


			<div className={'removeIcon'} onClick={() => deleteNode(data.idNode)}/>
		</div>
	);
});
