import Http from "../http";

const prefix = '/restrict-area';

const restrictArea = new (function () {

	this.getTypes = function () {
		return Http.authApi().get(`${prefix}/types/all`);
	};

})();

export default restrictArea;