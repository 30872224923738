import React from "react";
import mailingBoss from "../../../utils/editor/mailingboss";
import {toast} from "react-toastify";
import i18n from "../../../i18n";
import {Modal} from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Lists from "../extends/lists";
import {ShareData} from "../../shareData";

class FormCreateCampaign extends Lists {

	placeCreateCampaigns(callback) {

		const handleClose = () => this.setShow(false);
		const handleShow = () => this.setShow(true);
		const handleApply = () => {
			callback();
		}

		const handleOptionName = (changeEvent) => {
			this.setState(
				{
					newCampaign: {
						...this.state.newCampaign,
						...{name: changeEvent.target.value}
					}
				}
			)
		}
		const handleOptionSubject = (changeEvent) => {
			this.setState(
				{
					newCampaign: {
						...this.state.newCampaign,
						...{subject: changeEvent.target.value}
					}
				}
			)
		}
		const handleOptionChange = (changeEvent) => {
			this.setState(
				{
					newCampaign: {
						...this.state.newCampaign,
						...{send_to: changeEvent.target.value}
					}
				}
			)
		}
		const handleOptionAmount = (changeEvent) => {
			this.setState(
				{
					newCampaign: {
						...this.state.newCampaign,
						...{amount: changeEvent.target.value}
					}
				}
			)
		}
		const handleChangeList = (changeEvent) => {
			this.setState({form: changeEvent})

			this.setState(
				{
					newCampaign: {
						...this.state.newCampaign,
						...{list_uid: changeEvent.value}
					}
				}
			)
		}

		return (
			<div>
				<div className=''>
					<button className='btn btn-success w-100 btn-lists btn-create-campaign mb-0'
							data-ba-i={'create-campaign'}
							onClick={handleShow}>{i18n.t('cfb-create-campaign-button')}</button>
				</div>
				<div>
					<Modal show={this.state.show} onHide={handleClose} dialogClassName="modal-campaign">
						<Modal.Header>
							<h3 className="modal-title">{i18n.t('cfb-create-campaign-title')}</h3>
							<button type="button" className="close close-popup" onClick={handleClose}>&times;</button>
						</Modal.Header>

						<Modal.Body>
							<label className='w-100'>
								{i18n.t('cfb-create-name')}
								<input className='form-control'
									   onChange={handleOptionName}
									   value={this.state.newCampaign.name}/>
							</label>
							{this.state.isAutomation ?
								<label className='w-100'>
									{i18n.t('cfb-subject-name')}
									<input className='form-control'
										   onChange={handleOptionSubject}
										   value={this.state.newCampaign.subject}/>
								</label>
								: null}
							{this.state.isAutomation ? this.createPlaceToLoadLists(this.state.form, handleChangeList) : null}
							{!this.state.isAutomation ?
								<>
									<label className='w-100'>
										{i18n.t('cfb-create-campaign-send-to')}
										<div className='w-100'>
											<label>
												<input type='radio'
													   style={{marginRight: '3px'}}
													   value='current'
													   onChange={handleOptionChange}
													   checked={this.state.newCampaign.send_to === "current"}/>
												{i18n.t('cfb-create-campaign-send-to-current')}
											</label>
											<label style={{marginLeft: '20px'}}>
												<input type='radio'
													   style={{marginRight: '3px'}}
													   value='future'
													   onChange={handleOptionChange}
													   checked={this.state.newCampaign.send_to === "future"}/>
												{i18n.t('cfb-create-campaign-send-to-future')}
											</label>
										</div>
									</label>
									<label className='w-100'>
										{i18n.t('cfb-create-campaign-amount')}
										<input type='number'
											   onChange={handleOptionAmount}
											   className='form-control'
											   value={this.state.newCampaign.amount}/>
									</label>
								</>
								: null}
						</Modal.Body>

						<Modal.Footer>
							<button type="button" className="btn btn-link" onClick={handleClose}>
								{i18n.t('cfb-close')}
							</button>
							<button type="button" className="btn btn-success btn-upper" onClick={handleApply}>
								{i18n.t('cfb-save-changes')}
							</button>

						</Modal.Footer>
					</Modal>
				</div>
			</div>
		)
	}
}

class Form extends FormCreateCampaign {

	constructor(props) {
		super(props);

		this.state = {
			show: false,
			newCampaign: {
				name: '',
				send_to: 'current',
				amount: 1
			},
			slugs: ['subscribe-pending', 'subscribe-confirm', 'unsubscribe-confirm'],
			listsOptions: [],
			lists: [],
			urls: {
				"subscribe-pending": "",
				"subscribe-confirm": "",
				"unsubscribe-confirm": ""
			},
			form: {
				list_uid: null,
				name: ''
			},
			campaigns: null,
			loadedCampaign: false
		}

		if (
			typeof props.formElement !== 'undefined' &&
			typeof props.formElement.form !== 'undefined'
		) {
			this.state.listsOptions = [props.form];
			this.state.form = props.formElement.form;
		}

		if (
			typeof props.formElement !== 'undefined' &&
			typeof props.formElement.urls !== 'undefined'
		) {
			this.setState({urls: props.formElement.urls})
		}

		let lists = ShareData.recover('lists');

		if (lists) {
			this.state.listsOptions = lists;
		}
	}

	componentWillReceiveProps(nextProps: Readonly<P>, nextContext: any) {

		this.setState({form: {list_uid: null, name: ''}});
		this.setState({listsOptions: []});
		this.setState({campaigns: null});
		this.setState({loadedCampaign: false});
		this.setState({
			urls: {
				"subscribe-pending": "",
				"subscribe-confirm": "",
				"unsubscribe-confirm": ""
			}
		});

		if (
			typeof nextProps.formElement !== 'undefined' &&
			typeof nextProps.formElement.form !== 'undefined'
		) {
			this.setState({form: nextProps.formElement.form});
			this.setState({listsOptions: [nextProps.formElement.form]});
		}

		if (
			typeof nextProps.formElement !== 'undefined' &&
			typeof nextProps.formElement.urls !== 'undefined'
		) {
			this.setState({urls: nextProps.formElement.urls})
		}

		let lists = ShareData.recover('lists');

		if (lists) {
			this.setState({listsOptions: lists});
		}
	}

	loadCampaign = () => {

		let toastId = toast(i18n.t('cfb-loading-campaigns'), {autoClose: false});

		mailingBoss.getCampaigns(this.state.form.value).then((response) => {

			this.setState({campaigns: response.data.data});
			this.setState({loadedCampaign: true});

			toast.update(toastId, {
				render: i18n.t('cfb-loaded'),
				type: toast.TYPE.SUCCESS,
				autoClose: 5000,
				className: 'rotateY animated'
			});
		})
	}

	handleChangeList = (data) => {
		this.setState({form: data})
		this.props.handleChangeOptions({form: data});
	}

	handleUrls = () => {

		let urls = {};
		this.state.slugs.map((slug, index) => {
			urls[slug] = document.getElementById('select-page-' + slug).value;
		})

		this.setState({urls: urls})
		this.props.handleChangeOptionsURL(urls);
	}

	render() {
		return (
			<div>
				<Tabs defaultActiveKey="list" id="uncontrolled-tab-example">
					<Tab eventKey="list" title={i18n.t('cfb-edit-list')}>
						{this.createPlaceToLoadLists(this.state.form, this.handleChangeList)}
					</Tab>
					{this.state.form.list_uid !== null ? this.tabUrls() : null}
					{this.state.form.list_uid !== null ? this.tabCampaigns() : null}
				</Tabs>
			</div>
		);
	}

	tabUrls() {
		return <Tab eventKey="url" title={i18n.t('cfb-edit-urls')}>
			<div className=''>
				{this.state.form.list_uid !== null ? this.placeUrls() : null}
			</div>
		</Tab>;
	}

	apply = () => {
		this.setShow(false);

		console.log(this.state.newCampaign, this.state.form.value);

		mailingBoss.createCampaign(this.state.newCampaign, this.state.form.value).then((response) => {
			this.props.setCampaignOnArea(response.data.data[0])
		});
	}

	tabCampaigns() {
		return <Tab eventKey="campaign" title={i18n.t('cfb-edit-campaign')}>
			<div className=''>
				{this.state.form.list_uid !== null && this.state.campaigns === null ? this.placeCampaigns() : null}
				{this.state.campaigns !== null ? this.placeSelectCampaigns() : null}
				{this.state.loadedCampaign !== false ? this.placeCreateCampaigns(this.apply) : null}
			</div>
		</Tab>;
	}

	placeCampaigns() {
		return (
			<div className='place-btn'>
				<button className='btn btn-info w-100 btn-lists' onClick={this.loadCampaign}>
					{i18n.t('cfb-load-campaigns')}
				</button>
			</div>
		)
	}

	placeSelectCampaigns() {

		const options = [];

		this.state.campaigns.forEach((elem) => {
			options.push(
				<button className='btn btn-info w-100 text-left text-ellipsis mt-2'
						value={elem.group_uid}
						onClick={this.props.setCampaignOnArea.bind(this, elem)}>
					{elem.name} - ({elem.campaigns.length}) {i18n.t('cfb-emails')}
				</button>
			)
		})

		return (
			<div className=''>
				<label>{i18n.t('cfb-add-create-campaign')}</label>
				{options}
			</div>
		)
	}

	setShow = value => {
		this.setState({show: value});
	}

	placeUrls() {

		const options = [];
		options.push(
			<option value=''>{i18n.t('cfb-url-select')}</option>
		)
		this.props.elements.forEach((elem) => {
			if (elem.type === 'pageNode') {
				options.push(
					<option value={elem.data.idNode}>{elem.data.label}</option>
				)
			}
		})

		const items = [];
		this.state.slugs.map((slug, index) => {
			items.push(
				<div className='url-place' key={index}>
					<label className='w-100'>
						{i18n.t('cfb-url-' + slug)}
						<select className='form-control'
								onChange={this.handleUrls}
								id={'select-page-' + slug}
								value={this.state.urls[slug]}>
							{options}
						</select>
					</label>
				</div>
			)
		});

		return (
			<div className=''>
				<div>{items}</div>
			</div>);
	}
}

export default Form;
