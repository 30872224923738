import Http from "../http";

const prefix = '/page-types';
const prefixPage = '/page';

const pageTypes = new (function () {

	this.get = function (themeId) {
		return Http.authApi().get(`${prefix}/${themeId}`);
	};

	this.getPreview = function (themeId, pageId) {
		return Http.authApi().get(`${prefix}/${themeId}/${pageId}`);
	};

	this.getWithoutAuth = function (id) {
		return Http.webAction().get(`${prefixPage}/without-auth/${id}`);
	};

	this.getPagesFromWebsite = function (websiteId, isProd) {
		if (isProd)
			// return Http.authProd().get(`${prefixPage}s/from-website/${websiteId}`);
			return Http.authApi().get(`${prefixPage}s/from-website/${websiteId}`);
		else
			return Http.authApi().get(`${prefixPage}s/from-website/${websiteId}`);
	};

})();

export default pageTypes;