import axios from 'axios';

const getUrlProd = (type) => {
	let url = 'https://cheetah-api.builderall.com';

	if (type === 'api') {
		return url + '/api';
	}

	return url;
};

const http = new (function () {

	let url = '';
	switch (process.env.REACT_APP_ENV) {

		case 'local':
			url = 'http://api.localhost/';
			break;
		case 'develop':
			url = 'https://cheetah-api.builderall.io/';
			break;
		case 'homologation':
			url = 'https://cheetah-api.builderall.info/';
			break;
		case 'production':
			// url = 'https://api-master.builderall.io/';
			url = 'https://cheetah-api.builderall.com/';
			break;
		default:
			url = 'http://api.localhost/';
			break;

	}

	this.webAction = function () {
		return axios.create({
			baseURL: url,
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			}
		});
	};


	this.api = function () {
		return axios.create({
			baseURL: url + 'api/',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			}
		});
	};

	this.authApi = function () {
		let token = localStorage.getItem('token')
			? localStorage.getItem('token')
			: '';

		return axios.create({
			baseURL: url + 'api/',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
	};

	this.authProd = function () {
		let token = localStorage.getItem('tokenP')
			? localStorage.getItem('tokenP')
			: '';

		return axios.create({
			baseURL: getUrlProd('api'),
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${token}`
			}
		});
	};
})();

export default http;
